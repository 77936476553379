import { NavLink } from "react-router-dom";
import ProductCard from "../components/ProductCard";
import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { ParamContext } from "../components/context/ParamContexte";

const Shirts = () => {
  const { products, nameCompany, imgOpenTags } = useContext(ParamContext);
  const baseUrl = "https://api.artimarq.fr/public/images/";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const productsFiltered = products
    .filter((product) => product.nameCategory === "Chemises")
    .sort((a, b) => {
      if (a.genderProduct === "Hommes" && b.genderProduct !== "Hommes") {
        return -1;
      } else if (b.genderProduct === "Hommes" && a.genderProduct !== "Hommes") {
        return 1;
      } else if (a.genderProduct === "Femmes" && b.genderProduct !== "Femmes") {
        return -1;
      } else if (b.genderProduct === "Femmes" && a.genderProduct !== "Femmes") {
        return 1;
      } else {
        return 0;
      }
    });

  let dataToDisplay;
  if (productsFiltered.length > 0) {
    dataToDisplay = productsFiltered.map((product) => (
      <ProductCard
        key={product.idProduct}
        idProduct={product.idProduct}
        nameProduct={product.nameProduct}
        images={product.nameMedia}
      />
    ));
  } else {
    dataToDisplay = (
      <div className="contentEmpty">
        <h3>
          Un problème est survenue ! Si celui-ci persiste, n'hesitez pas a nous{" "}
          <NavLink to="/conatcts">contacter</NavLink> directement.
        </h3>
      </div>
    );
  }

  return (
    <div className="classic-page products-pages">
      <Helmet>
        <title>{`${nameCompany} | Nos categories de chemises`}</title>
        <meta property="og:title" content="Nos categories de chemises" />
        <meta
          property="og:description"
          content="Explorez l'élégance en personnalisant votre chemise."
        />
        <meta property="og:type" content="product" />
        <meta property="og:image" content={baseUrl + imgOpenTags} />
        <meta property="og:url" content="https://artimarq.fr/chemises" />
        <meta property="og:site_name" content="ARTIMARQ site officiel" />
        <meta
          name="description"
          content="Explorez l'élégance en personnalisant votre chemise. Découvrez un éventail de possibilités pour créer un style qui vous ressemble."
        />
        <link rel="canonical" href={`https://artimarq.fr/chemises`} />
      </Helmet>
      <div className="head-products-pages">
        <h1>Chemises manches longs, personalisez en france</h1>
        <p>
          Découvrez l'art de l'élégance en personnalisant votre chemise selon
          vos besoins, vos préférences et votre raffinement personnel. Nous vous
          offrons un éventail captivant de possibilités pour créer la chemise
          qui incarne parfaitement votre style et votre individualité.
        </p>
      </div>
      <div className="body-products-pages">
        {products.length > 0 ? (
          <div className="content-product">{dataToDisplay}</div>
        ) : (
          <div>{dataToDisplay}</div>
        )}
      </div>
    </div>
  );
};

export default Shirts;

import { NavLink } from "react-router-dom";
import ProductCard from "../components/ProductCard";
import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { ParamContext } from "../components/context/ParamContexte";

const Tshirts = () => {
  const { products, nameCompany, imgOpenTags } = useContext(ParamContext);
  const baseUrl = "https://api.artimarq.fr/public/images/";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const productsFiltered = products
    .filter((product) => product.nameCategory === "T-shirts")
    .sort((a, b) => {
      if (a.genderProduct === "Hommes" && b.genderProduct !== "Hommes") {
        return -1;
      } else if (b.genderProduct === "Hommes" && a.genderProduct !== "Hommes") {
        return 1;
      } else if (a.genderProduct === "Femmes" && b.genderProduct !== "Femmes") {
        return -1;
      } else if (b.genderProduct === "Femmes" && a.genderProduct !== "Femmes") {
        return 1;
      } else {
        return 0;
      }
    });

  let dataToDisplay;
  if (productsFiltered.length > 0) {
    dataToDisplay = productsFiltered.map((product) => (
      <ProductCard
        key={product.idProduct}
        idProduct={product.idProduct}
        nameProduct={product.nameProduct}
        images={product.nameMedia}
      />
    ));
  } else {
    dataToDisplay = (
      <div className="contentEmpty">
        <h3>
          Un problème est survenue ! Si celui-ci persiste, n'hesitez pas a nous{" "}
          <NavLink to="/conatcts">contacter</NavLink> directement.
        </h3>
      </div>
    );
  }

  return (
    <div className="classic-page products-pages">
      <Helmet>
        <title>{`${nameCompany} | Nos categories de t-shirts`}</title>
        <meta property="og:title" content="Nos categories de t-shirts" />
        <meta
          property="og:description"
          content="Révélez votre style en personnalisant vos t-shirts."
        />
        <meta property="og:type" content="service" />
        <meta property="og:image" content={baseUrl + imgOpenTags} />
        <meta property="og:url" content="https://artimarq.fr/t-shirts" />
        <meta property="og:site_name" content="ARTIMARQ site officiel" />
        <meta
          name="description"
          content="Révélez votre style en personnalisant vos t-shirts. Choisissez parmi une palette de couleurs, motifs et tailles pour créer le parfait t-shirt. Une toile vierge pour vos idées."
        />
        <link rel="canonical" href={`https://artimarq.fr/t-shirts`} />
      </Helmet>
      <div className="head-products-pages">
        <h1>Créez un t-shirt unique qui répond parfaitement à vos besoins !</h1>
        <p>
          Faites le choix de la perfection en personnalisant votre t-shirt selon
          vos propres caractéristiques, vos préférences et votre style. Nous
          mettons à votre disposition une gamme impressionnante d'options pour
          vous permettre de concevoir le t-shirt qui vous reflète.
        </p>
      </div>
      <div className="body-products-pages">
        {products.length > 0 ? (
          <div className="content-product">{dataToDisplay}</div>
        ) : (
          <div>{dataToDisplay}</div>
        )}
      </div>
    </div>
  );
};

export default Tshirts;

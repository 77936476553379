import axios from "axios";
import React, { useEffect, useState } from "react";

const ArticleBlogCard = ({ idArticle }) => {
  const baseUrl = "https://api.artimarq.fr/public/images/";

  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const getArticles = async () => {
      await axios
        .get(`https://api.artimarq.fr/front/article/${idArticle}`)
        .then((res) => {
          const ordersObj = Object.values(res.data);
          setArticles(ordersObj[0]);
        });
    };
    getArticles();
  }, [idArticle]);

  const datePerser = (date) => {
    if (date) {
      const monthNames = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ];

      const newDate = new Date(date);
      const day = newDate.getDate().toString().padStart(2, "0");
      const month = monthNames[newDate.getMonth()];
      const year = newDate.getFullYear();

      return `${day} ${month} ${year}`;
    } else {
      return "";
    }
  };

  return (
    <div>
      <a href={`/artimarq-le-blog/article/${idArticle}`}>
        <div className="blog-card">
          <div
            className="img-blog-container"
            style={{ backgroundImage: `url(${baseUrl}${articles.nameMedia}` }}
          ></div>
          <div className="card-content">
            <small>
              {articles.categories} | {datePerser(articles.dateArticle)}
            </small>
            <h2>{articles.title}</h2>
            <p>{articles.description}</p>
            <span className="black-button">Lire l'article</span>
          </div>
        </div>
      </a>
    </div>
  );
};

export default ArticleBlogCard;

import { NavLink } from "react-router-dom";
import ProductCard from "../components/ProductCard";
import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { ParamContext } from "../components/context/ParamContexte";

const OthersProducts = () => {
  const { products, nameCompany, imgOpenTags } = useContext(ParamContext);
  const baseUrl = "https://api.artimarq.fr/public/images/";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const productsFiltered = products
    .filter((product) => product.nameCategory === "Autres produits")
    .sort((a, b) => {
      if (a.genderProduct === "Hommes" && b.genderProduct !== "Hommes") {
        return -1;
      } else if (b.genderProduct === "Hommes" && a.genderProduct !== "Hommes") {
        return 1;
      } else if (a.genderProduct === "Femmes" && b.genderProduct !== "Femmes") {
        return -1;
      } else if (b.genderProduct === "Femmes" && a.genderProduct !== "Femmes") {
        return 1;
      } else {
        return 0;
      }
    });

  let dataToDisplay;
  if (productsFiltered.length > 0) {
    dataToDisplay = productsFiltered.map((product) => (
      <ProductCard
        key={product.idProduct}
        idProduct={product.idProduct}
        nameProduct={product.nameProduct}
        images={product.nameMedia}
      />
    ));
  } else {
    dataToDisplay = (
      <div className="contentEmpty">
        <h3>
          Un problème est survenue ! Si celui-ci persiste, n'hesitez pas a nous{" "}
          <NavLink to="/conatcts">contacter</NavLink> directement.
        </h3>
      </div>
    );
  }

  return (
    <div className="classic-page products-pages">
      <Helmet>
        <title>{`${nameCompany} | Tout nos produits`}</title>
        <meta property="og:title" content="Tout nos produits" />
        <meta
          property="og:description"
          content="Découvrez nos vêtements personnalisables et commencez à forgez une marque qui parle a vote cible."
        />
        <meta property="og:type" content="product" />
        <meta property="og:image" content={baseUrl + imgOpenTags} />
        <meta property="og:url" content="https://artimarq.fr/autres-produits" />
        <meta property="og:site_name" content="ARTIMARQ site officiel" />
        <meta
          name="description"
          content="Entreprises, associations, collectivités et marques, découvrez nos vêtements personnalisables et commencez à forgez une marque qui parle a vote cible."
        />
        <link rel="canonical" href={`https://artimarq.fr/autres-produits`} />
      </Helmet>
      <div className="head-products-pages">
        <h1>Autres produits disponibles</h1>
        <p>
          Entreprises, associations, collectivités et marques, découvrez notre
          vaste gamme de produits personnalisables. Que vous recherchiez des
          articles promotionnels uniques, des cadeaux d'entreprise mémorables,
          ou des solutions sur mesure pour vos événements, nous avons ce qu'il
          vous faut. Nos produits personnalisables vont au-delà des simples
          objets. Ils sont le reflet de votre identité et de votre message.
          Choisissez parmi une variété d'options de personnalisation, des logos
          et des couleurs aux messages et aux designs sur mesure.
        </p>
      </div>
      <div className="body-products-pages">
        {products.length > 0 ? (
          <div className="content-more-4-product">{dataToDisplay}</div>
        ) : (
          <div>{dataToDisplay}</div>
        )}
      </div>
      <div className="contact-button-product">
        <span>Vous ne trouvez pas ce que vous cherchez ?</span>
        <NavLink to="/demande-de-devis">
          <button className="black-button">Demandez un devis gratuit</button>
        </NavLink>
      </div>
    </div>
  );
};

export default OthersProducts;

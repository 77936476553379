import { useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ParamContext } from "./ParamContexte";

const PrivateRoute = () => {
  
  const { adminStatue } = useContext(ParamContext);

  const navigate = useNavigate();

  return  adminStatue ? <Outlet /> : navigate("/");
};

export default PrivateRoute;

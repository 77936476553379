import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { ParamContext } from "../components/context/ParamContexte";

const LegalNotice = () => {
  const {
    nameCompany,
    addressCompany,
    zipCompany,
    cityCompany,
    countryCompany,
    capitalCompany,
    emailContact,
    publisherCompany,
    statusCompany,
    rcs,
    idCompany,
  } = useContext(ParamContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formatNumber = (number) => {
    const numberString = number.toString();
    return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  return (
    <div className="legaleNotice">
      <Helmet>
        <title>Mentions légales | {nameCompany}</title>
        <meta
          name="description"
          content="Consultez les mentions légales de notre site internet pour en savoir plus sur notre entreprise, les informations légales, et plus encore."
        />
        <link rel="canonical" href={`https://artimarq.fr/mentions-legales`} />
      </Helmet>
      <div className="mentions-legales-container">
        <h1>MENTIONS LÉGALES</h1>
        <h2>Identification de l'éditeur du site</h2>
        <p>
          {nameCompany}, {statusCompany}
        </p>
        <p>
          Siège social: {addressCompany}, {zipCompany} {cityCompany} -{" "}
          {countryCompany}
        </p>
        <p>Capital social de {formatNumber(capitalCompany)} euros</p>
        <p>{rcs}, Numero de siret {idCompany}</p>
        <p>
          E-mail : <a href={`mailto:${emailContact}`}>{emailContact}</a>
        </p>
        <p>Responsable de la publication : {publisherCompany}</p>
        <h2>Identification de l’hébergeur du site</h2>
        <p>
          Le site artimarq.fr est hébergé par{" "}
          <a href="https://www.hostinger.fr" target="_blank" rel="noreferrer">
            HOSTINGER
          </a>{" "}
          INTERNATIONAL LTD, dont le siège social est situé au 61 Lordou Vironos
          Street, 6023 Larnaca, Chypre.
        </p>
      </div>
    </div>
  );
};

export default LegalNotice;

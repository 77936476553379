import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ParamContext } from "../../components/context/ParamContexte";
import { Helmet } from "react-helmet";

const ArticleBlog = ({ setIsLoading }) => {
  let { id } = useParams();

  const { nameBlog } = useContext(ParamContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [articles, setArticles] = useState([]);

  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const totalHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      const footerHeight = document.querySelector("footer")?.offsetHeight || 0;
      const adjustedTotalHeight = totalHeight - footerHeight;

      const progress = (window.scrollY / adjustedTotalHeight) * 100;
      setScrollProgress(progress);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    document.title = `${nameBlog} | ${articles[0]?.title}}`;
  }, [nameBlog, articles]);

  if (articles) {
    <Helmet>
      <title>{articles[0]?.title}</title>
      <meta name="description" content={articles[0]?.description} />
    </Helmet>;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.artimarq.fr/back/blog/visualisation/${id}`
        );
        setArticles(Object.values(response.data));
        setIsLoading(false);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id, setIsLoading]);

  return (
    <div className="article-page">
      {articles[0]?.content ? (
        <div>
          <div dangerouslySetInnerHTML={{ __html: articles[0]?.content }}></div>
          <progress
            value={scrollProgress}
            max="100"
            className="progress-bar"
          ></progress>
        </div>
      ) : (
        <p>Contenu vide</p>
      )}
    </div>
  );
};

export default ArticleBlog;

import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import ContactForm from "../components/ContactForm";
import { ParamContext } from "../components/context/ParamContexte";

const Contacts = () => {
  const {
    nameCompany,
    nameContact,
    emailContact,
    phoneContact,
    addressContact,
    zipContact,
    cityContact,
    countryContact,
    facebook,
    instagram,
    tiktok,
    linkdin,
    imgOpenTags,
  } = useContext(ParamContext);

  const baseUrl = "https://api.artimarq.fr/public/images/";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formatNumber = (number) => {
    const numberString = number.toString();
    return numberString.replace(/\B(?=(\d{2})+(?!\d))/g, " ");
  };

  return (
    <div className="classic-page">
      <Helmet>
        <title>{`${nameCompany} | Contactez-nous`}</title>
        <meta
          property="og:title"
          content="Contactez-nous"
        />
        <meta property="og:description" content="Besoin d'aide ? Notre équipe est là pour répondre à vos questions." />
        <meta property="og:type" content="contact" />
        <meta property="og:image" content={baseUrl + imgOpenTags} />
        <meta property="og:url" content="https://artimarq.fr/contacts" />
        <meta property="og:site_name" content="ARTIMARQ site officiel" />
        <meta
          name="description"
          content="Besoin d'aide ? Notre équipe est là pour répondre à vos questions, discuter de vos besoins et vous offrir un service personnalisé. Contactez-nous dès maintenant!"
        />
        <link rel="canonical" href={`https://artimarq.fr/contacts`} />
      </Helmet>
      <h1 className="title-contact">Contactez-nous</h1>
      <div className="contact-page-content">
        <div className="left-side-contact">
          <div className="infos-contact-container">
            <div className="infos-container">
              <span>{nameContact}</span>
              <span>{addressContact}</span>
              <span>
                {zipContact}, {cityContact} - {countryContact}
              </span>
              <span>
                <a href={`mailto:${emailContact}`}>{emailContact}</a>
              </span>
              <span>
                <a href={`tel:${phoneContact}`}>{formatNumber(phoneContact)}</a>
              </span>
            </div>
          </div>
          <div className="networks-contact">
            <span>Suivez-nous</span>
            <div className="icon-contact-container">
              <a href={facebook} target="_blank" rel="noreferrer">
                <svg
                  className="icon-rs-contact"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 640"
                >
                  <path d="M380.001 120.001h99.993V0h-99.993c-77.186 0-139.986 62.8-139.986 139.986v60h-80.009V320h79.985v320h120.013V320h99.994l19.996-120.013h-119.99v-60.001c0-10.843 9.154-19.996 19.996-19.996v.012z" />
                </svg>
              </a>
              <a href={instagram} target="_blank" rel="noreferrer">
                <svg
                  className="icon-rs-contact"
                  xmlns="http://www.w3.org/2000/svg"
                  fillRule="evenodd"
                  viewBox="0 0 640 640"
                >
                  <path d="M184.715 2.752h270.582C556.908 2.752 640 84.816 640 185.069v269.85c0 100.277-83.092 182.317-184.703 182.317H184.715C83.104 637.236 0 555.196 0 454.919v-269.85C0 84.816 83.103 2.752 184.715 2.752zm133.561 145.939c98.741 0 178.868 80.127 178.868 178.868 0 98.753-80.127 178.868-178.868 178.868-98.765 0-178.88-80.115-178.88-178.868 0-98.741 80.115-178.868 178.88-178.868zm0 60.414c65.387 0 118.454 53.056 118.454 118.454s-53.068 118.466-118.454 118.466c-65.41 0-118.466-53.067-118.466-118.466 0-65.398 53.056-118.454 118.466-118.454zM491.321 123.9c16.04 0 29.044 13.004 29.044 29.032 0 16.04-13.004 29.044-29.044 29.044-16.028 0-29.032-13.004-29.032-29.044 0-16.028 13.004-29.032 29.032-29.032zM206.825 54.58H433.21c85.005 0 154.526 69.178 154.526 153.712V435.81c0 84.532-69.52 153.699-154.526 153.699H206.825c-85.005 0-154.537-69.167-154.537-153.7V208.29c0-84.532 69.532-153.71 154.537-153.71z" />
                </svg>
              </a>
              <a href={tiktok} target="_blank" rel="noreferrer">
                <svg
                  className="icon-rs-contact"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 2859 3333"
                >
                  <path d="M2081 0c55 473 319 755 778 785v532c-266 26-499-61-770-225v995c0 1264-1378 1659-1932 753-356-583-138-1606 1004-1647v561c-87 14-180 36-265 65-254 86-398 247-358 531 77 544 1075 705 992-358V1h551z" />
                </svg>
              </a>
              <a href={linkdin} target="_blank" rel="noreferrer">
                <svg
                  className="icon-rs-contact"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 640"
                >
                  <path d="M228.582 205.715h126.462v64.832h1.83c17.611-31.595 60.675-64.832 124.892-64.832C615.303 205.715 640 288.818 640 396.926v220.219H508.116V421.93c0-46.536-.969-106.442-68.576-106.442-68.67 0-79.194 50.658-79.194 103.052v198.605H228.581v-411.43zM137.152 91.43c0 37.855-30.721 68.576-68.576 68.576-37.855 0-68.587-30.721-68.587-68.576 0-37.855 30.732-68.576 68.587-68.576 37.855 0 68.576 30.721 68.576 68.576zM-.011 205.715h137.163v411.43H-.011v-411.43z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="right-side-contact">
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default Contacts;

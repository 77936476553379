import React, { useState } from "react";
import axios from "axios";
import Popup from "../../components/Popup";
import countriesData from "../../countries.json";
import NavbarBack from "../../components/back_office/NavbarBack";
import NavBarBackPage from "../../components/back_office/NavBarBackPage";

const CreateBill = () => {
  const [messageSupp, setMessageSupp] = useState("");
  const [warning, setWarning] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [formDataState, setFormDataState] = useState({
    orga: "",
    Adresse: "",
    country: "France",
    zip: "",
    city: "",
    lastname: "",
    firstName: "",
    email: "",
    phone: "",
    typeDiscount: "Rate",
    DiscountValue: "",
    shipping: "",
    distribeType: "Shipping",
    advance: "false",
  });

  const resetFormDataState = () => {
    setFormDataState({
      orga: "",
      Adresse: "",
      country: "",
      zip: "",
      city: "",
      lastname: "",
      firstName: "",
      email: "",
      phone: "",
      typeDiscount: "",
      DiscountValue: "",
      shipping: "",
      distribeType: "Shipping",
      advance: "false",
    });
  };

  const [togglePopup, setTogglePopup] = useState(false);

  const handleChange = (value, name) => {
    setFormDataState((prevState) => ({ ...prevState, [name]: value }));
  };

  function addInput() {
    const newInput = {
      nameService: "",
      qtyService: "",
      priceTaxFree: "",
      vat: "20",
    };
    setServices([...services, newInput]);
  }

  const CreateDevis = async () => {
    setIsLoading(true);

    const {
      Adresse,
      zip,
      city,
      lastname,
      firstName,
      email,
      phone,
      distribeType,
    } = formDataState;
    
    if (/[^a-zA-Z\s]/.test(lastname) || /[^a-zA-Z\s]/.test(firstName)) {
      setIsLoading(false);
      setMessageSupp("");
      setWarning(
        "Le nom et le prénom ne peuvent pas contenir de caractères spéciaux"
      );
      return;
    }

    if (
      !Adresse ||
      !zip ||
      !city ||
      !lastname ||
      !firstName ||
      !email ||
      !phone ||
      !distribeType ||
      services.length === 0
    ) {
      setIsLoading(false);
      setMessageSupp("");
      setWarning("Veuillez remplire tous les champs obligatoirs");
      return;
    }

    const convertToStrings = (data) => {
      const result = {};
      for (const key in data) {
        if (Array.isArray(data[key])) {
          result[key] = data[key];
        } else {
          result[key] = String(data[key]);
        }
      }
      return result;
    };

    const formatData = {
      fisrtNameDevis: formDataState.firstName,
      lastNameDevis: formDataState.lastname,
      organisationDevis: formDataState.orga,
      addressDevis: formDataState.Adresse,
      zipDevis: formDataState.zip,
      cityDevis: formDataState.city,
      countryDevis: formDataState.country,
      emailDevis: formDataState.email,
      phoneDevis: formDataState.phone,
      discount: formDataState.DiscountValue,
      typeDiscount: formDataState.typeDiscount,
      shipping: formDataState.shipping,
      advance: formDataState.advance,
      services: services,
    };

    const formattedData = convertToStrings(formatData);

    try {
      const response = await axios.post(
        "https://api.artimarq.fr/back/bills/create",
        formattedData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      // console.log(response);
      setMessageSupp(response.data.message);
      setServices([]);
      resetFormDataState();
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="body-website_back">
      <NavbarBack />
      <div className="back-page-content">
        <NavBarBackPage />
        <div>
          <div className="input-devis-up-container admin-content-page">
            <div className="input-devis-bloc">
              <div className="section-input-devis">
                <div className="input-devis-container">
                  <label htmlFor="orga">Organisation</label>
                  <input
                    id="orga"
                    name="orga"
                    value={formDataState.orga}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
                <div className="input-devis-container">
                  <label htmlFor="Adresse">Adresse *</label>
                  <input
                    name="Adresse"
                    id="Adresse"
                    value={formDataState.Adresse}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
              <div className="section-input-devis">
                <div className="input-devis-container">
                  <label>Pays *</label>
                  <select
                    name="country"
                    id="country"
                    value={formDataState.country}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  >
                    {countriesData.map((country) => (
                      <option key={country.code} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-devis-container">
                  <label htmlFor="zip">Code postal *</label>
                  <input
                    name="zip"
                    id="zip"
                    value={formDataState.zip}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
                <div className="input-devis-container">
                  <label htmlFor="city">Ville *</label>
                  <input
                    name="city"
                    id="city"
                    value={formDataState.city}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="input-devis-bloc">
              <div className="section-input-devis">
                <div className="input-devis-container">
                  <label htmlFor="lastname">Nom *</label>
                  <input
                    name="lastname"
                    id="lastname"
                    value={formDataState.lastname}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
                <div className="input-devis-container">
                  <label htmlFor="firstName">Prenom *</label>
                  <input
                    id="firstName"
                    name="firstName"
                    value={formDataState.firstName}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
              <div className="section-input-devis">
                <div className="input-devis-container">
                  <label htmlFor="email">Email *</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formDataState.email}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
                <div className="input-devis-container">
                  <label htmlFor="phone">Telephone *</label>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    value={formDataState.phone}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
              <div className="input-devis-container">
                <label>Accompte versé *</label>
                <select
                  name="advance"
                  id="advance"
                  value={formDataState.advance}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                >
                  <option value="false">Aucun acompte</option>
                  <option value="10">Acompte de 10%</option>
                  <option value="20">Acompte de 20%</option>
                  <option value="30">Acompte de 30%</option>
                  <option value="40">Acompte de 40%</option>
                  <option value="50">Acompte de 50%</option>
                </select>
              </div>
            </div>
          </div>
          <div className="presta-container">
            <h4>Prestations *</h4>
            {services.map((input, index) => (
              <div className="input-presta-container" key={index}>
                <div className="input-devis-container">
                  <label htmlFor={`presta-select-${index}`}>Prestation *</label>
                  <textarea
                    className={
                      services[index].nameService.length > 65
                        ? "text-too-long"
                        : ""
                    }
                    name="nameService"
                    cols="35"
                    rows="3"
                    maxLength={65}
                    id={`presta-select-${index}`}
                    onChange={(e) => {
                      const inputValue = e.target.value;

                      if (inputValue.length <= 65) {
                        const newInputs = [...services];
                        newInputs[index].nameService = inputValue;
                        setServices(newInputs);
                      } else {
                        const tronqueeValue =
                          inputValue.substring(0, 65) + "...";
                        const newInputs = [...services];
                        newInputs[index].nameService = tronqueeValue;
                        setServices(newInputs);
                      }
                    }}
                  />
                  <small>65 caractères maximum</small>
                </div>
                <div className="input-devis-container">
                  <label htmlFor={`qty-input-${index}`}>Quantité *</label>
                  <input
                    name="qtyService"
                    id={`qty-input-${index}`}
                    value={input.qtyService}
                    onChange={(e) => {
                      const newInputs = [...services];
                      newInputs[index].qtyService = e.target.value;
                      setServices(newInputs);
                    }}
                    min="0"
                    step="1"
                  />
                </div>
                <div className="input-devis-container">
                  <label htmlFor={`price-select-${index}`}>PU.HT *</label>
                  <input
                    name="priceTaxFree"
                    id={`price-select-${index}`}
                    onChange={(e) => {
                      const newInputs = [...services];
                      newInputs[index].priceTaxFree = e.target.value;
                      setServices(newInputs);
                    }}
                  />
                </div>
                <div className="input-devis-container">
                  <label htmlFor={`vat-select-${index}`}>TVA *</label>
                  <select
                    name="presta"
                    id={`vat-select-${index}`}
                    defaultValue="20"
                    onChange={(e) => {
                      const newInputs = [...services];
                      newInputs[index].vat = e.target.value;
                      setServices(newInputs);
                    }}
                  >
                    <option value="5.5">5.5 %</option>
                    <option value="10">10 %</option>
                    <option value="20">20 %</option>
                    <option value="0">Hors TVA</option>
                  </select>
                </div>
                <div>
                  <button
                    className="remove-presta-button"
                    onClick={() => {
                      const newInputs = [...services];
                      newInputs.splice(index, 1);
                      setServices(newInputs);
                    }}
                  >
                    Retirer
                  </button>
                </div>
              </div>
            ))}
            <div>
              <button className="black-button" onClick={addInput}>
                Ajouter une services
              </button>
            </div>
          </div>
          <div className="shipping_devis_container">
            <h4>Distribution *</h4>
            <div className="section-input-devis">
              <div className="input-devis-container">
                <label>Type de distribution</label>
                <select
                  name="distribeType"
                  value={formDataState.distribeType}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                >
                  <option value="Shipping">Livraison</option>
                  <option value="PickUp">Recuperation a nos locaux</option>
                </select>
              </div>
              <div className="input-devis-container">
                <label htmlFor="shipping">Frais de livraison</label>
                <input
                  id="shipping"
                  name="shipping"
                  value={formDataState.shipping}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="discount_devis_container">
            <h4>Remise</h4>
            <div className="section-input-devis">
              <div className="input-devis-container">
                <label>Type de remise</label>
                <select
                  name="discountType"
                  value={formDataState.discountType}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                >
                  <option value="Rate">Pourcentage</option>
                  <option value="Fixed">Soutraction</option>
                </select>
              </div>
              <div className="input-devis-container">
                <label htmlFor="DiscountValue">Valeur de la remise</label>
                <input
                  id="DiscountValue"
                  name="DiscountValue"
                  value={formDataState.DiscountValue}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="footer-devis-page">
            <div className="btn-devis-page">
              <button className="black-button" onClick={resetFormDataState}>
                annuler
              </button>
              <button
                className="black-button"
                onClick={CreateDevis}
                disabled={isLoading}
              >
                {isLoading ? "Chargement..." : "Créer"}
              </button>
            </div>
          </div>
          <div className="alertSuccess">{warning}</div>
          <div className="alertSuccess">{messageSupp}</div>
          <Popup
            togglePopup={togglePopup}
            setTogglePopup={setTogglePopup}
            onSubmite={CreateDevis}
            messagePopup="En confirmant la creation, le devis sera immediatement envoyé au demandeur !"
            comeFrom="create devis"
          />
        </div>
      </div>
    </div>
  );
};

export default CreateBill;

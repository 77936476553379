import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import axios from "axios";

const NewsletterPage = ({ setIsLoading }) => {
  let { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.artimarq.fr/back/newsletter/visualisation/${id}`
        );
        setArticles(Object.values(response.data));
        setIsLoading(false);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id, setIsLoading]);

  return (
    <div className="article-page">
      <div className="btn-back">
        <NavLink to="/back/newsletter/articles">
          <button className="black-button">Retour</button>
        </NavLink>
      </div>
      {articles[0]?.content ? (
        <div>
          <div dangerouslySetInnerHTML={{ __html: articles[0]?.content }}></div>
        </div>
      ) : (
        <p>Contenu vide</p>
      )}
    </div>
  );
};

export default NewsletterPage;

import { NavLink } from "react-router-dom";
import ProductCard from "../components/ProductCard";
import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { ParamContext } from "../components/context/ParamContexte";

const SweatsShirts = () => {
  const { products, nameCompany, imgOpenTags } = useContext(ParamContext);
  const baseUrl = "https://api.artimarq.fr/public/images/";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const productsFiltered = products
    .filter((product) => product.nameCategory === "Sweat-shirts")
    .sort((a, b) => {
      if (a.genderProduct === "Hommes" && b.genderProduct !== "Hommes") {
        return -1;
      } else if (b.genderProduct === "Hommes" && a.genderProduct !== "Hommes") {
        return 1;
      } else if (a.genderProduct === "Femmes" && b.genderProduct !== "Femmes") {
        return -1;
      } else if (b.genderProduct === "Femmes" && a.genderProduct !== "Femmes") {
        return 1;
      } else {
        return 0;
      }
    });

  let dataToDisplay;
  if (productsFiltered.length > 0) {
    dataToDisplay = productsFiltered.map((product) => (
      <ProductCard
        key={product.idProduct}
        idProduct={product.idProduct}
        nameProduct={product.nameProduct}
        images={product.nameMedia}
      />
    ));
  } else {
    dataToDisplay = (
      <div className="contentEmpty">
        <h3>
          Un problème est survenue ! Si celui-ci persiste, n'hesitez pas a nous{" "}
          <NavLink to="/conatcts">contacter</NavLink> directement.
        </h3>
      </div>
    );
  }

  return (
    <div className="classic-page products-pages">
      <Helmet>
        <title>{`${nameCompany} | Nos categories de sweat-shirts`}</title>
        <meta property="og:title" content="Nos categories de sweat-shirts" />
        <meta
          property="og:description"
          content="Optez pour nos sweat-shirts personnalisables."
        />
        <meta property="og:type" content="product" />
        <meta property="og:image" content={baseUrl + imgOpenTags} />
        <meta property="og:url" content="https://artimarq.fr/sweats-shirts" />
        <meta property="og:site_name" content="ARTIMARQ site officiel" />
        <meta
          name="description"
          content="Optez pour nos sweat-shirts personnalisables : confort de qualité et canevas parfait pour votre personnalisation."
        />
        <link rel="canonical" href={`https://artimarq.fr/sweats-shirts`} />
      </Helmet>
      <div className="head-products-pages">
        <h1>Sweat-shirts à personnalisés : Affirmez votre identité</h1>
        <p>
          Explorez l'excellence de la personnalisation en créant votre
          sweat-shirt selon vos caractéristiques, vos préférences et votre sens
          du style. Notre vaste sélection d'options vous offre l'opportunité de
          façonner le sweat-shirt qui vous ressemble.
        </p>
      </div>
      <div className="body-products-pages">
        {products.length > 0 ? (
          <div className="content-product">{dataToDisplay}</div>
        ) : (
          <div>{dataToDisplay}</div>
        )}
      </div>
    </div>
  );
};

export default SweatsShirts;

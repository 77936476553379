import React from "react";

const LoadingPage = () => {
  return (
    <div className="loadingPage">
        <div className="loader"></div>
    </div>
  );
};

export default LoadingPage;

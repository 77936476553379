import { useContext, useEffect } from "react";
import { ParamContext } from "../../components/context/ParamContexte";
import ArticleBlogCard from "./ArticleBlogCard";
import { Helmet } from "react-helmet";
import NavBlog from "./NavBlog";

const Blog = () => {
  const { articles, nameBlog } = useContext(ParamContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const articleFiltered = articles.filter(
    (product) => product.categories === "Nouveautés et Tendances"
  );

  let dataToDisplay;
  if (articleFiltered.length > 0) {
    dataToDisplay = articleFiltered.map((product) => (
      <ArticleBlogCard key={product.idArticle} idArticle={product.idArticle} />
    ));
  } else {
    dataToDisplay = (
      <div className="content-empty-blog">
        <p>Il n'y a aucun article pour le moment</p>
      </div>
    );
  }

  return (
    <div>
      <Helmet>
        <title>{nameBlog} | Nouveautés et Tendances</title>
        <meta
          name="description"
          content="Explorez les dernières nouveautés et tendances en matière de personnalisation"
        />
        <link
          rel="canonical"
          href={`https://artimarq.fr/artimarq-le-blog/nouveautes-et-tendences`}
        />
      </Helmet>
      <NavBlog />
      <section className="blog-content">
        {articleFiltered.length > 0 ? (
          <div className="articles-content">{dataToDisplay}</div>
        ) : (
          <div>{dataToDisplay}</div>
        )}
      </section>
    </div>
  );
};

export default Blog;

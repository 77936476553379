const ProductCard = (props) => {
  const baseUrl = "https://api.artimarq.fr/public/images/";
  let bg_img = `url(${baseUrl}${props.images})`;
  let {
    idProduct,
    nameProduct,
  } = props;

  return (
    <div className="productCardContainer">
      <div className="warpper">
        <a href={`/produit/${idProduct}`} rel="nofollow">
          <div
            className="imgProductContainer"
            style={{ backgroundImage: bg_img }}
            alt="product"
          ></div>
          <div className="productCardOverlay">
            <p className="productCardText">{nameProduct}</p>
          </div>
        </a>
      </div>
    </div>
  );
};

export default ProductCard;

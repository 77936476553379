import React, { useState } from "react";
import axios from "axios";
import countriesData from "../../countries.json";
import NavbarBack from "../../components/back_office/NavbarBack";
import NavBarBackPage from "../../components/back_office/NavBarBackPage";

const CreateCustomerCard = () => {
  const [messageSupp, setMessageSupp] = useState("");
  const [warning, setWarning] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formDataState, setFormDataState] = useState({
    radioOrga: "",
    orgaName: "",
    Adresse: "",
    country: "France",
    zip: "",
    city: "",
    civility: "",
    lastname: "",
    firstName: "",
    email: "",
    phone: "",
  });

  const resetFormDataState = () => {
    setFormDataState({
      radioOrga: "",
      orgaName: "",
      Adresse: "",
      country: "",
      zip: "",
      city: "",
      civility: "",
      lastname: "",
      firstName: "",
      email: "",
      phone: "",
    });
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const options = [
    "Association",
    "Collectivité",
    "Entreprise",
    "Marque",
    "Particulier",
  ];

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleChange = (value, name) => {
    setFormDataState((prevState) => ({ ...prevState, [name]: value }));
  };

  const CreateDevis = async () => {
    setIsLoading(true);

    const {
      radioOrga,
      Adresse,
      zip,
      city,
      civility,
      lastname,
      firstName,
      email,
      phone,
    } = formDataState;

    if (
      !radioOrga &&
      !Adresse &&
      !zip &&
      !city &&
      !civility &&
      !lastname &&
      !firstName &&
      !email &&
      !phone
    ) {
      setIsLoading(false);
      setMessageSupp("");
      setWarning("Veuillez compléter tous les champs obligatoirs");
      return;
    }

    const convertToStrings = (data) => {
      const result = {};
      for (const key in data) {
        if (Array.isArray(data[key])) {
          result[key] = data[key];
        } else {
          result[key] = String(data[key]);
        }
      }
      return result;
    };

    const formatData = {
      civility: formDataState.civility,
      fisrtName: formDataState.firstName,
      lastName: formDataState.lastname,
      organisationType: formDataState.radioOrga,
      organisation: formDataState.orgaName,
      address: formDataState.Adresse,
      zip: formDataState.zip,
      city: formDataState.city,
      country: formDataState.country,
      email: formDataState.email,
      phone: formDataState.phone,
    };

    const formattedData = convertToStrings(formatData);

    try {
      const response = await axios.post(
        "https://api.artimarq.fr/back/leads/Create",
        formattedData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      // console.log(response);
      setMessageSupp(response.data.message);
      resetFormDataState();
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="body-website_back">
      <NavbarBack />
      <div className="back-page-content">
        <NavBarBackPage />
        <div>
          <div>
            <div className="input-devis-up-container admin-content-page">
              <div className="organisation-infos">
                <div className="body-ask-devis">
                  {options.map((option) => (
                    <label
                      key={option}
                      htmlFor={option}
                      className={`orga-items ${
                        selectedOption === option ? "orga-selected" : ""
                      }`}
                    >
                      <input
                        id={option}
                        type="radio"
                        name="radioOrga"
                        value={option}
                        checked={selectedOption === option}
                        onChange={(e) => {
                          handleChange(e.target.value, e.target.name);
                          handleOptionChange(e);
                        }}
                      />
                      {option}
                    </label>
                  ))}
                </div>
              </div>
              <div className="input-devis-bloc">
                <div className="section-input-devis">
                  <div className="input-devis-container">
                    <label htmlFor="orgaName">Organisation</label>
                    <input
                      id="orgaName"
                      name="orgaName"
                      value={formDataState.orgaName}
                      onChange={(e) => {
                        handleChange(e.target.value, e.target.name);
                      }}
                    />
                  </div>
                  <div className="input-devis-container">
                    <label htmlFor="Adresse">Adresse *</label>
                    <input
                      name="Adresse"
                      id="Adresse"
                      value={formDataState.Adresse}
                      onChange={(e) => {
                        handleChange(e.target.value, e.target.name);
                      }}
                    />
                  </div>
                </div>
                <div className="section-input-devis">
                  <div className="input-devis-container">
                    <label>Pays *</label>
                    <select
                      name="country"
                      id="country"
                      value={formDataState.country}
                      onChange={(e) => {
                        handleChange(e.target.value, e.target.name);
                      }}
                    >
                      {countriesData.map((country) => (
                        <option key={country.code} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="input-devis-container">
                    <label htmlFor="zip">Code postal *</label>
                    <input
                      name="zip"
                      id="zip"
                      value={formDataState.zip}
                      onChange={(e) => {
                        handleChange(e.target.value, e.target.name);
                      }}
                    />
                  </div>
                  <div className="input-devis-container">
                    <label htmlFor="city">Ville *</label>
                    <input
                      name="city"
                      id="city"
                      value={formDataState.city}
                      onChange={(e) => {
                        handleChange(e.target.value, e.target.name);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="input-devis-bloc">
                <div className="civility-devis-container">
                  <div className="civility-opt">
                    <label htmlFor="M">
                      <input
                        id="M"
                        type="radio"
                        name="civility"
                        className="radio-civ-leads"
                        value="M"
                        onChange={(e) => {
                          handleChange(e.target.value, e.target.name);
                        }}
                      />
                      M
                    </label>
                  </div>
                  <div className="civility-opt">
                    <label htmlFor="Mme">
                      <input
                        id="Mme"
                        type="radio"
                        name="civility"
                        className="radio-civ-leads"
                        value="Mme"
                        onChange={(e) => {
                          handleChange(e.target.value, e.target.name);
                        }}
                      />
                      Mme
                    </label>
                  </div>
                </div>
                <div className="section-input-devis">
                  <div className="input-devis-container">
                    <label htmlFor="firstName">Nom *</label>
                    <input
                      id="firstName"
                      name="firstName"
                      value={formDataState.firstName}
                      onChange={(e) => {
                        handleChange(e.target.value, e.target.name);
                      }}
                    />
                  </div>
                  <div className="input-devis-container">
                    <label htmlFor="lastname">Prenom *</label>
                    <input
                      name="lastname"
                      id="lastname"
                      value={formDataState.lastname}
                      onChange={(e) => {
                        handleChange(e.target.value, e.target.name);
                      }}
                    />
                  </div>
                </div>
                <div className="section-input-devis">
                  <div className="input-devis-container">
                    <label htmlFor="email">Email *</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formDataState.email}
                      onChange={(e) => {
                        handleChange(e.target.value, e.target.name);
                      }}
                    />
                  </div>
                  <div className="input-devis-container">
                    <label htmlFor="phone">Telephone *</label>
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      value={formDataState.phone}
                      onChange={(e) => {
                        handleChange(e.target.value, e.target.name);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-devis-page">
              <div className="btn-devis-page">
                <button className="black-button" onClick={resetFormDataState}>
                  annuler
                </button>
                <button
                  className="black-button"
                  onClick={CreateDevis}
                  disabled={isLoading}
                >
                  {isLoading ? "Chargement..." : "Créer"}
                </button>
              </div>
            </div>
            <div className="alertSuccess">{warning}</div>
            <div className="alertSuccess">{messageSupp}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCustomerCard;

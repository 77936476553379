import React from "react";

const SizesParam = (props) => {
  const { category, nameProduct, setSelectedSizes, selectedSizes } = props;

  const handleSizeClick = (size, quantity) => {
    setSelectedSizes((prevSizesData) => ({
      ...prevSizesData,
      [size]: quantity,
    }));
  };

  const optionsSizes = ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL"];

  const renderSizeInputs = () => {
    return optionsSizes.map((option) => (
      <div className="sizes-items" key={option}>
        <div className="size-label-container">
          <label htmlFor={option}>{option}</label>
        </div>
        <input
          id={option}
          type="number"
          value={selectedSizes[option] || ""}
          onChange={(e) => handleSizeClick(option, e.target.value)}
        />
      </div>
    ));
  };

  return (
    <div>
      <div className="body-custom-param">
        {(category !== "Accessoires" && category !== "Autres produits") ||
        nameProduct === "Débardeurs hommes" ? (
          <div className="sizes-container">{renderSizeInputs()}</div>
        ) : null}
      </div>
      {(category === "Accessoires" ||
        (category === "Autres produits" &&
          nameProduct !== "Débardeurs hommes")) && (
        <div className="body-custom-param">
          <div className="sizes-container">
            <div className="sizes-items">
              <label htmlFor="qty">Quantité</label>
              <input
                id="qty"
                type="number"
                value={
                  selectedSizes["quantité"] !== null
                    ? selectedSizes["quantité"]
                    : ""
                }
                onChange={(e) => handleSizeClick("quantité", e.target.value)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SizesParam;

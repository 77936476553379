import { useEffect, useState } from "react";
import axios from "axios";

const ZoneParam = (props) => {
  const {
    productType,
    selectedZones,
    setSelectedZones,
    setIsLoading,
    isFaceActive,
    isBackActive,
    isSideActive,
  } = props;

  const [zones, setZones] = useState([]);

  const baseUrl = "https://api.artimarq.fr/public/images/";

  const handleZoneClick = (zoneName) => {
    if (selectedZones.includes(zoneName)) {
      setSelectedZones(selectedZones.filter((zone) => zone !== zoneName));
    } else {
      setSelectedZones([...selectedZones, zoneName]);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const zonesResponse = await axios.get(
          "https://api.artimarq.fr/front/zones"
        );

        const zonesData = Object.values(zonesResponse.data);
        setZones(zonesData);
        setIsLoading(false);
      } catch (error) {
        console.error(
          "Une erreur s'est produite lors de la récupération des données.",
          error
        );
        setIsLoading(false);
      }
    };

    fetchData();
  }, [setIsLoading]);

  const renderZones = (side) => {
    return zones
      .filter((zone) => zone.textil === productType)
      .filter((zone) => zone.side === side)
      .map((zone) => (
        <div key={zone.id}>
          <label
            htmlFor={zone.side + " " + zone.name}
            className={
              selectedZones.includes(zone.side + " " + zone.name)
                ? "selected-background"
                : ""
            }
          >
            <img src={baseUrl + zone.img} alt={zone.name} />
            <span>{zone.name}</span>
          </label>
          <input
            id={zone.side + " " + zone.name}
            type="checkbox"
            value={selectedZones}
            onClick={() => handleZoneClick(zone.side + " " + zone.name)}
          />
        </div>
      ));
  };

  return (
    <div className="container-param">
      <div className="body-custom-param">
        {isFaceActive === "1" && <span>Face</span>}
        {isFaceActive === "1" && <div className="zones-container">{renderZones("face")}</div>}
      </div>
      <div className="body-custom-param">
        {isBackActive === "1" && <span>Dos</span>}
        {isBackActive === "1" && <div className="zones-container">{renderZones("back")}</div>}
      </div>
      <div className="body-custom-param">
        {isSideActive === "1" && <span>Coté</span>}
        {isSideActive === "1" && <div className="zones-container">{renderZones("side")}</div>}
      </div>
    </div>
  );
};

export default ZoneParam;

import React, { useContext, useEffect, useState } from "react";
import { ParamContext } from "../context/ParamContexte";
import { NavLink } from "react-router-dom";
import axios from "axios";

const NavbarBack = () => {
  const { logo, handleLogoutAdmin, adminData } = useContext(ParamContext);

  useEffect(() => {
    if (adminData.adminAcces === "1") {
      setRank("administrateur");
    } else {
      setRank("collaborateur");
    }
  }, [adminData]);

  const [rank, setRank] = useState("");
  const [messages, setMessages] = useState([]);

  const countMessages = () => {
    const pendingOrders = messages.filter(
      (message) => message.isReplyed === "0"
    );
    return pendingOrders.length;
  };

  const newMessages = countMessages();

  useEffect(() => {
    const getMessages = () => {
      axios
        .get(`https://api.artimarq.fr/back/messages/getMessages`)
        .then((res) => {
          const infoObj = Object.values(res.data);
          setMessages(infoObj);
        });
    };
    getMessages();
  }, []);

  return (
    <div className="navbar-back-container">
      <div className="img-navBack-container">
        <img src={logo} alt="Logo" />
      </div>
      <ul>
        <li>
          <NavLink
            to="/back/demandes-de-devis"
            className="items-back-link"
            activeclassname="active"
          >
            Gestion devis
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/back/gestion-clients"
            className="items-back-link"
            activeclassname="active"
          >
            Gestion clients
          </NavLink>
        </li>
        {adminData.adminAcces === "1" && (
          <li>
            <NavLink
              to="/back/gestion-equipe"
              className="items-back-link"
              activeclassname="active"
            >
              Gestion equipe
            </NavLink>
          </li>
        )}
        {adminData.adminAcces === "1" && (
          <li>
            <NavLink
              to="/back/gestion-produits"
              className="items-back-link"
              activeclassname="active"
            >
              Gestion produits
            </NavLink>
          </li>
        )}
        <li>
          <NavLink
            to="/back/messagerie"
            className="items-back-link"
            activeclassname="active"
          >
            {newMessages > 0 && (
              <div className="badgeNewOrder">{newMessages}</div>
            )}
            Messages
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/back/factures"
            className="items-back-link"
            activeclassname="active"
          >
            Facturation
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/back/blog/articles"
            className="items-back-link"
            activeclassname="active"
          >
            Le Blog
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/back/newsletter/articles"
            className="items-back-link"
            activeclassname="active"
          >
            Newsletter
          </NavLink>
        </li>
        {adminData.adminAcces === "1" && (
          <li>
            <NavLink
              to="/back/parametres"
              className="items-back-link"
              activeclassname="active"
            >
              Parametres
            </NavLink>
          </li>
        )}
      </ul>
      <div className="footer-navbar-back">
        <div className="data-admin">
          <span>{adminData.firstName}</span>
          <span>{rank}</span>
        </div>
        <div className="logout-container">
          <button className="black-button" onClick={handleLogoutAdmin}>
            Se deconecter
          </button>
        </div>
      </div>
    </div>
  );
};

export default NavbarBack;

import React, { useContext, useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import CurtainsMenu from "./CurtainsMenu";
import { ParamContext } from "./context/ParamContexte";

const FrontNavBar = () => {
  const { logo, facebook, instagram, tiktok, linkdin } =
    useContext(ParamContext);

  const [toggleSubMenu, setToggleSubMenu] = useState(false);
  const [toggleCross, setToggleCross] = useState(false);
  const [toggleNav, setToggleNav] = useState(false);

  const subMenuRef = useRef();
  const curtainsMenuRef = useRef();
  const location = useLocation();

  useEffect(() => {
    if (
      !(
        location.pathname.startsWith("/back/") ||
        location.pathname === "/desinscription-newsletter" ||
        location.pathname === "/carte-de-visite"
      )
    ) {
      let handler = (e) => {
        if (!subMenuRef.current.contains(e.target)) {
          setToggleSubMenu(false);
        }
        if (!curtainsMenuRef.current.contains(e.target)) {
          setToggleNav(false);
          setToggleCross(false);
        }
      };
      document.addEventListener("mousedown", handler);
      return () => {
        document.removeEventListener("mousedown", handler);
      };
    }
  }, [location]);

  const closeSubMenu = () => {
    setToggleSubMenu(false);
  };

  const toggleNavFunc = () => {
    setToggleNav(!toggleNav);
    setToggleCross(!toggleCross);
  };

  return (
    !(
      location.pathname.startsWith("/back/") ||
      location.pathname === "/desinscription-newsletter" ||
      location.pathname === "/carte-de-visite"
    ) && (
      <div>
        <nav className="navBar">
          <div className="menu-curtains-btn" ref={curtainsMenuRef}>
            <div className={toggleCross ? "btn-crossed" : "menuButton"}>
              <div className="menuButton" onClick={toggleNavFunc}>
                <div className="menu-btn-burger"></div>
              </div>
            </div>
            <CurtainsMenu
              toggleNav={toggleNav}
              setToggleNav={setToggleNav}
              setToggleCross={setToggleCross}
              facebook={facebook}
              instagram={instagram}
              tiktok={tiktok}
              linkdin={linkdin}
            />
          </div>
          <div
            className={`bg-curtains-menu ${toggleNav ? "active" : "inactive"}`}
          ></div>
          <div className="nav-left-container">
            <div className="img-nav-container">
              <a href="/">
                <img src={logo} alt="Page d'acceuil" />
              </a>
            </div>
            <div className="menu-button" ref={subMenuRef}>
              <button
                className="transparent-button"
                onClick={() => {
                  setToggleSubMenu(!toggleSubMenu);
                }}
              >
                NOS PRODUITS
              </button>
              <ul
                className={`SubMenu ${toggleSubMenu ? "active" : "inactive"}`}
              >
                <li>
                  <NavLink to="/t-shirts" onClick={closeSubMenu}>
                    T-shirts
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/polos" onClick={closeSubMenu}>
                    Polos
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/chemises" onClick={closeSubMenu}>
                    Chemises
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/sweats-shirts" onClick={closeSubMenu}>
                    Sweat-shirts
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/hoodies" onClick={closeSubMenu}>
                    Hoodies
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/pantalons" onClick={closeSubMenu}>
                    Pantalons
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/Shorts" onClick={closeSubMenu}>
                    Shorts
                  </NavLink>
                </li>
                <li>
                  <NavLink to="accessoires" onClick={closeSubMenu}>
                    Accessoires
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/autres-produits" onClick={closeSubMenu}>
                    Autres produits
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/ecussons" onClick={closeSubMenu}>
                    Ecussons
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="def-link">
              <NavLink to="/marquages">NOS MARQUAGES</NavLink>
            </div>
            <div className="def-link">
              <NavLink to="/artimarq-le-blog/nouveautes-et-tendences">
                LE BLOG
              </NavLink>
            </div>
          </div>
          <div className="nav-right-container">
            <NavLink to="/demande-de-devis">
              <button className="black-button">Demander un devis</button>
            </NavLink>
          </div>
        </nav>
        {/* {showPastille &&
          !location.pathname.startsWith("/artimarq-le-blog") &&
          !location.pathname.startsWith("/artimarq-le-blog/") && (
            <div className="product-img-pastille">
              Les images des produits sont à titre illustratif. Nous choisirons
              le produit parfait ensemble.
              <div>
                <button className="accept-button">
                  J'ai compris
                </button>
              </div>
            </div>
          )} */}
      </div>
    )
  );
};

export default FrontNavBar;

import React, { useEffect, useState } from "react";
import NavBarBackPage from "../../components/back_office/NavBarBackPage";
import NavbarBack from "../../components/back_office/NavbarBack";
import DevisCardBack from "../../components/DevisCardBack";
import axios from "axios";

const VisualDevis = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [devisData, setDevisData] = useState([]);

  useEffect(() => {
    const getDevis = async () => {
      await axios
        .get(`https://api.artimarq.fr/back/devis/visualisations`)
        .then((res) => {
          const infoObj = Object.values(res.data);
          const sortedData = infoObj.sort(
            (a, b) => new Date(b.dateDevis) - new Date(a.dateDevis)
          );
          setDevisData(sortedData);
        });
    };
    getDevis();
  }, []);

  let dataToDisplay;
  if (devisData.length > 0) {
    dataToDisplay = devisData.map((devis) => (
      <DevisCardBack key={devis.id} id={devis.id} />
    ));
  } else {
    dataToDisplay = (
      <div className="contentEmpty">
        <h3>Vous n'avez aucun devis en cours pour l'instant.</h3>
      </div>
    );
  }

  return (
    <div className="body-website_back">
      <NavbarBack />
      <div className="back-page-content">
        <NavBarBackPage />
        <div className="admin-content-page">
          <div className="devis-back-container">{dataToDisplay}</div>
        </div>
      </div>
    </div>
  );
};

export default VisualDevis;

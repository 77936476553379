const TechnicsParam = (props) => {
  const { selectedTechnic, setSelectedTechnic, technics } = props;

  const handleTechnicsClick = (option) => {
    setSelectedTechnic(option);
  };

  return (
    <div className="body-custom-param">
      <div className="technic-container">
        {technics?.map((option) => (
          <div key={option.idTechnic}>
            <label
              htmlFor={option.nameTechnic}
              className={` ${
                selectedTechnic === option.nameTechnic ? "selected-background" : ""
              }`}
            >
              {option.nameTechnic}
            </label>
            <input
              id={option.nameTechnic}
              type="radio"
              value={option.nameTechnic}
              onClick={() => handleTechnicsClick(option.nameTechnic)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TechnicsParam;

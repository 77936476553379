import axios from "axios";
import React, { useEffect, useState } from "react";
import qs from "qs";
import Popup from "../Popup";
import JoinCustomer from "./JoinCustomer";
import DetailOrder from "./DetailOrder";

const OrderCardBack = (props) => {
  let { idOrders } = props;

  const [order, setOrder] = useState([]);
  const [togglePopup, setTogglePopup] = useState(false);
  const [toggleDetails, setToggleDetails] = useState(false);
  const [toggleJoin, setToggleJoin] = useState(false);

  useEffect(() => {
    const getOrder = async () => {
      await axios
        .get(`https://api.artimarq.fr/back/orders/visualisation/${idOrders}`)
        .then((res) => {
          const ordersObj = Object.values(res.data);
          setOrder(ordersObj[0]);
        });
    };
    getOrder();
  }, [idOrders]);

  const datePerser = (date) => {
    if (date) {
      const newDate = new Date(date);
      newDate.setHours(newDate.getHours() + 2);
      const day = newDate.getDate().toString().padStart(2, "0");
      const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
      const year = newDate.getFullYear();
      const hours = newDate.getHours().toString().padStart(2, "0");
      const minutes = newDate.getMinutes().toString().padStart(2, "0");
  
      return `${day}/${month}/${year} à ${hours}h:${minutes}m`;
    } else {
      return "";
    }
  };
  

  const deleteOrder = () => {
    const formatData = {
      idOrders,
    };

    const formatIMG = {
      nameMedia: order?.media,
    };

    axios
      .post(
        "https://api.artimarq.fr/back/orders/delete",
        qs.stringify(formatData),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post(
        "https://api.artimarq.fr/back/images/delete",
        qs.stringify(formatIMG),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateOrder = () => {
    const formatData = {
      idOrders,
    };

    axios
      .post(
        "https://api.artimarq.fr/back/orders/updateOrder",
        qs.stringify(formatData),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function isOrderExpired(dateOrder) {
    const currentDate = new Date();
    const expirationDate = new Date(dateOrder);

    expirationDate.setDate(expirationDate.getDate() + 3);

    if (currentDate > expirationDate) {
      expirationDate.setDate(expirationDate.getDate() + 4);

      if (currentDate > expirationDate) {
        return {
          expired: true,
          daysPassed: Math.floor(
            (currentDate - expirationDate) / (1000 * 60 * 60 * 24)
          ),
          expiredClass: "orderCardExpired30",
        };
      }

      return {
        expired: true,
        daysPassed: Math.floor(
          (currentDate - expirationDate) / (1000 * 60 * 60 * 24)
        ),
        expiredClass: "orderCardExpired14",
      };
    }

    return {
      expired: false,
      daysPassed: 0,
      expiredClass: "",
    };
  }

  const orderExpired = isOrderExpired(order?.dateOrder);

  return (
    <ul className="devis-card-back">
      <li>
        <h5>Demandeur</h5>
        <span>
          {order?.civilityLeads} {order?.firstNameLeads} {order?.lastNameLeads}
        </span>
      </li>
      <li>
        <h5>Organisation</h5>
        <span>{order?.OrganisationLeads}</span>
      </li>
      <li>
        <h5>Type d'organisation</h5>
        <span>
          {order?.OrganisationType ? order?.OrganisationType : "Non renseigné"}
        </span>
      </li>
      <li>
        <h5>Date</h5>
        <span className={orderExpired.expiredClass}>
          {datePerser(order?.dateOrder)}
        </span>
      </li>
      <li className="last-item">
        <button
          className={`card-btn ${
            order?.isConsulted === "1" ? "" : "unconsulted"
          }`}
          onClick={() => {
            setToggleDetails(true);
            updateOrder();
          }}
        >
          Consulter
        </button>
        <button
          className="card-btn"
          onClick={() => {
            setTogglePopup(true);
          }}
        >
          Supprimer
        </button>
        <button className="card-btn" onClick={() => setToggleJoin(true)}>
          Joindre le client
        </button>
      </li>
      <Popup
        togglePopup={togglePopup}
        setTogglePopup={setTogglePopup}
        onSubmite={deleteOrder}
        messagePopup="La suppression est irreversible, êtes-vous certain de vouloir supprimer cette commande ?"
        comeFrom="orderDeletion"
      />
      <JoinCustomer
        toggleJoin={toggleJoin}
        setToggleJoin={setToggleJoin}
        emailLeads={order?.emailLeads}
        firstNameLeads={order?.firstNameLeads}
        lastNameLeads={order?.lastNameLeads}
      />
      <DetailOrder
        toggleDetails={toggleDetails}
        setToggleDetails={setToggleDetails}
        dateOrder={order?.dateOrder}
        statut={order?.statut}
        printingType={order?.printingType}
        civilityLeads={order?.civilityLeads}
        firstNameLeads={order?.firstNameLeads}
        lastNameLeads={order?.lastNameLeads}
        emailLeads={order?.emailLeads}
        phoneLeads={order?.phoneLeads}
        textileName={order?.textileName}
        colorTextil={order?.colorTextil}
        gsmTextil={order?.gsmTextil}
        messageOrder={order?.messageOrder}
        option1={order?.option1}
        option2={order?.option2}
        option3={order?.option3}
        option4={order?.option4}
        option5={order?.option5}
        orderDelay={order?.orderDelay}
        OrganisationLeads={order?.OrganisationLeads}
        OrganisationType={order?.OrganisationType}
        organisationAddress={order?.organisationAddress}
        organisationCity={order?.organisationCity}
        organisationCountry={order?.organisationCountry}
        organisationZip={order?.organisationZip}
        qty={order?.qty}
        zones={order?.zones}
        media={order?.media}
      />
    </ul>
  );
};

export default OrderCardBack;

import React, { useContext, useState } from "react";
import { ParamContext } from "./context/ParamContexte";
import { useLocation } from "react-router-dom";

const DisplayerPopup = () => {
  const { popupMessage, isdisplaypfopup } = useContext(ParamContext);

  const location = useLocation();

  const [showPopup, setShowPopup] = useState(
    JSON.parse(sessionStorage.getItem("consent")) !== null
      ? JSON.parse(sessionStorage.getItem("consent"))
      : true
  );

  const handleAcceptCookies = () => {
    setShowPopup(false);
    sessionStorage.setItem("consent", JSON.stringify(false));
  };

  return (
    <>
      {showPopup &&
        isdisplaypfopup === "true" &&
        !location.pathname.startsWith("/back/") && (
          <div className="cookie-popup">
            <div className="cookie-content">
              <div>{popupMessage}</div>
              <button className="black-button" onClick={handleAcceptCookies}>
                J'ai compris
              </button>
            </div>
          </div>
        )}
    </>
  );
};

export default DisplayerPopup;

import React, { useEffect, useState } from "react";
import NavbarBack from "../../components/back_office/NavbarBack";
import NavBarBackPage from "../../components/back_office/NavBarBackPage";
import axios from "axios";
import LeadCard from "../../components/back_office/LeadCard";

const CutomerManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [leadsData, setLeadsData] = useState([]);

  useEffect(() => {
    const getleads = async () => {
      await axios
        .get(`https://api.artimarq.fr/back/leads/visualisations`)
        .then((res) => {
          const allLeads = Object.values(res.data);
          const leadsWithStatusLeads = allLeads.filter(
            (lead) => lead.statut === "Customer"
          );
          const sortedData = leadsWithStatusLeads.sort(
            (a, b) => new Date(b.dateLeads) - new Date(a.dateLeads)
          );
          setLeadsData(sortedData);
        });
    };
    getleads();
  }, []);

  let dataToDisplay;
  if (leadsData.length > 0) {
    dataToDisplay = leadsData.map((lead) => (
      <LeadCard key={lead.idLeads} idLeads={lead.idLeads} />
    ));
  } else {
    dataToDisplay = (
      <div className="contentEmpty">
        <h3>Vous n'avez aucun client pour l'instant.</h3>
      </div>
    );
  }

  return (
    <div className="body-website_back">
      <NavbarBack />
      <div className="back-page-content">
        <NavBarBackPage />
        <div>
          <div className="admin-content-page">
            <div className="devis-back-container">{dataToDisplay}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CutomerManagement;

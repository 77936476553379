import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { ParamContext } from "../../components/context/ParamContexte";

const NavBlog = () => {
  const { nameBlog, descBlog, imgBlogCover } = useContext(ParamContext);
  return (
    <div>
      <section
        className="blog-hero"
        style={{ backgroundImage: imgBlogCover }}
        alt="product"
      >
        <div className="heroOverlay"></div>
        <div className="hero-blog-text">
          <h1>{nameBlog}</h1>
          <p>{descBlog}</p>
        </div>
      </section>
      <div className="blog-header">
        <ul>
          <li>
            <NavLink
              to="/artimarq-le-blog/nouveautes-et-tendences"
              className="link-menu-blog"
              activeclassname="active"
            >
              Nouveautés et Tendances
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/artimarq-le-blog/evenements-et-nouveautes"
              className="link-menu-blog"
              activeclassname="active"
            >
              Événements et Actualités
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/artimarq-le-blog/guides"
              className="link-menu-blog"
              activeclassname="active"
            >
              Guides
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/artimarq-le-blog/inspirations"
              className="link-menu-blog"
              activeclassname="active"
            >
              Inspirations
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/artimarq-le-blog/realisations"
              className="link-menu-blog"
              activeclassname="active"
            >
              Réalisations
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/artimarq-le-blog/concours-et-jeux"
              className="link-menu-blog"
              activeclassname="active"
            >
              Concours et Jeux
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavBlog;

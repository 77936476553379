import axios from "axios";
import React, { useEffect, useState } from "react";
import qs from "qs";
import Popup from "../Popup";
import JoinCustomer from "./JoinCustomer";
import UpdateLeads from "../../pages/back_office/UpdateLeads";

const LeadCard = (props) => {
  let { idLeads } = props;

  const [order, setOrder] = useState([]);
  const [togglePopup, setTogglePopup] = useState(false);
  const [toggleJoin, setToggleJoin] = useState(false);
  const [toggleEdite, setToggleEdite] = useState(false);

  useEffect(() => {
    const getOrder = async () => {
      await axios
        .get(`https://api.artimarq.fr/back/leads/visualisation/${idLeads}`)
        .then((res) => {
          const ordersObj = Object.values(res.data);
          setOrder(ordersObj[0]);
        });
    };
    getOrder();
  }, [idLeads]);

  const datePerser = (date) => {
    if (date) {
      const newDate = new Date(date);
      const day = newDate.getDate().toString().padStart(2, "0");
      const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
      const year = newDate.getFullYear();
      return `${day}/${month}/${year}`;
    } else {
      return "";
    }
  };

  const deleteLead = () => {
    const formatData = {
      idLeads,
      idOrder: order?.order,
    };

    const formatIMG = {
      nameMedia: order?.media,
    };

    axios
      .post(
        "https://api.artimarq.fr/back/leads/delete",
        qs.stringify(formatData),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post(
        "https://api.artimarq.fr/back/images/delete",
        qs.stringify(formatIMG),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <ul className="devis-card-back">
      <li>
        <h5>Demandeur</h5>
        <span>
          {order?.civilityLeads} {order?.firstNameLeads} {order?.lastNameLeads}
        </span>
        <span>{order?.statut}</span>
      </li>
      <li>
        <h5>Organisation</h5>
        {order?.OrganisationName ? order?.OrganisationName : "Non renseigné"}
        {<span>{order?.organisationAddress}</span>}
        {
          <span>
            {order?.organisationZip}
            {" - "}
            {order?.organisationCity}
            {", "}
            {order?.organisationCountry}
          </span>
        }
      </li>
      <li>
        <h5>Contacts</h5>
        <span>{order?.emailLeads}</span>
        <span>{order?.phoneLeads}</span>
      </li>
      <li>
        <h5>Depuis-le</h5>
        <span>{datePerser(order?.dateLeads)}</span>
      </li>
      <li className="last-item">
        <button className="card-btn" onClick={() => setToggleEdite(true)}>
          Modifier
        </button>
        <button
          className="card-btn"
          onClick={() => {
            setTogglePopup(true);
          }}
        >
          Supprimer
        </button>
        <button className="card-btn" onClick={() => setToggleJoin(true)}>
          Joindre le prospect
        </button>
      </li>
      <Popup
        togglePopup={togglePopup}
        setTogglePopup={setTogglePopup}
        onSubmite={deleteLead}
        messagePopup="La suppression est irreversible, êtes-vous certain de vouloir supprimer ce ce prospect?"
        comeFrom="leadDeletion"
      />
      <JoinCustomer
        toggleJoin={toggleJoin}
        setToggleJoin={setToggleJoin}
        emailLeads={order?.emailLeads}
        firstNameLeads={order?.firstNameLeads}
        lastNameLeads={order?.lastNameLeads}
      />

      <UpdateLeads
        toggleEdite={toggleEdite}
        setToggleEdite={setToggleEdite}
        idLeads={order?.idLeads}
        organisationType={order?.organisationType}
        OrganisationName={order?.OrganisationName}
        organisationAddress={order?.organisationAddress}
        organisationCity={order?.organisationCity}
        organisationCountry={order?.organisationCountry}
        organisationZip={order?.organisationZip}
        civilityLead={order?.civilityLeads}
        firstNameLeads={order?.firstNameLeads}
        lastNameLeads={order?.lastNameLeads}
        emailLeads={order?.emailLeads}
        phoneLeads={order?.phoneLeads}
        statutLead={order?.statut}
      />
    </ul>
  );
};

export default LeadCard;

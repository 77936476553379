import React, { useEffect, useState } from "react";
import axios from "axios";
import NavbarBack from "../../components/back_office/NavbarBack";
import NavBarBackPage from "../../components/back_office/NavBarBackPage";

const MediaManagement = () => {
  const baseUrl = "https://api.artimarq.fr/public/images/";

  const [toggleMessageLogo, setToggleMessageSecLogo] = useState("");
  const [toggleMessageBanL, setToggleMessageBanL] = useState("");
  const [toggleMessageBanSM, setToggleMessageBanSM] = useState("");
  const [toggleMessageSeri, setToggleMessageSeri] = useState("");
  const [toggleMessageFlo, setToggleMessageFlo] = useState("");
  const [toggleMessageImp, setToggleMessageImp] = useState("");
  const [toggleMessageBro, setToggleMessageBro] = useState("");

  const [imgHomeCoverL, setImgHomeCoverL] = useState("");
  const [imgHomeCoverSM, setImgHomeCoverSM] = useState("");
  const [logo, setLogo] = useState("");
  const [embroidryImg, setEmbroidryImg] = useState("");
  const [dtgImg, setDtgImg] = useState("");
  const [flocImg, setFlocImg] = useState("");
  const [serImg, setSerImg] = useState("");
  const [imgLBlog, setImgLBlog] = useState("");
  const [imgSMBlog, setImgSMBlog] = useState("");
  const [imgOpenTag, setImgOpenTag] = useState("");
  const [imgBusCard, setImgBusCard] = useState("");
  const [imgLogoVar, setImgLogoVar] = useState("");

  const [logoInput, setLogoInput] = useState("");
  const [bannerL, setBannerL] = useState("");
  const [bannerSM, setBannerSM] = useState("");
  const [seriFile, setSeriFile] = useState("");
  const [floFile, setFloFile] = useState("");
  const [impressFile, setImpressFile] = useState("");
  const [embrodFile, setEmbrodFile] = useState("");
  const [imgLBlogFil, setImgLBlogFil] = useState("");
  const [imgSMBlogFill, setImgSMBlogFill] = useState("");
  const [imgOpenTagFile, setImgOpenTagFile] = useState("");
  const [imgBusCardFill, setImgBusCardFill] = useState("");
  const [imgLogoVarFile, setImgLogoVarFile] = useState("");

  useEffect(() => {
    const getOrder = async () => {
      await axios
        .get(`https://api.artimarq.fr/back/settings/visualisations`)
        .then((res) => {
          const SettingssObj = Object.values(res.data);
          const settingsObj = Object.values(SettingssObj);
          settingsObj.forEach((setting) => {
            switch (setting.refSettings) {
              case "slide-l":
                setImgHomeCoverL(setting.settingValue);
                break;
              case "slide-sm":
                setImgHomeCoverSM(setting.settingValue);
                break;
              case "Logo artimarq":
                setLogo(setting.settingValue);
                break;
              case "broderie":
                setEmbroidryImg(setting.settingValue);
                break;
              case "dtg":
                setDtgImg(setting.settingValue);
                break;
              case "flocage":
                setFlocImg(setting.settingValue);
                break;
              case "serigraphie":
                setSerImg(setting.settingValue);
                break;
              case "Hero blog L":
                setImgLBlog(setting.settingValue);
                break;
              case "Hero blog SM":
                setImgSMBlog(setting.settingValue);
                break;
              case "Facebook img Open Tags":
                setImgOpenTag(setting.settingValue);
                break;
              case "Hero-busness-card":
                setImgBusCard(setting.settingValue);
                break;
              case "logo-variation":
                setImgLogoVar(setting.settingValue);
                break;
              default:
                break;
            }
          });
        });
    };
    getOrder();
  }, []);

  const handleChangeLogo = (event) => {
    const newFiles = event.target.files;
    setLogoInput([...newFiles]);
  };

  const handleChangeBannerL = (event) => {
    const newFiles = event.target.files;
    setBannerL([...newFiles]);
  };

  const handleChangeBannerSM = (event) => {
    const newFiles = event.target.files;
    setBannerSM([...newFiles]);
  };

  const handleChangeSerigrapf = (event) => {
    const newFiles = event.target.files;
    setSeriFile([...newFiles]);
  };

  const handleChangeFloc = (event) => {
    const newFiles = event.target.files;
    setFloFile([...newFiles]);
  };

  const handleChangeImpress = (event) => {
    const newFiles = event.target.files;
    setImpressFile([...newFiles]);
  };

  const handleChangeEmbroidery = (event) => {
    const newFiles = event.target.files;
    setEmbrodFile([...newFiles]);
  };

  const handleChangeBanBlogL = (event) => {
    const newFiles = event.target.files;
    setImgLBlogFil([...newFiles]);
  };

  const handleChangeBanBlogSM = (event) => {
    const newFiles = event.target.files;
    setImgSMBlogFill([...newFiles]);
  };

  const handleChangeOpenTag = (event) => {
    const newFiles = event.target.files;
    setImgOpenTagFile([...newFiles]);
  };

  const handleChangeBusCard = (event) => {
    const newFiles = event.target.files;
    setImgBusCardFill([...newFiles]);
  };

  const handleChangeLogoVar = (event) => {
    const newFiles = event.target.files;
    setImgLogoVarFile([...newFiles]);
  };

  const ChangeLogo = async () => {
    const filesData = new FormData();

    filesData.append("logo", logo);
    for (let i = 0; i < logoInput.length; i++) {
      filesData.append("files", logoInput[i]);
    }

    try {
      const response = await axios.post(
        "https://api.artimarq.fr/back/settings/imgLogo",
        filesData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response);
      setToggleMessageSecLogo(response.data.message);
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  const ChangeBannerL = async () => {
    const filesData = new FormData();

    filesData.append("imgHomeCoverL", imgHomeCoverL);
    for (let i = 0; i < bannerL.length; i++) {
      filesData.append("files", bannerL[i]);
    }

    try {
      const response = await axios.post(
        "https://api.artimarq.fr/back/settings/imgBanL",
        filesData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response);
      setToggleMessageBanL(response.data.message);
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  const ChangeBannerSM = async () => {
    const filesData = new FormData();

    filesData.append("imgHomeCoverSM", imgHomeCoverSM);
    for (let i = 0; i < bannerSM.length; i++) {
      filesData.append("files", bannerSM[i]);
    }

    try {
      const response = await axios.post(
        "https://api.artimarq.fr/back/settings/imgBanSM",
        filesData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      //   console.log(response);
      setToggleMessageBanSM(response.data.message);
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  const ChangeSeri = async () => {
    const filesData = new FormData();

    filesData.append("serImg", serImg);
    for (let i = 0; i < seriFile.length; i++) {
      filesData.append("files", seriFile[i]);
    }

    try {
      const response = await axios.post(
        "https://api.artimarq.fr/back/settings/imgSer",
        filesData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response);
      setToggleMessageSeri(response.data.message);
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  const ChangeFlo = async () => {
    const filesData = new FormData();

    filesData.append("flocImg", flocImg);
    for (let i = 0; i < floFile.length; i++) {
      filesData.append("files", floFile[i]);
    }

    try {
      const response = await axios.post(
        "https://api.artimarq.fr/back/settings/imgFlo",
        filesData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response);
      setToggleMessageFlo(response.data.message);
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  const ChangeImpress = async () => {
    const filesData = new FormData();

    filesData.append("dtgImg", dtgImg);
    for (let i = 0; i < impressFile.length; i++) {
      filesData.append("files", impressFile[i]);
    }

    try {
      const response = await axios.post(
        "https://api.artimarq.fr/back/settings/imgImp",
        filesData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response);
      setToggleMessageImp(response.data.message);
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  const ChangeEmbroid = async () => {
    const filesData = new FormData();

    filesData.append("embroidryImg", embroidryImg);
    for (let i = 0; i < embrodFile.length; i++) {
      filesData.append("files", embrodFile[i]);
    }

    try {
      const response = await axios.post(
        "https://api.artimarq.fr/back/settings/imgBrod",
        filesData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response);
      setToggleMessageBro(response.data.message);
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  const ChangeBanBlogL = async () => {
    const filesData = new FormData();

    filesData.append("imgLBlog", imgLBlog);
    for (let i = 0; i < imgLBlogFil.length; i++) {
      filesData.append("files", imgLBlogFil[i]);
    }

    try {
      // const response =
      await axios.post(
        "https://api.artimarq.fr/back/settings/imgBanBlogL",
        filesData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response);
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  const ChangeBanBlogSM = async () => {
    const filesData = new FormData();

    filesData.append("imgSMBlog", imgSMBlog);
    for (let i = 0; i < imgSMBlogFill.length; i++) {
      filesData.append("files", imgSMBlogFill[i]);
    }

    try {
      // const response =
      await axios.post(
        "https://api.artimarq.fr/back/settings/imgBanBlogSM",
        filesData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response);
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  const ChangeOpenTag = async () => {
    const filesData = new FormData();

    filesData.append("imgOpenTag", imgOpenTag);
    for (let i = 0; i < imgOpenTagFile.length; i++) {
      filesData.append("files", imgOpenTagFile[i]);
    }

    try {
      // const response =
      await axios.post(
        "https://api.artimarq.fr/back/settings/imgOpenTags",
        filesData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response);
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  const ChangeBusCard = async () => {
    const filesData = new FormData();

    filesData.append("imgBusCard", imgBusCard);
    for (let i = 0; i < imgBusCardFill.length; i++) {
      filesData.append("files", imgBusCardFill[i]);
    }

    try {
      // const response =
      await axios.post(
        "https://api.artimarq.fr/back/settings/imgBusCard",
        filesData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response);
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  const ChangeLogoVar = async () => {
    const filesData = new FormData();

    filesData.append("imgLogoVar", imgLogoVar);
    for (let i = 0; i < imgLogoVarFile.length; i++) {
      filesData.append("files", imgLogoVarFile[i]);
    }

    try {
      // const response =
      await axios.post(
        "https://api.artimarq.fr/back/settings/imgLogoVar",
        filesData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response);
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="body-website_back edite-Settings-page">
      <NavbarBack />
      <div className="back-page-content">
        <NavBarBackPage />
        <div className="input-devis-up-container admin-content-page">
          <div className="input-devis-bloc media-man-bloc">
            <div className="input-devis-container">
              <h2>logo</h2>
              <small>En cas de modification veuillez renommer le nouveau fichier Logo_ARTIMARQ (format PNG)</small>
              <div className="media-visu">
                {logoInput && logoInput[0] && logoInput[0].name ? (
                  <img src={URL.createObjectURL(logoInput[0])} alt="media" />
                ) : (
                  <img src={baseUrl + logo} alt="media" />
                )}
              </div>
              <label htmlFor="uploadLogo">
                <form className="btn-upload-img">
                  <div>
                    <p>Modifier</p>
                  </div>
                  <div className="uploadFooter">
                    <label htmlFor="uploadLogo" className="uploadButton">
                      <input
                        type="file"
                        accept="image/*"
                        id="uploadLogo"
                        onChange={handleChangeLogo}
                        multiple={false}
                      />
                    </label>
                  </div>
                </form>
              </label>
              {logoInput && logoInput[0] && logoInput[0].name && (
                <div>
                  <button className="btn-upload-img" onClick={ChangeLogo}>
                    Valider
                  </button>
                  <div className="alertSuccess">{toggleMessageLogo}</div>
                </div>
              )}
            </div>
            <div className="input-devis-container">
              <h2>Bannière desktop</h2>
              <div className="media-visu">
                {bannerL && bannerL[0] && bannerL[0].name ? (
                  <img src={URL.createObjectURL(bannerL[0])} alt="media" />
                ) : (
                  <img src={baseUrl + imgHomeCoverL} alt="media" />
                )}
              </div>
              <label htmlFor="uploadBannerL">
                <form className="btn-upload-img">
                  <div>
                    <p>Modifier</p>
                  </div>
                  <div className="uploadFooter">
                    <label htmlFor="uploadBannerL" className="uploadButton">
                      <input
                        type="file"
                        accept="image/*"
                        id="uploadBannerL"
                        onChange={handleChangeBannerL}
                        multiple={false}
                      />
                    </label>
                  </div>
                </form>
              </label>
              {bannerL && bannerL[0] && bannerL[0].name && (
                <div>
                  <button className="btn-upload-img" onClick={ChangeBannerL}>
                    Valider
                  </button>
                  <div className="alertSuccess">{toggleMessageBanL}</div>
                </div>
              )}
            </div>
            <div className="input-devis-container">
              <h2>Bannière Mobile</h2>
              <div className="media-visu">
                {bannerSM && bannerSM[0] && bannerSM[0].name ? (
                  <img src={URL.createObjectURL(bannerSM[0])} alt="media" />
                ) : (
                  <img src={baseUrl + imgHomeCoverSM} alt="media" />
                )}
              </div>
              <label htmlFor="uploadBannerSM">
                <form className="btn-upload-img">
                  <div>
                    <p>Modifier</p>
                  </div>
                  <div className="uploadFooter">
                    <label htmlFor="uploadBannerSM" className="uploadButton">
                      <input
                        type="file"
                        accept="image/*"
                        id="uploadBannerSM"
                        onChange={handleChangeBannerSM}
                        multiple={false}
                      />
                    </label>
                  </div>
                </form>
              </label>
              {bannerSM && bannerSM[0] && bannerSM[0].name && (
                <div>
                  <button className="btn-upload-img" onClick={ChangeBannerSM}>
                    Valider
                  </button>
                  <div className="alertSuccess">{toggleMessageBanSM}</div>
                </div>
              )}
            </div>
            <div className="input-devis-container">
              <h2>Bannière serigraphie</h2>
              <div className="media-visu">
                {seriFile && seriFile[0] && seriFile[0].name ? (
                  <img src={URL.createObjectURL(seriFile[0])} alt="media" />
                ) : (
                  <img src={baseUrl + serImg} alt="media" />
                )}
              </div>
              <label htmlFor="uploadSerigraph">
                <form className="btn-upload-img">
                  <div>
                    <p>Modifier</p>
                  </div>
                  <div className="uploadFooter">
                    <label htmlFor="uploadSerigraph" className="uploadButton">
                      <input
                        type="file"
                        accept="image/*"
                        id="uploadSerigraph"
                        onChange={handleChangeSerigrapf}
                        multiple={false}
                      />
                    </label>
                  </div>
                </form>
              </label>
              {seriFile && seriFile[0] && seriFile[0].name && (
                <div>
                  <button className="btn-upload-img" onClick={ChangeSeri}>
                    Valider
                  </button>
                  <div className="alertSuccess">{toggleMessageSeri}</div>
                </div>
              )}
            </div>
            <div className="input-devis-container">
              <h2>Bannière flocage</h2>
              <div className="media-visu">
                {floFile && floFile[0] && floFile[0].name ? (
                  <img src={URL.createObjectURL(floFile[0])} alt="media" />
                ) : (
                  <img src={baseUrl + flocImg} alt="media" />
                )}
              </div>
              <label htmlFor="uploadFloc">
                <form className="btn-upload-img">
                  <div>
                    <p>Modifier</p>
                  </div>
                  <div className="uploadFooter">
                    <label htmlFor="uploadFloc" className="uploadButton">
                      <input
                        type="file"
                        accept="image/*"
                        id="uploadFloc"
                        onChange={handleChangeFloc}
                        multiple={false}
                      />
                    </label>
                  </div>
                </form>
              </label>
              {floFile && floFile[0] && floFile[0].name && (
                <div>
                  <button className="btn-upload-img" onClick={ChangeFlo}>
                    Valider
                  </button>
                  <div className="alertSuccess">{toggleMessageFlo}</div>
                </div>
              )}
            </div>
            <div className="input-devis-container">
              <h2>Bannière impression numerique</h2>
              <div className="media-visu">
                {impressFile && impressFile[0] && impressFile[0].name ? (
                  <img src={URL.createObjectURL(impressFile[0])} alt="media" />
                ) : (
                  <img src={baseUrl + dtgImg} alt="media" />
                )}
              </div>
              <label htmlFor="uploadImp">
                <form className="btn-upload-img">
                  <div>
                    <p>Modifier</p>
                  </div>
                  <div className="uploadFooter">
                    <label htmlFor="uploadImp" className="uploadButton">
                      <input
                        type="file"
                        accept="image/*"
                        id="uploadImp"
                        onChange={handleChangeImpress}
                        multiple={false}
                      />
                    </label>
                  </div>
                </form>
              </label>
              {impressFile && impressFile[0] && impressFile[0].name && (
                <div>
                  <button className="btn-upload-img" onClick={ChangeImpress}>
                    Valider
                  </button>
                  <div className="alertSuccess">{toggleMessageImp}</div>
                </div>
              )}
            </div>
            <div className="input-devis-container">
              <h2>Bannière broderie</h2>
              <div className="media-visu">
                {embrodFile && embrodFile[0] && embrodFile[0].name ? (
                  <img src={URL.createObjectURL(embrodFile[0])} alt="media" />
                ) : (
                  <img src={baseUrl + embroidryImg} alt="media" />
                )}
              </div>
              <label htmlFor="uploadImbrodery">
                <form className="btn-upload-img">
                  <div>
                    <p>Modifier</p>
                  </div>
                  <div className="uploadFooter">
                    <label htmlFor="uploadImbrodery" className="uploadButton">
                      <input
                        type="file"
                        accept="image/*"
                        id="uploadImbrodery"
                        onChange={handleChangeEmbroidery}
                        multiple={false}
                      />
                    </label>
                  </div>
                </form>
              </label>
              {embrodFile && embrodFile[0] && embrodFile[0].name && (
                <div>
                  <button className="btn-upload-img" onClick={ChangeEmbroid}>
                    Valider
                  </button>
                  <div className="alertSuccess">{toggleMessageBro}</div>
                </div>
              )}
            </div>
            <div className="input-devis-container">
              <h2>Bannière desktop blog</h2>
              <div className="media-visu">
                {imgLBlogFil && imgLBlogFil[0] && imgLBlogFil[0].name ? (
                  <img src={URL.createObjectURL(imgLBlogFil[0])} alt="media" />
                ) : (
                  <img src={baseUrl + imgLBlog} alt="media" />
                )}
              </div>
              <label htmlFor="uploadBanLBlog">
                <form className="btn-upload-img">
                  <div>
                    <p>Modifier</p>
                  </div>
                  <div className="uploadFooter">
                    <label htmlFor="uploadBanLBlog" className="uploadButton">
                      <input
                        type="file"
                        accept="image/*"
                        id="uploadBanLBlog"
                        onChange={handleChangeBanBlogL}
                        multiple={false}
                      />
                    </label>
                  </div>
                </form>
              </label>
              {imgLBlogFil && imgLBlogFil[0] && imgLBlogFil[0].name && (
                <div>
                  <button className="btn-upload-img" onClick={ChangeBanBlogL}>
                    Valider
                  </button>
                </div>
              )}
            </div>
            <div className="input-devis-container">
              <h2>Bannière mobile blog</h2>
              <div className="media-visu">
                {imgSMBlogFill && imgSMBlogFill[0] && imgSMBlogFill[0].name ? (
                  <img
                    src={URL.createObjectURL(imgSMBlogFill[0])}
                    alt="media"
                  />
                ) : (
                  <img src={baseUrl + imgSMBlog} alt="media" />
                )}
              </div>
              <label htmlFor="uploadBanSMBlog">
                <form className="btn-upload-img">
                  <div className="btn-upload-img">
                    <p>Modifier</p>
                  </div>
                  <div className="uploadFooter">
                    <label htmlFor="uploadBanSMBlog" className="uploadButton">
                      <input
                        type="file"
                        accept="image/*"
                        id="uploadBanSMBlog"
                        onChange={handleChangeBanBlogSM}
                        multiple={false}
                      />
                    </label>
                  </div>
                </form>
              </label>
              {imgSMBlogFill && imgSMBlogFill[0] && imgSMBlogFill[0].name && (
                <div>
                  <button className="btn-upload-img" onClick={ChangeBanBlogSM}>
                    Valider
                  </button>
                </div>
              )}
            </div>
            <div className="input-devis-container">
              <h2>Open Tags (Facebook)</h2>
              <div className="media-visu">
                {imgOpenTagFile &&
                imgOpenTagFile[0] &&
                imgOpenTagFile[0].name ? (
                  <img
                    src={URL.createObjectURL(imgOpenTagFile[0])}
                    alt="media"
                  />
                ) : (
                  <img src={baseUrl + imgOpenTag} alt="media" />
                )}
              </div>
              <label htmlFor="uploadOpenTags">
                <form className="btn-upload-img">
                  <div className="btn-upload-img">
                    <p>Modifier</p>
                  </div>
                  <div className="uploadFooter">
                    <label htmlFor="uploadOpenTags" className="uploadButton">
                      <input
                        type="file"
                        accept="image/*"
                        id="uploadOpenTags"
                        onChange={handleChangeOpenTag}
                        multiple={false}
                      />
                    </label>
                  </div>
                </form>
              </label>
              {imgOpenTagFile &&
                imgOpenTagFile[0] &&
                imgOpenTagFile[0].name && (
                  <div>
                    <button className="btn-upload-img" onClick={ChangeOpenTag}>
                      Valider
                    </button>
                  </div>
                )}
            </div>

            <div className="input-devis-container">
              <h2>Fond carte de visite</h2>
              <div className="media-visu">
                {imgBusCardFill &&
                imgBusCardFill[0] &&
                imgBusCardFill[0].name ? (
                  <img
                    src={URL.createObjectURL(imgBusCardFill[0])}
                    alt="media"
                  />
                ) : (
                  <img src={baseUrl + imgBusCard} alt="media" />
                )}
              </div>
              <label htmlFor="uploadBusCard">
                <form className="btn-upload-img">
                  <div className="btn-upload-img">
                    <p>Modifier</p>
                  </div>
                  <div className="uploadFooter">
                    <label htmlFor="uploadBusCard" className="uploadButton">
                      <input
                        type="file"
                        accept="image/*"
                        id="uploadBusCard"
                        onChange={handleChangeBusCard}
                        multiple={false}
                      />
                    </label>
                  </div>
                </form>
              </label>
              {imgBusCardFill &&
                imgBusCardFill[0] &&
                imgBusCardFill[0].name && (
                  <div>
                    <button className="btn-upload-img" onClick={ChangeBusCard}>
                      Valider
                    </button>
                  </div>
                )}
            </div>
            <div className="input-devis-container">
              <h2>variation de logo</h2>
              <small>En cas de modification veuillez renommer le nouveau fichier Logo_variation (format PNG)</small>
              <div className="media-visu">
                {imgLogoVarFile &&
                imgLogoVarFile[0] &&
                imgLogoVarFile[0].name ? (
                  <img
                    src={URL.createObjectURL(imgLogoVarFile[0])}
                    alt="media"
                  />
                ) : (
                  <img src={baseUrl + imgLogoVar} alt="media" />
                )}
              </div>
              <label htmlFor="uploadLogoVar">
                <form className="btn-upload-img">
                  <div className="btn-upload-img">
                    <p>Modifier</p>
                  </div>
                  <div className="uploadFooter">
                    <label htmlFor="uploadLogoVar" className="uploadButton">
                      <input
                        type="file"
                        accept="image/*"
                        id="uploadLogoVar"
                        onChange={handleChangeLogoVar}
                        multiple={false}
                      />
                    </label>
                  </div>
                </form>
              </label>
              {imgLogoVarFile &&
                imgLogoVarFile[0] &&
                imgLogoVarFile[0].name && (
                  <div>
                    <button className="btn-upload-img" onClick={ChangeLogoVar}>
                      Valider
                    </button>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaManagement;

import React, { useEffect, useRef } from "react";

const DetailOrder = (props) => {
  let {
    toggleDetails,
    setToggleDetails,
    dateOrder,
    statut,
    printingType,
    civilityLeads,
    firstNameLeads,
    lastNameLeads,
    emailLeads,
    phoneLeads,
    textileName,
    colorTextil,
    gsmTextil,
    messageOrder,
    option1,
    option2,
    option3,
    option4,
    option5,
    orderDelay,
    OrganisationLeads,
    OrganisationType,
    organisationAddress,
    organisationCity,
    organisationCountry,
    organisationZip,
    qty,
    zones,
    media,
  } = props;

  const myRef = useRef();
  const baseUrl = "https://api.artimarq.fr/public/images/";

  useEffect(() => {
    let handler = (e) => {
      if (!myRef.current.contains(e.target)) {
        setToggleDetails(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const datePerser = (date) => {
    if (date) {
      const newDate = new Date(date);
      newDate.setHours(newDate.getHours() + 2);
      const day = newDate.getDate().toString().padStart(2, "0");
      const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
      const year = newDate.getFullYear();
      const hours = newDate.getHours().toString().padStart(2, "0");
      const minutes = newDate.getMinutes().toString().padStart(2, "0");
  
      return `${day}/${month}/${year} à ${hours}h:${minutes}m`;
    } else {
      return "";
    }
  };

  return (
    <div
      className={`bg-details-order ${toggleDetails ? "active" : "inactive"}`}
    >
      <div ref={myRef} className="details-order-container">
        <div className="details-content">
          <div className="customer-container">
            <h2 className="section-title">Détails du demandeur</h2>
            <div className="data-pair">
              <span>Date :</span>
              <p>{datePerser(dateOrder)}</p>
            </div>
            <div className="data-pair">
              <span>Demandeur :</span>
              <p>
                {civilityLeads} {firstNameLeads} {lastNameLeads}
              </p>
            </div>
            <div className="data-pair">
              <span>Status du Demandeur :</span>
              <p>{statut}</p>
            </div>
            <div className="data-pair">
              <span>Email :</span>
              <p>{emailLeads}</p>
            </div>
            <div className="data-pair">
              <span>Téléphone :</span>
              <p>{phoneLeads}</p>
            </div>
            <div className="data-pair">
              <span>Organisation :</span>
              <p>{OrganisationLeads}</p>
            </div>
            <div className="data-pair">
              <span>Type d'organisation :</span>
              <p>{OrganisationType}</p>
            </div>
            <div className="data-pair">
              <span>Adresse :</span>
              <p>{organisationAddress}</p>
            </div>
            <div className="data-pair">
              <span>Ville :</span>
              <p>{organisationCity}</p>
            </div>
            <div className="data-pair">
              <span>Pays :</span>
              <p>{organisationCountry}</p>
            </div>
            <div className="data-pair">
              <span>Code postal :</span>
              <p>{organisationZip}</p>
            </div>
          </div>
          <div className="textile-container">
            <h2 className="section-title">Détails de la demande</h2>
            <div className="data-pair">
              <span>Type de textile :</span>
              <p>{textileName}</p>
            </div>
            <div className="data-pair">
              <span>Couleur du textile :</span>
              <p>{colorTextil}</p>
            </div>
            <div className="data-pair">
              <span>Technique :</span>
              <p>{printingType}</p>
            </div>
            <div className="data-pair">
              <span>Grammage du textile :</span>
              <p>{gsmTextil}</p>
            </div>
            <div className="data-pair">
              <span>Option 1 :</span>
              <p>{option1}</p>
            </div>
            <div className="data-pair">
              <span>Option 2 :</span>
              <p>{option2}</p>
            </div>
            <div className="data-pair">
              <span>Option 3 :</span>
              <p>{option3}</p>
            </div>
            <div className="data-pair">
              <span>Option 4 :</span>
              <p>{option4}</p>
            </div>
            <div className="data-pair">
              <span>Option 5 :</span>
              <p>{option5}</p>
            </div>
            <div className="data-pair">
              <span>Message :</span>
              <p>{messageOrder}</p>
            </div>
            <div className="data-pair">
              <span>Delai d'exécution :</span>
              <p>{orderDelay}</p>
            </div>
            <div className="data-pair">
              <span>Quantities :</span>
              <ul>
                {qty?.map((q, index) => (
                  <li key={index}>
                    {q.sizeName} : {q.quantity}
                  </li>
                ))}
              </ul>
            </div>
            <div className="data-pair">
              <span>Zones:</span>
              <ul>
                {zones?.map((z, index) => (
                  <li key={index}>{z.nameZone}</li>
                ))}
              </ul>
            </div>
            <div className="data-pair">
              <span>Media:</span>
              {media?.length > 0 && (
                <ul>
                  {media?.map((m, index) => (
                    <li key={index}>
                      <a href={baseUrl + m.nameMedia} download>
                        <img src={baseUrl + m.nameMedia} alt="produit" />
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="btn-container-detail">
            <button
              className="black-button"
              onClick={() => setToggleDetails(false)}
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailOrder;

import axios from "axios";
import React, { useEffect, useState } from "react";
import qs from "qs";
import Popup from "../Popup";

const ProductCardBack = (props) => {
  let { idProduct } = props;

  const [product, setProdct] = useState([]);
  const [togglePopup, setTogglePopup] = useState(false);
  const [isOn, setIsOn] = useState(false);
  const [messagePopup, setMessagePopup] = useState("");
  const [comeFrom, setComeFrom] = useState("");

  const baseUrl = "https://api.artimarq.fr/public/images/";

  useEffect(() => {
    if (product?.isOnline === "1") {
      setIsOn(true);
    } else {
      setIsOn(false);
    }
  }, [product?.isOnline]);

  useEffect(() => {
    const getOrder = async () => {
      await axios
        .get(`https://api.artimarq.fr/back/products/visualisation/${idProduct}`)
        .then((res) => {
          const productsObj = Object.values(res.data);
          setProdct(productsObj[0]);
        });
    };
    getOrder();
  }, [idProduct]);

  const datePerser = (date) => {
    if (date) {
      const newDate = new Date(date);
      const day = newDate.getDate().toString().padStart(2, "0");
      const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
      const year = newDate.getFullYear();
      return `${day}/${month}/${year}`;
    } else {
      return "";
    }
  };

  const HandlePopupDeletion = () => {
    setMessagePopup(
      "La suppression est irreversible, êtes-vous certain de vouloir supprimer ce Produit ?"
    );
    setComeFrom("productDeletion");
    setTogglePopup(true);
  };

  const HandlePopupOline = () => {
    setMessagePopup("Confirmez-vous cette action ?");
    setComeFrom("toggleOnline");
    setTogglePopup(true);
  };

  const toggleSwitch = () => {
    setIsOn(!isOn);

    const formatData = {
      idProduct,
    };

    axios
      .post(
        "https://api.artimarq.fr/back/products/publish",
        qs.stringify(formatData),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteProduct = () => {
    const formatData = {
      idProduct,
      idMedia: product?.idMedia,
    };

    axios
      .post(
        "https://api.artimarq.fr/back/products/delete",
        qs.stringify(formatData),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <ul className="devis-card-back">
      <li>
        <h5>Nom du produit</h5>
        <span>{product?.nameProduct}</span>
      </li>
      <li>
        <h5>Date de creation</h5>
        {datePerser(product?.dateProduct)}
      </li>
      <li>
        <h5>Type de produit</h5>
        <span>{product?.productType}</span>
      </li>
      <li>
        <h5>Categorie</h5>
        <span>{product?.nameCategory}</span>
      </li>
      <li>
        <h5>En ligne</h5>
        <div
          className={`switch ${isOn ? "on" : "off"}`}
          onClick={HandlePopupOline}
        >
          <div className="toggle" />
        </div>
      </li>
      <li className="last-item">
        <a
          className="card-btn"
          href={`/back/modification/produit/${idProduct}`} rel="nofollow"
        >
          Modifier
        </a>
        <button className="card-btn" onClick={() => HandlePopupDeletion()}>
          Supprimer
        </button>
      </li>
      <li>
          <img src={baseUrl + product?.nameMedia} alt="produit" />
      </li>
      <Popup
        togglePopup={togglePopup}
        setTogglePopup={setTogglePopup}
        onSubmite={comeFrom === "toggleOnline" ? toggleSwitch : deleteProduct}
        messagePopup={messagePopup}
        comeFrom={comeFrom}
      />
    </ul>
  );
};

export default ProductCardBack;

import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoadingPage from "./components/LoadingPage";
import HomePage from "./pages/HomePage";
import FrontNavBar from "./components/FrontNavBar";
import Footer from "./components/Footer";
import Accessories from "./pages/Accessories";
import Tshirts from "./pages/Tshirts";
import AskDevis from "./pages/AskDevis";
import Hoodies from "./pages/Hoodies";
import Technics from "./pages/Technics";
import Polos from "./pages/Polos";
import Shirts from "./pages/Shirts";
import SweatsShirts from "./pages/SweatsShirts";
import Pants from "./pages/Pants";
import OthersProducts from "./pages/OthersProducts";
import LegalNotice from "./pages/LegalNotice";
import Cgv from "./pages/Cgv";
import Faq from "./pages/Faq";
import Contacts from "./pages/Contacts";
import CustomePage from "./pages/CustomePage";
import Short from "./pages/Short";
import PrivencyPolity from "./pages/PrivencyPolity";
import LoginPage from "./pages/back_office/LoginPage";
import PrivateRoute from "./components/context/PrivateRoute";
import { ParamContextProvider } from "./components/context/ParamContexte";
import DevisManagement from "./pages/back_office/DevisManagement";
import LeadsManagement from "./pages/back_office/LeadsManagement";
import TeamManagement from "./pages/back_office/TeamManagement";
import ProductsManagement from "./pages/back_office/ProductsManagement";
import Message from "./pages/back_office/Message";
import Billing from "./pages/back_office/Billing";
import Settings from "./pages/back_office/Settings";
import CreatDevis from "./pages/back_office/CreatDevis";
import VisualDevis from "./pages/back_office/VisualDevis";
import CreateBill from "./pages/back_office/CreateBill";
import CutomerManagement from "./pages/back_office/CutomerManagement";
import CreateCustomerCard from "./pages/back_office/CreateCustomerCard";
import UserParam from "./pages/back_office/UserParam";
import CreatUser from "./pages/back_office/CreatUser";
import CreateProduct from "./pages/back_office/CreateProduct";
import EditeProduct from "./components/back_office/EditeProduct";
import MediaManagement from "./pages/back_office/MediaManagement";
import NewsAndTrend from "./pages/blog/NewsAndTrend.js";
import EventAndNews from "./pages/blog/EventAndNews";
import Guide from "./pages/blog/Guide";
import Inspirations from "./pages/blog/Inspirations";
import Achevements from "./pages/blog/Achevements";
import Competitions from "./pages/blog/Competitions";
import ArticleBlogPage from "./pages/blog/ArticleBlogPage";
import BlogsViews from "./pages/blog/BlogsViews";
import CreateArticle from "./pages/blog/CreateArticle";
import EditionArticle from "./pages/blog/EditionArticle";
import EditeNewsletter from "./pages/back_office/EditeNewsletter";
import CreateNewsletter from "./pages/back_office/CreateNewsletter";
import ViewNewsletter from "./pages/back_office/ViewNewsletter";
import NewsletterPage from "./pages/back_office/NewsletterPage";
import axios from "axios";
import UnsubcribeNews from "./pages/UnsubcribeNews";
import DisplayerPopup from "./components/DisplayerPopup";
import WebsiteLink from "./pages/WebsiteLink";
import Patch from "./pages/Patch";

function App() {

  const [isLoading, setIsLoading] = useState(true);

  const [logo, setLogo] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [settingsResponse] = await Promise.all([
          axios.get("https://api.artimarq.fr/front/settings"),
        ]);

        const settingsObj = Object.values(settingsResponse.data);
        settingsObj.forEach((setting) => {
          switch (setting.refSettings) {
            case "Logo artimarq":
              setLogo(setting.mediaSetting);
              break;
            default:
              break;
          }
        });
      } catch (error) {
        console.error("Une erreur s'est produite : ", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <BrowserRouter>
      <ParamContextProvider>
        <FrontNavBar />
        {isLoading ? (
          <LoadingPage />
        ) : (
          <div className="body-website">
            <Routes>
              <Route
                path="/"
                element={<HomePage setIsLoading={setIsLoading} />}
              />
              <Route path="/marquages" element={<Technics />} />
              <Route
                path="/artimarq-le-blog/nouveautes-et-tendences"
                element={<NewsAndTrend setIsLoading={setIsLoading} />}
              />
              <Route
                path="/artimarq-le-blog/evenements-et-nouveautes"
                element={<EventAndNews setIsLoading={setIsLoading} />}
              />
              <Route
                path="/artimarq-le-blog/guides"
                element={<Guide setIsLoading={setIsLoading} />}
              />

              <Route
                path="/artimarq-le-blog/inspirations"
                element={<Inspirations setIsLoading={setIsLoading} />}
              />
              <Route
                path="/artimarq-le-blog/realisations"
                element={<Achevements setIsLoading={setIsLoading} />}
              />
              <Route
                path="/artimarq-le-blog/concours-et-jeux"
                element={<Competitions setIsLoading={setIsLoading} />}
              />
              <Route
                path="/artimarq-le-blog/article/:id"
                element={<ArticleBlogPage setIsLoading={setIsLoading} />}
              />
              <Route
                path="/demande-de-devis"
                element={<AskDevis setIsLoading={setIsLoading} />}
              />
              <Route path="/t-shirts" element={<Tshirts />} />
              <Route path="/polos" element={<Polos />} />
              <Route path="/chemises" element={<Shirts />} />
              <Route path="/sweats-shirts" element={<SweatsShirts />} />
              <Route path="/hoodies" element={<Hoodies />} />
              <Route path="/pantalons" element={<Pants />} />
              <Route path="/shorts" element={<Short />} />
              <Route path="/accessoires" element={<Accessories />} />
              <Route path="/autres-produits" element={<OthersProducts />} />
              <Route path="/ecussons" element={<Patch />} />
              <Route path="/mentions-legales" element={<LegalNotice />} />
              <Route path="/carte-de-visite" element={<WebsiteLink />} />
              <Route
                path="/protection-des-données"
                element={<PrivencyPolity />}
              />
              <Route path="/CGV" element={<Cgv />} />
              <Route path="/FAQ" element={<Faq />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route
                path="/desinscription-newsletter"
                element={<UnsubcribeNews setIsLoading={setIsLoading} />}
              />
              <Route
                path="/produit/:id"
                element={<CustomePage setIsLoading={setIsLoading} />}
              />
              <Route path="/back/login" element={<LoginPage />} />
              <Route element={<PrivateRoute />}>
                <Route path="/back/devis-en-cours" element={<VisualDevis />} />
                <Route
                  path="/back/demandes-de-devis"
                  element={<DevisManagement />}
                />
                <Route path="/back/creer-un-devis" element={<CreatDevis />} />
                <Route
                  path="/back/gestion-de-leads"
                  element={<LeadsManagement />}
                />
                <Route
                  path="/back/gestion-clients"
                  element={<CutomerManagement />}
                />
                <Route
                  path="/back/creation-de-fiche-client"
                  element={<CreateCustomerCard />}
                />
                <Route
                  path="/back/gestion-equipe"
                  element={<TeamManagement />}
                />
                <Route
                  path="/back/parametre-utilisateur"
                  element={<UserParam />}
                />
                <Route
                  path="/back/creer-un-utilisateur"
                  element={<CreatUser />}
                />
                <Route
                  path="/back/gestion-produits"
                  element={<ProductsManagement />}
                />
                <Route
                  path="/back/modification/produit/:id"
                  element={<EditeProduct />}
                />
                <Route
                  path="/back/creer-un-produit"
                  element={<CreateProduct />}
                />
                <Route path="/back/messagerie" element={<Message />} />
                <Route path="/back/factures" element={<Billing />} />
                <Route path="/back/blog/articles" element={<BlogsViews />} />
                <Route
                  path="/back/blog/redaction"
                  element={<CreateArticle />}
                />
                <Route
                  path="/back/blog/edition-article/:id"
                  element={<EditionArticle />}
                />
                <Route
                  path="/back/newsletter/articles"
                  element={<ViewNewsletter />}
                />
                <Route
                  path="/back/newsletter/redaction"
                  element={<CreateNewsletter />}
                />
                <Route
                  path="/back/newsletter/edition/:id"
                  element={<EditeNewsletter />}
                />
                <Route
                  path="/back/newsletter/article/:id"
                  element={<NewsletterPage setIsLoading={setIsLoading} />}
                />
                <Route
                  path="/back/creer-une-facture"
                  element={<CreateBill />}
                />
                <Route path="/back/parametres" element={<Settings />} />
                <Route path="/back/medias" element={<MediaManagement />} />
              </Route>
              <Route
                path="*"
                element={<HomePage setIsLoading={setIsLoading} />}
              />
            </Routes>
          </div>
        )}
        <Footer />
        <DisplayerPopup />
      </ParamContextProvider>
    </BrowserRouter>
  );
}

export default App;

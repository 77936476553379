import React, { useContext, useState } from "react";
import axios from "axios";
import NavbarBack from "../../components/back_office/NavbarBack";
import NavBarBackPage from "../../components/back_office/NavBarBackPage";
import { ParamContext } from "../../components/context/ParamContexte";

const CreateNewsletter = () => {
  const { adminId } = useContext(ParamContext);

  const [togglemessage, setToggleMessage] = useState("");
  const [warning, setWarning] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formDataState, setFormDataState] = useState({
    titleNewsletter: " ",
    descNewsletter: " ",
    author: " ",
    articleContent: " ",
  });

  const resetFormDataState = () => {
    setFormDataState({
      titleNewsletter: " ",
      descNewsletter: " ",
      author: " ",
      articleContent: " ",
    });
  };

  const handleChange = (value, name) => {
    setFormDataState((prevState) => ({ ...prevState, [name]: value }));
  };

  const CreateProduct = async () => {
    setIsLoading(true);

    const { titleNewsletter, descNewsletter, author, articleContent } =
      formDataState;

    if (!titleNewsletter && !descNewsletter && !author && !articleContent) {
      setIsLoading(false);
      setToggleMessage("");
      setWarning("Veuillez remplire tous les champs obligatoirs");
      return;
    }

    try {
      const response = await axios.post(
        "https://api.artimarq.fr/back/newsletter/create",
        {
          adminId: adminId,
          titleNewsletter: formDataState.titleNewsletter,
          descNewsletter: formDataState.descNewsletter,
          author: formDataState.author,
          articleContent: formDataState.articleContent,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      //   console.log(response);
      setWarning("");
      resetFormDataState();
      setToggleMessage(response.data.message);
    } catch (error) {
      console.log(error.message);
      setToggleMessage(
        "Une erreur s'est produite lors de la création du produit."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="body-website_back">
      <NavbarBack />
      <div className="back-page-content">
        <NavBarBackPage />
        <div>
          <div className="input-devis-up-container admin-content-page">
            <div className="input-devis-bloc">
              <div className="section-input-devis">
                <div className="input-devis-container">
                  <label htmlFor="titleNewsletter">Titre de l'article</label>
                  <input
                    name="titleNewsletter"
                    id="titleNewsletter"
                    defaultValue={formDataState.titleNewsletter}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
              <div className="section-input-devis">
                <div className="input-devis-container">
                  <label htmlFor="descNewsletter">Description</label>
                  <input
                    name="descNewsletter"
                    id="descNewsletter"
                    defaultValue={formDataState.descNewsletter}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
                <div className="input-devis-container">
                  <label htmlFor="author">Auteur</label>
                  <input
                    name="author"
                    id="author"
                    defaultValue={formDataState.author}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="input-devis-container">
              <label htmlFor="articleContent">
                Contenu de la newsletter (balisée)
              </label>
              <textarea
                name="articleContent"
                id="articleContent"
                resize="none"
                rows="50"
                defaultValue={formDataState.articleContent}
                onChange={(e) => {
                  handleChange(e.target.value, e.target.name);
                }}
              ></textarea>
            </div>
          </div>
          <div className="footer-devis-page">
            <div className="btn-devis-page">
              <button className="black-button" onClick={resetFormDataState}>
                annuler
              </button>
              <button
                className="black-button"
                onClick={CreateProduct}
                disabled={isLoading}
              >
                {isLoading ? "Chargement..." : "Créer"}
              </button>
            </div>
          </div>
          <div className="alertSuccess">{warning}</div>
          <div className="alertSuccess">{togglemessage}</div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewsletter;

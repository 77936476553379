import axios from "axios";
import { useEffect, useState } from "react";

const UnsubcribeNews = ({ setIsLoading }) => {
  const [isValidSubscriber, setIsValidSubscriber] = useState(
    JSON.parse(sessionStorage.getItem("isValidSubscriber")) || false
  );

  function handleUserData() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const formatData = {
      email: params?.email,
    };

    axios
      .post(
        "https://api.artimarq.fr/back/newsletter/unsubscription",
        formatData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data === true) {
          setIsValidSubscriber(true);
        } else {
          setIsValidSubscriber(false);
        }
        setIsLoading(false);
        sessionStorage.setItem("isValidSubscriber", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
      sessionStorage.setItem("confirmSuccess", true);
  }

  useEffect(() => {
    const paymentSuccess = sessionStorage.getItem("confirmSuccess");
    if (paymentSuccess === "true") {
      return;
    }

    if (window.location.pathname === "/desinscription-newsletter") {
      setIsLoading(true);
      handleUserData();
    }
  }, []);

  return (
    <div className="unsubscribe-page">
      {isValidSubscriber ? (
        <>
          <h1>Confirmation de désinscription.</h1>
          <p>
            Votre adresse e-mail a été retirée de notre liste de diffusion. Nous
            vous remercions d'avoir fait partie de notre communauté.
          </p>
          <p>
            Si vous avez des questions ou des commentaires, n'hésitez pas à{" "}
            <a href="/contacts">nous contacter</a>.
          </p>
        </>
      ) : (
        <>
          <h1>Un probleme est survenu, veuillez réessayer plutard.</h1>
        </>
      )}
      <a href="/">
        <button className="black-button">REVENIR SUR LE SITE</button>
      </a>
    </div>
  );
};

export default UnsubcribeNews;

import { NavLink } from "react-router-dom";
import ProductCard from "../components/ProductCard";
import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { ParamContext } from "../components/context/ParamContexte";

const Short = () => {
  const { products, nameCompany, imgOpenTags } = useContext(ParamContext);
  const baseUrl = "https://api.artimarq.fr/public/images/";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const productsFiltered = products
    .filter((product) => product.nameCategory === "Shorts")
    .sort((a, b) => {
      if (a.genderProduct === "Hommes" && b.genderProduct !== "Hommes") {
        return -1;
      } else if (b.genderProduct === "Hommes" && a.genderProduct !== "Hommes") {
        return 1;
      } else if (a.genderProduct === "Femmes" && b.genderProduct !== "Femmes") {
        return -1;
      } else if (b.genderProduct === "Femmes" && a.genderProduct !== "Femmes") {
        return 1;
      } else {
        return 0;
      }
    });

  let dataToDisplay;
  if (productsFiltered.length > 0) {
    dataToDisplay = productsFiltered.map((product) => (
      <ProductCard
        key={product.idProduct}
        idProduct={product.idProduct}
        nameProduct={product.nameProduct}
        images={product.nameMedia}
      />
    ));
  } else {
    dataToDisplay = (
      <div className="contentEmpty">
        <h3>
          Un problème est survenue ! Si celui-ci persiste, n'hesitez pas a nous{" "}
          <NavLink to="/conatcts">contacter</NavLink> directement.
        </h3>
      </div>
    );
  }

  return (
    <div className="classic-page products-pages">
      <Helmet>
        <title>{`${nameCompany} | Nos categories de shorts`}</title>
        <meta property="og:title" content="Nos categories de shorts" />
        <meta
          property="og:description"
          content="Découvrez l'art de personnaliser vos shorts."
        />
        <meta property="og:type" content="product" />
        <meta property="og:image" content={baseUrl + imgOpenTags} />
        <meta property="og:url" content="https://artimarq.fr/Shorts" />
        <meta property="og:site_name" content="ARTIMARQ site officiel" />
        <meta
          name="description"
          content="Découvrez l'art de personnaliser vos shorts. Des options performantes, résistantes au travail, confortables pour les loisirs. Priorité à votre image."
        />
        <link rel="canonical" href={`https://artimarq.fr/Shorts`} />
      </Helmet>
      <div className="head-products-pages">
        <h1>Shorts à personnaliés</h1>
        <p>
          Découvrez l'art de la personnalisation avec nos catégories de shorts.
          Que vous cherchiez des shorts de sport performants, des shorts de
          travail résistants, des shorts de loisirs confortables, ou d'autres
          options, notre principale priorité est de créer des shorts qui
          reflètent votre image.
        </p>
      </div>
      <div className="body-products-pages">
        {products.length > 0 ? (
          <div className="content-product">{dataToDisplay}</div>
        ) : (
          <div>{dataToDisplay}</div>
        )}
      </div>
    </div>
  );
};

export default Short;

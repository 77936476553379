import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import faqDatas from "../faqDatas.json";
import { NavLink } from "react-router-dom";
import { ParamContext } from "../components/context/ParamContexte";

const Faq = () => {
  const [expanded, setExpanded] = useState({});

  const { nameCompany } = useContext(ParamContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleAnswer = (id) => {
    setExpanded((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <div className="classic-page page-faq">
      <Helmet>
        <title>{`${nameCompany} | FAQ - Questions Fréquemment Posées`}</title>
        <meta
          name="description"
          content="Trouvez des réponses à vos questions fréquemment posées sur nos produits et services. Si vous ne trouvez pas de réponse ici, n'hésitez pas à nous contacter."
        />
        <link rel="canonical" href={`https://artimarq.fr/FAQ`} />
      </Helmet>
      <h1>Questions fréquemment posées</h1>
      <div>
        {faqDatas.map((option) => (
          <div key={option.id} className="ques-answ-container">
            <div
              className="question-container"
              onClick={() => toggleAnswer(option.id)}
            >
              <span>{option.question}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 266.77"
                className={expanded[option.id] ? "rotate" : ""}
              >
                <path d="M493.12 3.22c4.3-4.27 11.3-4.3 15.62-.04a10.85 10.85 0 0 1 .05 15.46L263.83 263.55c-4.3 4.28-11.3 4.3-15.63.05L3.21 18.64a10.85 10.85 0 0 1 .05-15.46c4.32-4.26 11.32-4.23 15.62.04L255.99 240.30 493.12 3.22z" />
              </svg>
            </div>
            <div
              className={`answer-container ${
                expanded[option.id] ? "show" : ""
              }`}
            >
              <p>{option.answer}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="contact-btn-faq">
        <span>Vous avez d’autres questions ?</span>
        <NavLink to="/contacts">
          <button className="black-button">Contactez-nous</button>
        </NavLink>
      </div>
    </div>
  );
};

export default Faq;

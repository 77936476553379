import { NavLink } from "react-router-dom";
import ProductCard from "../components/ProductCard";
import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { ParamContext } from "../components/context/ParamContexte";

const Accessories = () => {
  const { products, nameCompany, imgOpenTags } = useContext(ParamContext);

  const baseUrl = "https://api.artimarq.fr/public/images/";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const productsFiltered = products
    .filter((product) => product.nameCategory === "Accessoires")
    .sort((a, b) => {
      if (a.genderProduct === "Hommes" && b.genderProduct !== "Hommes") {
        return -1;
      } else if (b.genderProduct === "Hommes" && a.genderProduct !== "Hommes") {
        return 1;
      } else if (a.genderProduct === "Femmes" && b.genderProduct !== "Femmes") {
        return -1;
      } else if (b.genderProduct === "Femmes" && a.genderProduct !== "Femmes") {
        return 1;
      } else {
        return 0;
      }
    });

  let dataToDisplay;
  if (productsFiltered.length > 0) {
    dataToDisplay = productsFiltered.map((product) => (
      <ProductCard
        key={product.idProduct}
        idProduct={product.idProduct}
        nameProduct={product.nameProduct}
        images={product.nameMedia}
      />
    ));
  } else {
    dataToDisplay = (
      <div className="contentEmpty">
        <h3>
          Un problème est survenue ! Si celui-ci persiste, n'hesitez pas a nous{" "}
          <NavLink to="/conatcts">contacter</NavLink> directement.
        </h3>
      </div>
    );
  }

  return (
    <div className="classic-page products-pages">
      <Helmet>
        <title>{`${nameCompany} | Nos accessoires à personnalisé`}</title>
        <meta
          property="og:title"
          content="Nos accessoires à personnalisé"
        />
        <meta property="og:description" content="Optez pour l'exclusivité en personnalisant vos accessoires." />
        <meta property="og:type" content="product" />
        <meta property="og:image" content={baseUrl + imgOpenTags} />
        <meta property="og:url" content="https://artimarq.fr/accessoires" />
        <meta property="og:site_name" content="ARTIMARQ site officiel" />
        <meta
          name="description"
          content="Optez pour l'exclusivité en personnalisant vos accessoires : casquettes, bonnets, bobs... Variété d'options pour creer l'accessoire qui vous convient."
        />
        <link rel="canonical" href={`https://artimarq.fr/accessoires`} />
      </Helmet>
      <div className="head-products-pages">
        <h1>Accessoires à personnalisé</h1>
        <p>
          Optez pour l'exclusivité en personnalisant vos accessoires selon vos
          préférences et votre style uniques. Nous mettons à votre disposition
          une vaste sélection d'options pour créer l'accessoires qui vous
          convient.
        </p>
      </div>
      <div className="body-products-pages">
        {products.length > 0 ? (
          <div className="content-product">{dataToDisplay}</div>
        ) : (
          <div>{dataToDisplay}</div>
        )}
      </div>
      <div className="contact-button-product">
        <span>Vous ne trouvez pas ce que vous cherchez ?</span>
        <NavLink to="/demande-de-devis">
          <button className="black-button">Demandez un devis gratuit</button>
        </NavLink>
      </div>
    </div>
  );
};

export default Accessories;

import { NavLink } from "react-router-dom";
import ProductCard from "../components/ProductCard";
import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { ParamContext } from "../components/context/ParamContexte";

const Polos = () => {
  const { products, nameCompany, imgOpenTags } = useContext(ParamContext);
  const baseUrl = "https://api.artimarq.fr/public/images/";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const productsFiltered = products
    .filter((product) => product.nameCategory === "Polos")
    .sort((a, b) => {
      if (a.genderProduct === "Hommes" && b.genderProduct !== "Hommes") {
        return -1;
      } else if (b.genderProduct === "Hommes" && a.genderProduct !== "Hommes") {
        return 1;
      } else if (a.genderProduct === "Femmes" && b.genderProduct !== "Femmes") {
        return -1;
      } else if (b.genderProduct === "Femmes" && a.genderProduct !== "Femmes") {
        return 1;
      } else {
        return 0;
      }
    });

  let dataToDisplay;
  if (productsFiltered.length > 0) {
    dataToDisplay = productsFiltered.map((product) => (
      <ProductCard
        key={product.idProduct}
        idProduct={product.idProduct}
        nameProduct={product.nameProduct}
        images={product.nameMedia}
      />
    ));
  } else {
    dataToDisplay = (
      <div className="contentEmpty">
        <h3>
          Un problème est survenue ! Si celui-ci persiste, n'hesitez pas a nous{" "}
          <NavLink to="/conatcts">contacter</NavLink> directement.
        </h3>
      </div>
    );
  }

  return (
    <div className="classic-page products-pages">
      <Helmet>
        <title>{`${nameCompany} | Nos categories de polos`}</title>
        <meta property="og:title" content="Nos categories de polos" />
        <meta
          property="og:description"
          content="Créez un polo unique, à votre image, pour exprimer votre style distinctif."
        />
        <meta property="og:type" content="product" />
        <meta property="og:image" content={baseUrl + imgOpenTags} />
        <meta property="og:url" content="https://artimarq.fr/polos" />
        <meta property="og:site_name" content="ARTIMARQ site officiel" />
        <meta
          name="description"
          content="Découvrez la liberté de personnalisation avec notre sélection variée. Créez un polo unique, à votre image, pour exprimer votre style distinctif."
        />
        <link rel="canonical" href={`https://artimarq.fr/polos`} />
      </Helmet>
      <div className="head-products-pages">
        <h1>Polos Personnalisés : Élégance Décontractée</h1>
        <p>
          Notre sélection généreuse d'options vous offre l'opportunité de créer
          le polo qui exprimera au mieux votre propre style. Découvrez la
          liberté de la personnalisation et démarquez-vous avec un polo qui vous
          ressemble.
        </p>
      </div>
      <div className="body-products-pages">
        {products.length > 0 ? (
          <div className="content-product">{dataToDisplay}</div>
        ) : (
          <div>{dataToDisplay}</div>
        )}
      </div>
    </div>
  );
};

export default Polos;

import ProductCard from "../components/ProductCard";
import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { ParamContext } from "../components/context/ParamContexte";
import { NavLink } from "react-router-dom";

const Patch = () => {
  const { products, nameCompany, imgOpenTags } = useContext(ParamContext);
  const baseUrl = "https://api.artimarq.fr/public/images/";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const productsFiltered = products
    .filter((product) => product.nameCategory === "Patchs")
    .sort((a, b) => {
      if (a.genderProduct === "Hommes" && b.genderProduct !== "Hommes") {
        return -1;
      } else if (b.genderProduct === "Hommes" && a.genderProduct !== "Hommes") {
        return 1;
      } else if (a.genderProduct === "Femmes" && b.genderProduct !== "Femmes") {
        return -1;
      } else if (b.genderProduct === "Femmes" && a.genderProduct !== "Femmes") {
        return 1;
      } else {
        return 0;
      }
    });

  let dataToDisplay;
  if (productsFiltered.length > 0) {
    dataToDisplay = productsFiltered.map((product) => (
      <ProductCard
        // key={product.idProduct}
        // idProduct={product.idProduct}
        // nameProduct={product.nameProduct}
        // images={product.nameMedia}
      />
    ));
  } else {
    dataToDisplay = (
      <div className="contentEmpty">
        <h3>Aucun produit pour le moment.</h3>
      </div>
    );
  }

  return (
    <div className="classic-page products-pages">
      <Helmet>
        <title>{`${nameCompany} | Ecussons sur mesure`}</title>
        <meta property="og:title" content="Ecussons sur mesure" />
        <meta
          property="og:description"
          content=" Démarquez-vous grâce à des écussons sur mesure."
        />
        <meta property="og:type" content="service" />
        <meta property="og:image" content={baseUrl + imgOpenTags} />
        <meta property="og:url" content="https://artimarq.fr/ecussons" />
        <meta property="og:site_name" content="ARTIMARQ site officiel" />
        <meta
          name="description"
          content="Démarquez-vous grâce à des écussons sur mesure. Notre large sélection d'options vous permet de choisir les couleurs, les tailles et les designs qui reflètent le mieux votre identité."
        />
        <link rel="canonical" href={`https://artimarq.fr/ecussons`} />
      </Helmet>
      <div className="head-products-pages">
        <h1>Creez des écussons uniques</h1>
        <p>
          Démarquez-vous grâce à des écussons sur mesure. Chez ARTIMARQ, nous
          transformons vos idées en écussons uniques. Notre large sélection
          d'options vous permet de choisir les couleurs, les tailles et les
          designs qui reflètent le mieux votre identité, que ce soit pour
          personnaliser vos vêtements, accessoires ou uniformes professionnels.
        </p>
        <div className="contact-button-product">
          <NavLink to="/demande-de-devis">
            <button className="black-button">Je crée mon écusson</button>
          </NavLink>
        </div>
      </div>
      <div className="body-products-pages">
        {products.length > 0 ? (
          <div className="content-product">{dataToDisplay}</div>
        ) : (
          <div>{dataToDisplay}</div>
        )}
      </div>
    </div>
  );
};

export default Patch;

import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import qs from "qs";

const JoinCustomer = (props) => {
  let { toggleJoin, setToggleJoin, emailLeads, firstNameLeads, lastNameLeads } =
    props;

  const myRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!myRef.current.contains(e.target)) {
        setToggleJoin(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const [textContent, setTextContent] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    let handler = (e) => {
      if (!myRef.current.contains(e.target)) {
        setToggleJoin(false);
        setTextContent("");
        setSubject("");
        setMessage("");
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleTextChange = (event) => {
    setTextContent(event.target.value);
  };

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const joinCustomer = () => {
    const formatData = {
      emailLeads,
      textContent,
      subject,
      firstNameLeads,
      lastNameLeads,
    };

    axios
      .post(
        "https://api.artimarq.fr/back/orders/join",
        qs.stringify(formatData),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        setTextContent("");
        setSubject("");
        setMessage(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div
      className={`bg-contact-customer ${toggleJoin ? "active" : "inactive"}`}
    >
      <div ref={myRef} className="contact-Back-Container">
        <input
          type="text"
          placeholder="Objet"
          value={subject}
          onChange={handleSubjectChange}
        />
        <textarea
          cols="100"
          rows="15"
          placeholder="Message"
          value={textContent}
          onChange={handleTextChange}
        ></textarea>
        <div className="btn-Contact-Gest">
          <button
            className="card-btn"
            onClick={() => {
              setToggleJoin(false);
              setTextContent("");
              setSubject("");
              setMessage("");
            }}
          >
            ANNULER
          </button>
          <button className="card-btn" onClick={joinCustomer}>
            ENVOYER
          </button>
        </div>
        <div className="alertSuccess">{message}</div>
      </div>
    </div>
  );
};

export default JoinCustomer;

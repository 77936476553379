import React, { useEffect, useState } from "react";
import axios from "axios";
import NavbarBack from "../../components/back_office/NavbarBack";
import NavBarBackPage from "../../components/back_office/NavBarBackPage";
import { NavLink, useParams } from "react-router-dom";

const EditeNewsletter = () => {
  let { id } = useParams();

  const [article, setArticle] = useState([]);
  const [togglemessage, setToggleMessage] = useState("");
  const [warning, setWarning] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formDataState, setFormDataState] = useState({
    titleNewsletter: article.title,
    descNewsletter: article.description,
    author: article.Author,
    articleContent: article.content,
  });

  const resetFormDataState = () => {
    setFormDataState({
      titleNewsletter: article.title || "",
      descNewsletter: article.description || "",
      author: article.Author || "",
      articleContent: article.content || "",
    });
  };

  useEffect(() => {
    const getOrder = async () => {
      await axios
        .get(`https://api.artimarq.fr/back/newsletter/visualisation/${id}`)
        .then((res) => {
          const productsObj = Object.values(res.data);
          setArticle(productsObj[0]);
        });
    };
    getOrder();
  }, [id]);

  const handleChange = (value, name) => {
    setFormDataState((prevState) => ({ ...prevState, [name]: value }));
  };

  const EditeArticle = async () => {
    setIsLoading(true);

    try {
      const response = await axios.post(
        "https://api.artimarq.fr/back/newsletter/edit",
        {
          idArticle: id,
          titleNewsletter: formDataState.titleNewsletter,
          descNewsletter: formDataState.descNewsletter,
          author: formDataState.author,
          articleContent: formDataState.articleContent,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      //   console.log(response);
      setWarning("");
      resetFormDataState();
      setToggleMessage(response.data.message);
    } catch (error) {
      console.log(error.message);
      setToggleMessage(
        "Une erreur s'est produite lors de la création du produit."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="body-website_back">
      <NavbarBack />
      <div className="back-page-content">
        <NavBarBackPage />
        <div>
          <div className="input-devis-up-container admin-content-page">
            <NavLink to="/back/newsletter/articles">
              <button className="black-button">Retour</button>
            </NavLink>
            <div className="input-devis-bloc">
              <div className="section-input-devis"></div>
              <div className="section-input-devis">
                <div className="input-devis-container">
                  <label htmlFor="titleNewsletter">Titre de l'article</label>
                  <input
                    name="titleNewsletter"
                    id="titleNewsletter"
                    defaultValue={
                      formDataState.titleNewsletter || article?.title
                    }
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
              <div className="section-input-devis">
                <div className="input-devis-container">
                  <label htmlFor="descNewsletter">Description</label>
                  <input
                    name="descNewsletter"
                    id="descNewsletter"
                    defaultValue={
                      formDataState.descNewsletter || article?.description
                    }
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
                <div className="input-devis-container">
                  <label htmlFor="author">Auteur</label>
                  <input
                    name="author"
                    id="author"
                    defaultValue={formDataState.author || article?.Author}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="input-devis-container">
              <label htmlFor="articleContent">
                Contenu de la newsletter (balisée)
              </label>
              <textarea
                name="articleContent"
                id="articleContent"
                resize="none"
                rows="50"
                defaultValue={formDataState.articleContent || article?.content}
                onChange={(e) => {
                  handleChange(e.target.value, e.target.name);
                }}
              ></textarea>
            </div>
          </div>
          <div className="footer-devis-page">
            <div className="btn-devis-page">
              <button className="black-button" onClick={resetFormDataState}>
                annuler
              </button>
              <button
                className="black-button"
                onClick={EditeArticle}
                disabled={isLoading}
              >
                {isLoading ? "Chargement..." : "Modifier"}
              </button>
            </div>
          </div>
          <div className="alertSuccess">{warning}</div>
          <div className="alertSuccess">{togglemessage}</div>
        </div>
      </div>
    </div>
  );
};

export default EditeNewsletter;

import React, { useEffect, useState } from "react";
import NavbarBack from "../../components/back_office/NavbarBack";
import NavBarBackPage from "../../components/back_office/NavBarBackPage";
import axios from "axios";
import BillCard from "../../components/back_office/BillCard";

const Billing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [billsData, setBillsData] = useState([]);

  useEffect(() => {
    const getDevis = async () => {
      await axios
        .get(`https://api.artimarq.fr/back/bills/visualisations`)
        .then((res) => {
          const infoObj = Object.values(res.data);
          const sortedData = infoObj.sort(
            (a, b) => new Date(b.idBill) - new Date(a.idBill)
          );
          setBillsData(sortedData);
        });
    };
    getDevis();
  }, []);

  let dataToDisplay;
  if (billsData.length > 0) {
    dataToDisplay = billsData.map((order) => (
      <BillCard key={order.idBill} idBill={order.idBill} />
    ));
  } else {
    dataToDisplay = (
      <div className="contentEmpty">
        <h3>Vous n'avez aucune facture pour l'instant.</h3>
      </div>
    );
  }

  return (
    <div className="body-website_back">
      <NavbarBack />
      <div className="back-page-content">
        <NavBarBackPage />
        <div>
          <div className="admin-content-page">
            <div className="devis-back-container">{dataToDisplay}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Billing;

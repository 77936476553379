import React, { useEffect, useState } from "react";

const TextilParam = (props) => {
  const {
    category,
    nameProduct,
    selectedStyle,
    setSelectedGSM,
    selectedGSM,
    setSelectedStyle,
  } = props;

  const [optionsGSM, setOptionsGSM] = useState([]);
  const [optionsStyles, setOptionsStyles] = useState([]);
  const [optionRadio, setOptionRadio] = useState([
    "Coupe droite",
    "Coupe ajustée",
  ]);

  useEffect(() => {
    const updateOptions = () => {
      if (category === "T-shirts" || nameProduct === "Débardeurs hommes") {
        setOptionsGSM([
          "Fluide : Jusqu'à 140 g/m2",
          "Standard : 145 - 155 g/m2",
          "Lourd : Plus de 160 g/m2",
        ]);
        if (category === "T-shirts") {
          setOptionsStyles(["Longues manches", "Tailles enfants", "Col V"]);
        }
      } else if (category === "Polos") {
        setOptionsGSM([
          "Fluide : Jusqu'à 155 g/m2",
          "Standard : 155 - 195 g/m2",
          "Lourd : Plus de 195 g/m2",
        ]);
        setOptionsStyles(["Longues manches", "Tailles enfants"]);
      } else if (category === "Chemises") {
        setOptionsGSM([
          "Fluide : Jusqu'à 80 g/m2",
          "Standard : 80 - 150 g/m2",
          "Lourd : Plus de 150 g/m2",
        ]);
        setOptionsStyles(["Longues manches"]);
      } else if (
        category === "Sweat-shirts" ||
        category === "Hoodies" ||
        category === "Pantalons" ||
        category === "Shorts"
      ) {
        setOptionsGSM([
          "Fluide : Jusqu'à 190g/m²",
          "Standard : 200g/m² - 250g/m²",
          "Lourd : Plus de 250g/m²",
        ]);
        setOptionRadio(["Cintré", "Ample"]);
        setOptionsStyles(["Tailles enfants"]);
        if (category === "Hoodies") {
          setOptionsStyles(["Zippé", "Tailles enfants"]);
        }
      }
    };

    updateOptions();
  }, [category, nameProduct]);

  const handleGSMClick = (option) => {
    setSelectedGSM(option);
  };

  const handleStyleClick = (styleName) => {
    let updatedStyles = [];

    if (selectedStyle.includes(styleName)) {
      updatedStyles = selectedStyle.filter((style) => style !== styleName);
    } else {
      updatedStyles = [...selectedStyle, styleName];
    }

    setSelectedStyle(updatedStyles);
  };

  const shouldDisplayParameters =
    (category !== "Accessoires" && category !== "Autres produits") ||
    nameProduct === "Débardeurs hommes";

  return (
    shouldDisplayParameters && (
      <div className="container-param">
        <div className="body-custom-param">
          <span>Grammage</span>
          <div className="options-container">
            {optionsGSM.map((option, index) => (
              <div className="options-items" key={index}>
                <label htmlFor={option}>{option}</label>
                <input
                  id={option}
                  className="param-text-input"
                  type="radio"
                  name="radioGSM"
                  checked={selectedGSM === option || null}
                  onChange={() => handleGSMClick(option)}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="body-custom-param">
          <span>Style</span>
          <div className="options-container">
            {optionsStyles.map((option, index) => (
              <div className="options-items" key={index}>
                <label htmlFor={option}>{option}</label>
                <input
                  id={option}
                  className="param-text-input"
                  type="checkbox"
                  value={true}
                  checked={selectedStyle.includes(option) || null}
                  onChange={() => handleStyleClick(option)}
                />
              </div>
            ))}
            {optionRadio.map((option, index) => (
              <div className="options-items" key={index}>
                <label htmlFor={option}>{option}</label>
                <input
                  id={option}
                  className="param-text-input"
                  type="radio"
                  name="cut"
                  value={true}
                  checked={selectedStyle.includes(option) || null}
                  onChange={() => handleStyleClick(option)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  );
};

export default TextilParam;

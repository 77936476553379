import React, { useEffect, useState } from "react";
import NavbarBack from "../../components/back_office/NavbarBack";
import axios from "axios";
import NavBarBackPage from "../../components/back_office/NavBarBackPage";
import TeamMemberCard from "../../components/back_office/TeamMemberCard";

const TeamManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    const getUser = async () => {
      await axios
        .get(`https://api.artimarq.fr/back/users/visualisations`)
        .then((res) => {
          const infoObj = Object.values(res.data);
          const sortedData = infoObj.sort();
          setUsers(sortedData);
        });
    };
    getUser();
  }, []);

  let dataToDisplay;
  if (users.length > 0) {
    dataToDisplay = users.map((user) => (
      <TeamMemberCard key={user.idAdmin} idUser={user.idAdmin} />
    ));
  } else {
    dataToDisplay = (
      <div className="contentEmpty">
        <h3>Un probleme est survenu.</h3>
      </div>
    );
  }

  return (
    <div className="body-website_back">
      <NavbarBack />
      <div className="back-page-content">
        <NavBarBackPage />
        <div>
          <div className="admin-content-page">
            <div className="devis-back-container">{dataToDisplay}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamManagement;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Popup from "../../components/Popup";
import qs from "qs";

const ArticleBlogCardBack = ({ idArticle }) => {
  const baseUrl = "https://api.artimarq.fr/public/images/";

  const [articles, setArticles] = useState([]);
  const [togglePopup, setTogglePopup] = useState(false);
  const [isOn, setIsOn] = useState(false);
  const [messagePopup, setMessagePopup] = useState("");
  const [comeFrom, setComeFrom] = useState("");

  useEffect(() => {
    const getArticles = async () => {
      await axios
        .get(`https://api.artimarq.fr/back/blog/visualisation/${idArticle}`)
        .then((res) => {
          const ordersObj = Object.values(res.data);
          setArticles(ordersObj[0]);
        });
    };
    getArticles();
  }, [idArticle]);

  useEffect(() => {
    if (articles?.onlineArticle === "1") {
      setIsOn(true);
    } else {
      setIsOn(false);
    }
  }, [articles?.onlineArticle]);

  const datePerser = (date) => {
    if (date) {
      const monthNames = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ];

      const newDate = new Date(date);
      const day = newDate.getDate().toString().padStart(2, "0");
      const month = monthNames[newDate.getMonth()];
      const year = newDate.getFullYear();

      return `${day} ${month} ${year}`;
    } else {
      return "";
    }
  };

  const HandlePopupDeletion = () => {
    setMessagePopup(
      "La suppression est irreversible, êtes-vous certain de vouloir supprimer cet article ?"
    );
    setComeFrom("artcleDeletion");
    setTogglePopup(true);
  };

  const HandlePopupOnline = () => {
    setMessagePopup("Confirmez-vous cette action ?");
    setComeFrom("toggleOnlineArticle");
    setTogglePopup(true);
  };

  const toggleArticle = () => {
    setIsOn(!isOn);

    const formatData = {
      idArticle,
    };

    axios
      .post(
        "https://api.artimarq.fr/back/blog/publish",
        qs.stringify(formatData),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteArticle = () => {
    const formatData = {
      idArticle,
      idMedia: articles?.idMedia,
    };

    axios
      .post(
        "https://api.artimarq.fr/back/blog/delete",
        qs.stringify(formatData),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="blog-card">
        <div
          className="img-blog-container"
          style={{ backgroundImage: `url(${baseUrl}${articles?.nameMedia}` }}
        ></div>
        <div className="card-content">
          <small>
            {articles?.categories} | {datePerser(articles?.dateArticle)}
          </small>
          <h2>{articles?.title}</h2>
          <p>{articles?.description}</p>
          <small>
            Publié par {articles?.firstNameAuthor} {articles?.lastNameAuthor}
          </small>
          <div
            className={`switch ${isOn ? "on" : "off"}`}
            onClick={HandlePopupOnline}
          >
            <div className="toggle" />
          </div>
          <NavLink to={`/artimarq-le-blog/article/${idArticle}`}>
            <button className="black-button">
              <span>Lire l'article</span>
            </button>
          </NavLink>
          <NavLink to={`/back/blog/edition-article/${idArticle}`}>
            <button className="black-button">
              <span>Modifier</span>
            </button>
          </NavLink>
          <button className="black-button" onClick={HandlePopupDeletion}>
            Supprimer
          </button>
        </div>
      </div>
      <Popup
        togglePopup={togglePopup}
        setTogglePopup={setTogglePopup}
        onSubmite={
          comeFrom === "toggleOnlineArticle" ? toggleArticle : deleteArticle
        }
        messagePopup={messagePopup}
        comeFrom={comeFrom}
      />
    </div>
  );
};

export default ArticleBlogCardBack;

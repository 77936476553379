import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import axios from "axios";
import qs from "qs";
import { useState } from "react";
import { NavLink } from "react-router-dom";

const ContactForm = () => {
  const [toggleMessage, setToggleMessage] = useState("");

  const validationSchema = Yup.object().shape({
    civility: Yup.string().required("Veuillez selectioner une option"),
    firstName: Yup.string().required("Veuillez saisir votre nom"),
    lastName: Yup.string().required("Veuillez saisir votre prénom"),
    email: Yup.string()
      .email("Veuillez saisir un email valide (ex. mon-addresse@gmail.com")
      .required("Veuillez saisir un email"),
    phone: Yup.string().required("Veuillez saisir un numero de telephone"),
    contactSubject: Yup.string().test(
      "orgaCountry",
      "Selectionné un sujet",
      function (contactSubject) {
        return contactSubject !== "Raison du contact *";
      }
    ),
    message: Yup.string().required("Veuillez ajouter votre message"),
    acceptTerms: Yup.bool()
      .transform((value, originalValue) =>
        originalValue === "on" ? true : value
      )
      .oneOf([true], "Accepter les conditions est obligatoire"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      acceptTerms: false,
      newsletter: true,
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    const formatData = {
      organisation: data.organisation,
      civility: data.civility,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      message: data.message,
      contactSubject: data.contactSubject,
      acceptTerms: data.acceptTerms,
      newsLetter: data.newsletter,
    };

    axios
      .post(
        "https://api.artimarq.fr/front/sendMessage",
        qs.stringify(formatData),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        if (response.data.success === true) {
          reset();
          setToggleMessage(response.data.message);
        } else {
          setToggleMessage(response.data.message);
        }
      })
      .catch((error) => {
        alert(error.data.message);
      });
  };

  return (
    <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="input-contact-container">
        <label htmlFor="orga-name-contact">
          Nom de votre organisation (facultatif)
        </label>
        <input
          type="text"
          id="orga-name-contact"
          name="organisation"
          {...register("organisation")}
        />
      </div>
      <div className="civility-contact-container">
        <div className="civility-opt">
          <label className={errors.civility ? "errorInput" : ""} htmlFor="M">
            <input
              id="M"
              type="radio"
              name="civility"
              value="M"
              {...register("civility")}
              className={`radioCiv ${errors.civility ? "errorInput" : ""}`}
            />
            M
          </label>
        </div>
        <div className="civility-opt">
          <label className={errors.civility ? "errorInput" : ""} htmlFor="Mme">
            <input
              id="Mme"
              type="radio"
              name="civility"
              value="Mme"
              {...register("civility")}
              className={`radioCiv ${errors.civility ? "errorInput" : ""}`}
            />
            Mme
          </label>
        </div>
      </div>
      <div>
        <div className="form-body-contact">
          <div className="input-contact-container">
            <label htmlFor="contact-last-name">Nom *</label>
            <input
              type="text"
              id="contact-last-name"
              name="lastName"
              {...register("lastName")}
            />
            {errors.lastName && (
              <small className="errorInputConfig">
                {errors.lastName?.message}
              </small>
            )}
          </div>
          <div className="input-contact-container">
            <label htmlFor="contact-first-name">Prenom *</label>
            <input
              type="text"
              id="contact-first-name"
              name="firstName"
              {...register("firstName")}
            />
            {errors.firstName && (
              <small className="errorInputConfig">
                {errors.firstName?.message}
              </small>
            )}
          </div>
          <div className="input-contact-container">
            <label htmlFor="contact-email">Email *</label>
            <input
              type="email"
              id="contact-email"
              name="email"
              {...register("email")}
            />
            {errors.email && (
              <small className="errorInputConfig">
                {errors.email?.message}
              </small>
            )}
          </div>
          <div className="input-contact-container">
            <label htmlFor="contact-phone">Telephone *</label>
            <input
              type="text"
              id="contact-phone"
              name="phone"
              {...register("phone")}
            />
            {errors.phone && (
              <small className="errorInputConfig">
                {errors.phone?.message}
              </small>
            )}
          </div>
          <div className="input-contact-container">
            <label>Sujet</label>
            <select
              name="contactSubject"
              {...register("contactSubject")}
              defaultValue="Raison du contact *"
            >
              <option disabled>Raison du contact *</option>
              <option value="Commande">Commande</option>
              <option value="Informations produits">
                Informations produits
              </option>
              <option value="Presse">Presse</option>
              <option value="Ressources Humaines">Ressources Humaines</option>
              <option value="Suggestions">Suggestions</option>
              <option value="Retour">Retour</option>
              <option value="Autre">Autre</option>
            </select>
            {errors.contactSubject && (
              <small className="errorInputConfig">
                {errors.contactSubject?.message}
              </small>
            )}
          </div>
          <div className="message-container">
            <div className="input-contact-container">
              <label htmlFor="contact-message">Message *</label>
              <textarea
                name="message"
                id="contact-message"
                resize="none"
                maxLength={1000}
                {...register("message")}
              ></textarea>
              <small>1000 caractères</small>
              {errors.message && (
                <small className="errorInputConfig">
                  {errors.message?.message}
                </small>
              )}
            </div>
          </div>
          <div className="check-box-container">
            <div className="using-cond-container">
              <div className="checkbox-space">
                <input
                  type="checkbox"
                  id="acceptTerms"
                  name="acceptTerms"
                  className={errors.acceptTerms ? "errorInput" : ""}
                  {...register("acceptTerms")}
                />
                <svg
                  className="checked-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 457.57"
                >
                  <path d="M0,220.57c100.43-1.33,121-5.2,191.79,81.5,54.29-90,114.62-167.9,179.92-235.86C436-.72,436.5-.89,512,.24,383.54,143,278.71,295.74,194.87,457.57,150,361.45,87.33,280.53,0,220.57Z" />
                </svg>
              </div>
              <div
                className={`label-using-cond-container ${
                  errors.acceptTerms ? "errorInput" : ""
                }`}
              >
                <label htmlFor="acceptTerms">
                  En appuyant sur cochant cette case, je reconnais avoir accepté
                  la politique de{" "}
                  <NavLink to="/protection-des-données">
                    protection des données &amp; l'utilisation de cookies.
                  </NavLink>
                </label>
              </div>
            </div>
            <div className="newsletter">
              <div className="checkbox-space">
                <input
                  type="checkbox"
                  id="checknews"
                  name="newsletter"
                  {...register("newsletter")}
                />
                <svg
                  className="checked-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 457.57"
                >
                  <path d="M0,220.57c100.43-1.33,121-5.2,191.79,81.5,54.29-90,114.62-167.9,179.92-235.86C436-.72,436.5-.89,512,.24,383.54,143,278.71,295.74,194.87,457.57,150,361.45,87.33,280.53,0,220.57Z" />
                </svg>
              </div>
              <div className="labelContainer">
                <label htmlFor="checknews">
                  Je souhaite recevoir la Newsletter et être informé(e) de
                  toutes les nouveauté.
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="submit-contact-container">
        <button className="black-button">Envoyer</button>
      </div>
      <div className="alertSuccess">{toggleMessage}</div>
    </form>
  );
};

export default ContactForm;

import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import ProductCard from "../components/ProductCard";
import { Helmet } from "react-helmet";
import { Link, Element } from "react-scroll";
import { ParamContext } from "../components/context/ParamContexte";

const HomePage = ({ setIsLoading }) => {
  const { imgHomeCover, nameCompany, homeTitle, homeSubTitle, imgOpenTags } =
    useContext(ParamContext);

  const baseUrl = "https://api.artimarq.fr/public/images/";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [products, setProducts] = useState([]);
  const [section, setSection] = useState(null);

  const myRef = useRef();
  const sectionRef = useRef();

  const handleSection = (option) => {
    setSection(option);
  };

  useEffect(() => {
    let handler = (e) => {
      if (!myRef.current.contains(e.target)) {
        setSection(false);
        handleSection(null);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productsResponse = await axios.get(
          "https://api.artimarq.fr/front/products"
        );

        const productsData = Object.values(productsResponse.data).filter(
          (product) => product.displayHomePage === "1"
        );
        setProducts(productsData);
        setIsLoading(false);
      } catch (error) {
        console.error(
          "Une erreur s'est produite lors de la récupération des données.",
          error
        );
        setIsLoading(false);
      }
    };

    fetchData();
  }, [setIsLoading]);

  return (
    <div>
      <Helmet>
        <title>{`${nameCompany} | Personnalisez vos vêtements : communication, événements, professionnels, cadeaux.`}</title>
        <meta
          property="og:title"
          content= "Personnalisez vos vêtements"
        />
        <meta property="og:description" content="Personnalisez vos vêtements : flocage, serigraphie, impression textile & broderie." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={baseUrl + imgOpenTags} />
        <meta property="og:url" content="https://artimarq.fr" />
        <meta property="og:site_name" content="ARTIMARQ site officiel" />
        <meta
          name="description"
          content="Laissez une impression durable avec des vêtements personnalisés qui parlent pour vous : flocage, sérigraphie, impression & broderie. Créez une identité forte qui influence."
        />
        <link rel="canonical" href={`https://artimarq.fr`} />
      </Helmet>
      <section
        className="homeHeroUp"
        style={{ backgroundImage: imgHomeCover }}
        alt="product"
      >
        {/* <div className="heroOverlay"></div> */}
        <div className="heroText">
          <h1>{homeTitle}</h1>
          <p>{homeSubTitle}</p>
          <a href="/contacts">
            <button
              className="white-button btnHomeBanner"
            >
              Nous contacter
            </button>
          </a>
        </div>
      </section>
      <section className="home-content">
        <div className="head-content">
          <h2>Choisissez votre produit.</h2>
          <span>Vêtements à personnaliser</span>
        </div>
        <div className="products-container">
          {products.map((product) => (
            <ProductCard
              key={product.idProduct}
              idProduct={product.idProduct}
              nameProduct={product.nameProduct}
              images={product.nameMedia}
            />
          ))}
        </div>
        <div ref={myRef} className="links-services">
          <ul>
            <Link
              to="section-desc-home"
              smooth={true}
              duration={500}
              offset={-350}
            >
              <li
                onClick={() => {
                  handleSection("NOS MARQUAGES");
                }}
              >
                NOS MARQUAGES
              </li>
            </Link>
          </ul>
          <ul>
            <Link
              to="section-desc-home"
              smooth={true}
              duration={500}
              offset={-350}
            >
              <li onClick={() => handleSection("ACCOMPAGNEMENT")}>
                ACCOMPAGNEMENT
              </li>
            </Link>
          </ul>
          <ul>
            <Link
              to="section-desc-home"
              smooth={true}
              duration={500}
              offset={-350}
            >
              <li onClick={() => handleSection("CREATION GRAPHIQUE")}>
                CREATION GRAPHIQUE
              </li>
            </Link>
          </ul>
        </div>
        <div
          className="home-text-container"
          id="section-desc-home"
          ref={sectionRef}
        >
          <Element name="section-desc-home">
            {section !== null && section !== undefined && (
              <div
                className="cross-contanier"
                onClick={() => {
                  handleSection(null);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 451.124 451.124"
                >
                  <path
                    className="cross"
                    d="M310.5,260.5L747.5,697.5Zm0,0L747.5,697.5Zm436.992,0L310.5,697.5Zm0,0L310.5,697.5Z"
                    transform="translate(-303.438 -253.438)"
                  />
                </svg>
              </div>
            )}
            {section === "NOS MARQUAGES" ? (
              <div>
                <h2>Votre Marque, Votre Style</h2>
                <p>
                  Votre temps est précieux. Nous comprenons l'importance de
                  respecter les délais. Nos processus de production efficaces
                  garantissent une livraison rapide sans compromettre la
                  qualité. Chez {nameCompany}, nous sommes les experts en
                  marquage textile, prêts à donner vie à vos idées. Notre
                  engagement envers l'excellence signifie que nous allons
                  au-delà de la simple impression. Nous croyons que chaque
                  vêtement devrait être une œuvre d'art unique. Que ce soit pour
                  des t-shirts, des uniformes d'entreprise ou des articles
                  promotionnels, nous offrons une personnalisation sans limite.
                  Choisissez parmi une variété de techniques de marquage.
                </p>
              </div>
            ) : section === "ACCOMPAGNEMENT" ? (
              <div>
                <h2>Pourquoi choisir nos services ?</h2>
                <p>
                  Chez {nameCompany}, nous nous engageons à fournir des
                  solutions de marquage de haute qualité pour répondre à tous
                  vos besoins.
                  <br></br>
                  Personnalisation exceptionnelle, créez des designs uniques
                  pour vos textiles. Qualité supérieure, utilisation de
                  matériaux de haute qualité pour des résultats durables.
                  Conseils d'experts, notre équipe est là pour vous guider à
                  chaque étape du processus. Suivi personnalisé, nous assurons
                  un suivi attentif pour vous satisfaire pleinement.<br></br>
                  <br></br>
                  Lorsque vous choisissez {nameCompany}, vous choisissez un
                  partenaire créatif dédié à la réussite de votre entreprise.
                  Nous sommes là pour transformer vos idées en réalité visuelle.
                </p>
              </div>
            ) : section === "CREATION GRAPHIQUE" ? (
              <div>
                <h2>Nos services de graphisme, créativité sans limites</h2>
                <p>
                  Chez {nameCompany}, nous croyons en la puissance de l'image et
                  du design pour transformer vos idées en réalité. Notre
                  engagement envers l'excellence va au-delà du marquage textile.
                  Nous vous offrons une gamme complète de services de graphisme
                  pour donner vie à votre vision.<br></br>
                  <br></br>
                  Nous sommes prêts à concevoir des visuels uniques et
                  mémorables qui reflètent l'identité de votre entreprise. Que
                  ce soit pour des logos, des graphiques promotionnels ou des
                  designs de vêtements, nous créons des œuvres d'art visuelles
                  qui captivent l'attention.
                </p>
              </div>
            ) : (
              <div>
                <h2>{nameCompany}, acteur français du marquage textile</h2>
                <p>
                  {nameCompany}, votre partenaire dans le domaine de la
                  personnalisation. Nous sommes spécialisés dans la création de
                  produits personnalisés, conçus pour répondre à vos besoins.
                  Chez {nameCompany}, nous comprenons l'importance de votre
                  communication et la mise en valeur de votre marque. Notre
                  équipe de passionnés met tout en œuvre pour produire des
                  résultats exceptionnels.
                  <br></br>
                  <br></br>Que vous souhaitiez des t-shirts personnalisés, des
                  vêtements de travail ou des articles promotionnels, nous nous
                  engageons à fournir des résultats durables, avec des couleurs
                  éclatantes et une grande attention aux détails.
                </p>
              </div>
            )}
          </Element>
        </div>
        <div className="engagement-container">
          <div className="engagement">
            <img src="img/proposal-icon.png" alt="proposal-icon" />
            <div className="text-container-eng">
              <span>Conforme au cahier des charges.</span>
            </div>
          </div>
          <div className="engagement">
            <img src="img/fast-icon.png" alt="fast-icon" />
            <div className="text-container-eng">
              <span>Ajuster vos delais de livraison.</span>
            </div>
          </div>
          <div className="engagement">
            <img src="img/headphone-headset-icon.png" alt="headset-icon" />
            <div className="text-container-eng">
              <span>Devis gratuit et sans engagement.</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;

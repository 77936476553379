import axios from "axios";
import React, { useEffect, useState } from "react";
import qs from "qs";
import Popup from "../Popup";
import EditeUser from "./EditeUser";

const TeamMemberCard = (props) => {
  let { idUser } = props;

  const [user, setUser] = useState([]);
  const [togglePopup, setTogglePopup] = useState(false);
  const [toggleEdtiteUser, setToggleEdtiteUser] = useState(false);

  useEffect(() => {
    if (user.adminAcces === "1") {
      setRank("administrateur");
    } else {
      setRank("collaborateur");
    }
  }, [user.adminAcces]);

  const [rank, setRank] = useState("");

  useEffect(() => {
    const getOrder = async () => {
      await axios
        .get(`https://api.artimarq.fr/back/users/visualisation/${idUser}`)
        .then((res) => {
          const usersObj = Object.values(res.data);
          setUser(usersObj[0]);
        });
    };
    getOrder();
  }, [idUser]);

  const deleteUser = () => {
    const formatData = {
      idUser,
    };

    axios
      .post(
        "https://api.artimarq.fr/back/users/delete",
        qs.stringify(formatData),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <ul className="devis-card-back">
      <li>
        <h5>Identité</h5>
        <span>
          {user?.lastName} {user?.firstName}
        </span>
        <span>{rank}</span>
      </li>
      <li>
        <h5>Adresse</h5>
        {<span>{user?.adress}</span>}
        {<span>{user?.adressComp}</span>}
        {
          <span>
            {user?.zipCode}
            {" - "}
            {user?.cityCode}
            {", "}
            {user?.country}
          </span>
        }
      </li>
      <li>
        <h5>Contacts</h5>
        <span>{user?.phone}</span>
        <span>{user?.email}</span>
      </li>
      <li>
        <h5>Identifiant</h5>
        <span>{user?.login}</span>
      </li>
      <li className="last-item">
        <button className="card-btn" onClick={() => setToggleEdtiteUser(true)}>
          Modifier
        </button>
        <button
          className="card-btn"
          onClick={() => {
            setTogglePopup(true);
          }}
        >
          Supprimer
        </button>
      </li>
      <Popup
        togglePopup={togglePopup}
        setTogglePopup={setTogglePopup}
        onSubmite={deleteUser}
        messagePopup="La suppression est irreversible, êtes-vous certain de vouloir supprimer cet utilisateur ?"
        comeFrom="userDeletion"
      />
      <EditeUser
        toggleEdtiteUser={toggleEdtiteUser}
        setToggleEdtiteUser={setToggleEdtiteUser}
        idUser={user?.idAdmin}
        firstNameUser={user?.firstName}
        lastNameUser={user?.lastName}
        emailUser={user?.email}
        phoneUser={user?.phone}
        address={user?.adress}
        addressCom={user?.adressComp}
        city={user?.cityCode}
        zip={user?.zipCode}
        country={user?.country}
        login={user?.login}
        statutUser={user?.adminAcces}
      />
    </ul>
  );
};

export default TeamMemberCard;

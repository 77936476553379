import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavLink, useParams } from "react-router-dom";
import NavbarBack from "./NavbarBack";

const EditeProduct = () => {
  let { id } = useParams();

  const [product, setProdct] = useState([]);
  const [toggleMessage, setToggleMessage] = useState("");
  const [toggleMessageSec, setToggleMessageSec] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [files, setFiles] = useState([]);
  const [displayHome, setDisplayHome] = useState("");
  const [backActive, setBackActive] = useState("");
  const [faceActive, setFaceActive] = useState("");
  const [sideActive, setSideActive] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formDataState, setFormDataState] = useState({
    displayHomePage: product?.displayHomePage,
    genderProduct: product?.genderProduct,
    isBackActive: product?.isBackActive,
    isFaceActive: product?.isFaceActive,
    isSideActive: product?.isSideActive,
    nameCategory: product?.nameCategory,
    nameProduct: product?.nameProduct,
    productType: product?.productType,
    files,
    selectedOptions,
  });

  const resetFormDataState = () => {
    setFormDataState({
      displayHomePage: product?.displayHomePage || "",
      genderProduct: product?.genderProduct || "",
      isBackActive: product?.isBackActive || "",
      isFaceActive: product?.isFaceActive || "",
      isSideActive: product?.isSideActive || "",
      nameCategory: product?.nameCategory || "",
      nameProduct: product?.nameProduct || "",
      productType: product?.productType || "",
      setFiles: [],
      selectedOptions: selectedOptions,
    });
  };

  useEffect(() => {
    const getOrder = async () => {
      await axios
        .get(`https://api.artimarq.fr/back/products/visualisation/${id}`)
        .then((res) => {
          const productsObj = Object.values(res.data);
          setProdct(productsObj[0]);
        });
    };
    getOrder();
  }, [id]);

  const options = [
    { id: 1, name: "Serigraphie" },
    { id: 2, name: "Flocage" },
    { id: 3, name: "Impression" },
    { id: 4, name: "Broderie" },
  ];

  useEffect(() => {
    if (product?.displayHomePage === "1") {
      setDisplayHome("1");
    } else {
      setDisplayHome(false);
    }
  }, [product?.displayHomePage]);

  useEffect(() => {
    if (product?.isBackActive === "1") {
      setBackActive("1");
    } else {
      setBackActive(false);
    }
  }, [product?.isBackActive]);

  useEffect(() => {
    if (product?.isFaceActive === "1") {
      setFaceActive("1");
    } else {
      setFaceActive(false);
    }
  }, [product?.isFaceActive]);

  useEffect(() => {
    if (product?.isSideActive === "1") {
      setSideActive("1");
    } else {
      setSideActive(false);
    }
  }, [product?.isSideActive]);

  const handleChangeFiles = (event) => {
    const newFiles = event.target.files;
    setFiles([...newFiles]);
  };

  const handleChange = (value, name) => {
    setFormDataState((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    if (
      product?.technics &&
      Array.isArray(product?.technics) &&
      product?.technics.length > 0
    ) {
      const ids = product?.technics.map((technic) => technic.idTechnic);
      setSelectedOptions(ids);
    }
  }, [product?.technics, id]);

  const handleOptionClick = (option) => {
    const stringOptions = selectedOptions.map((value) => String(value));
    const stringValue = String(option);

    if (stringOptions.includes(stringValue)) {
      const newSelectedOptions = stringOptions.filter(
        (value) => value !== stringValue
      );
      setSelectedOptions(newSelectedOptions);
    } else {
      setSelectedOptions([...stringOptions, stringValue]);
    }
  };

  const EditeProduct = async () => {
    setIsLoading(true);

    const formatData = {
      idProduct: id,
      displayHomePage: formDataState.displayHomePage,
      genderProduct: formDataState.genderProduct,
      isBackActive: formDataState.isBackActive,
      isFaceActive: formDataState.isFaceActive,
      isSideActive: formDataState.isSideActive,
      nameCategory: formDataState.nameCategory,
      nameMedia: product?.nameMedia,
      nameProduct: formDataState.nameProduct,
      productType: formDataState.productType,
      files,
      selectedOptions,
    };

    try {
      const response = await axios.post(
        "https://api.artimarq.fr/back/products/edit",
        formatData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      console.log(response);
      resetFormDataState();
      setToggleMessage(response.data.message);
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const ChangeImg = async () => {
    setIsLoading(true);

    const filesData = new FormData();
    filesData.append("idProduct", id);
    filesData.append("nameMedia", product?.nameMedia);
    for (let i = 0; i < files.length; i++) {
      filesData.append("files[]", files[i]);
    }

    try {
      const response = await axios.post(
        "https://api.artimarq.fr/back/products/edit",
        filesData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response);
      setToggleMessageSec(response.data.message);
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="body-website_back edite-product-page">
      <NavbarBack />
      <div className="back-page-content">
        <a href="/back/gestion-produits">
          <button className="black-button">Retour</button>
        </a>
        <div className="input-devis-up-container admin-content-page">
          <div className="input-devis-bloc">
            <div className="section-input-devis">
              <div className="input-devis-container">
                <label>Genre</label>
                <select
                  name="genderProduct"
                  id="genderProduct"
                  value={formDataState.genderProduct || product?.genderProduct}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                >
                  <option value="Hommes">Hommes</option>
                  <option value="Femmes">Femmes</option>
                  <option value="Unisexe">Unisexe</option>
                </select>
              </div>
              <div className="input-devis-container">
                <label>Categorie</label>
                <select
                  name="nameCategory"
                  id="nameCategory"
                  value={formDataState.nameCategory || product?.nameCategory}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                >
                  <option value="T-shirts">T-shirts</option>
                  <option value="Polos">Polos</option>
                  <option value="Chemises">Chemises</option>
                  <option value="Hoodies">Hoodies</option>
                  <option value="Sweat-shirts">Sweat-shirts</option>
                  <option value="Pantalons">Pantalons</option>
                  <option value="Shorts">Shorts</option>
                  <option value="Accessoires">Accessoires</option>
                  <option value="Autres produits">Autres produits</option>
                  <option value="Patchs">Ecussons</option>
                </select>
              </div>
              <div className="input-devis-container">
                <label>Afficher en page d'acceuil</label>
                <select
                  name="displayHomePage"
                  id="displayHomePage"
                  value={formDataState.displayHomePage || displayHome}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                >
                  <option value="1">Oui</option>
                  <option value={false}>Non</option>
                </select>
              </div>
            </div>
            <div className="section-input-devis">
              <div className="input-devis-container">
                <label>Imprimable de face ?</label>
                <select
                  name="isFaceActive"
                  id="isFaceActive"
                  value={formDataState.isFaceActive || faceActive}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                >
                  <option value="1">Oui</option>
                  <option value={false}>Non</option>
                </select>
              </div>
              <div className="input-devis-container">
                <label>Imprimable de dos ?</label>
                <select
                  name="isBackActive"
                  id="isBackActive"
                  value={formDataState.isBackActive || backActive}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                >
                  <option value="1">Oui</option>
                  <option value={false}>Non</option>
                </select>
              </div>
              <div className="input-devis-container">
                <label>Imprimable de coté ?</label>
                <select
                  name="isSideActive"
                  id="isSideActive"
                  value={formDataState.isSideActive || sideActive}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                >
                  <option value="1">Oui</option>
                  <option value={false}>Non</option>
                </select>
              </div>
            </div>
            <div className="section-input-devis">
              <div className="input-devis-container">
                <label htmlFor="nameProduct">Nom du produit</label>
                <input
                  name="nameProduct"
                  id="nameProduct"
                  defaultValue={
                    formDataState.nameProduct || product?.nameProduct
                  }
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
              <div className="input-devis-container">
                <label>Type de produit</label>
                <select
                  name="productType"
                  id="productType"
                  value={formDataState.productType}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                >
                  <option value="T-shirts">T-shirts</option>
                  <option value="Polos">Polos</option>
                  <option value="Chemises">Chemises</option>
                  <option value="Hoodies">Hoodies</option>
                  <option value="Sweat-shirts">Sweat-shirts</option>
                  <option value="Pantalons">Pantalons</option>
                  <option value="Shorts">Shorts</option>
                  <option value="Casquettes">Casquettes</option>
                  <option value="Débardeurs">Débardeurs</option>
                  <option value="Bobs">Bobs</option>
                  <option value="Bonnets">Bonnets</option>
                  <option value="Sacs à dos">Sacs à dos</option>
                  <option value="Tote bags">Tote bags</option>
                  <option value="Tabliers">Tabliers</option>
                  <option value="Masques">Masques</option>
                  <option value="Peignoirs">Peignoirs</option>
                  <option value="Sacs de gym">Sacs de gym</option>
                  <option value="Serviettes">Serviettes</option>
                  <option value="Patchs">Ecussons</option>
                </select>
              </div>
            </div>
          </div>
          <div className="body-ask-devis">
            {options.map((option) => (
              <label
                key={option.id}
                htmlFor={option.id}
                className={`orga-items ${
                  selectedOptions && selectedOptions.includes(String(option.id))
                    ? "orga-selected"
                    : ""
                }`}
              >
                <input
                  id={option.id}
                  type="checkbox"
                  name="checkboxOrga"
                  value={option.id}
                  checked={
                    selectedOptions &&
                    selectedOptions.includes(String(option.id))
                  }
                  onChange={(e) => handleOptionClick(e.target.value)}
                />
                {option.name}
              </label>
            ))}
          </div>
          <div className="container-edite-btn">
            <div className="edite-btn">
              <NavLink to="/back/gestion-produits">
                <button
                  className="black-button"
                  onClick={() => {
                    resetFormDataState();
                  }}
                >
                  annuler
                </button>
              </NavLink>
              <button
                className="black-button"
                onClick={EditeProduct}
                disabled={isLoading}
              >
                {isLoading ? "Chargement..." : "Valider"}
              </button>
            </div>
          </div>
          <div className="alertSuccess">{toggleMessage}</div>
          <div className="containerSectionFiles">
            <div>
              <div className="input-devis-container">
                <label>Changer de visuel</label>
                <form className="upload">
                  <label htmlFor="uploadInput" className="uploadButton">
                    <div className="uploadBody">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 99.09 122.88"
                      >
                        <path d="M64.64,13,86.77,36.21H64.64V13ZM42.58,71.67a3.25,3.25,0,0,1-4.92-4.25l9.42-10.91a3.26,3.26,0,0,1,4.59-.33,5.14,5.14,0,0,1,.4.41l9.3,10.28a3.24,3.24,0,0,1-4.81,4.35L52.8,67.07V82.52a3.26,3.26,0,1,1-6.52,0V67.38l-3.7,4.29ZM24.22,85.42a3.26,3.26,0,1,1,6.52,0v7.46H68.36V85.42a3.26,3.26,0,1,1,6.51,0V96.14a3.26,3.26,0,0,1-3.26,3.26H27.48a3.26,3.26,0,0,1-3.26-3.26V85.42ZM99.08,39.19c.15-.57-1.18-2.07-2.68-3.56L63.8,1.36A3.63,3.63,0,0,0,61,0H6.62A6.62,6.62,0,0,0,0,6.62V116.26a6.62,6.62,0,0,0,6.62,6.62H92.46a6.62,6.62,0,0,0,6.62-6.62V39.19Zm-7.4,4.42v71.87H7.4V7.37H57.25V39.9A3.71,3.71,0,0,0,61,43.61Z" />
                      </svg>
                      <p>Glissez le fichier</p>
                    </div>
                  </label>
                  <div className="uploadFooter">
                    <label htmlFor="uploadInput" className="uploadButton">
                      <p>Parcourir</p>
                      <input
                        type="file"
                        accept="image/*"
                        id="uploadInput"
                        onChange={handleChangeFiles}
                        multiple={false}
                      />
                    </label>
                  </div>
                </form>
                <div className="uploadedImagesEdit">
                  {files.map((file, index) => (
                    <div key={index}>
                      <p>{file.name}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-edite-btn">
          <div className="edite-btn">
            <NavLink to="/back/gestion-produits">
              <button
                className="black-button"
                onClick={() => {
                  resetFormDataState();
                }}
              >
                annuler
              </button>
            </NavLink>
            <button
              className="black-button"
              onClick={ChangeImg}
              disabled={isLoading}
            >
              {isLoading ? "Chargement..." : "Valider"}
            </button>
          </div>
        </div>
        <div className="alertSuccess">{toggleMessageSec}</div>
      </div>
    </div>
  );
};

export default EditeProduct;

import axios from "axios";
import qs from "qs";
import React, { useEffect, useState } from "react";
import DetailsMessage from "./DetailsMessage";
import Popup from "../Popup";

const MessageCard = ({ idMessage }) => {
  const [message, setMesssage] = useState([]);
  const [toggleDetails, setToggleDetails] = useState(false);
  const [togglePopup, setTogglePopup] = useState(false);

  useEffect(() => {
    const getMessage = async () => {
      await axios
        .get(`https://api.artimarq.fr/back/messages/getMessage/${idMessage}`)
        .then((res) => {
          const messagesObj = Object.values(res.data);
          setMesssage(messagesObj[0]);
        });
    };
    getMessage();
  }, [idMessage]);

  const datePerser = (date) => {
    if (date) {
      const newDate = new Date(date);
      const day = newDate.getDate().toString().padStart(2, "0");
      const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
      const year = newDate.getFullYear();
      const hour = newDate.getHours().toString().padStart(2, "0");
      const minute = newDate.getMinutes().toString().padStart(2, "0");
      return `${day}/${month}/${year} - ${hour}:${minute}`;
    } else {
      return "";
    }
  };

  const deleteMessage = () => {
    const formatData = {
      idMessage,
    };

    axios
      .post(
        "https://api.artimarq.fr/back/messages/delete",
        qs.stringify(formatData),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateMessage = () => {
    const formatData = {
      idMessage,
    };

    axios
      .post(
        "https://api.artimarq.fr/back/messages/updateMessage",
        qs.stringify(formatData),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <ul className="devis-card-back messageContainer">
      <li>
        <h5>date</h5>
        <span>{datePerser(message?.dateMessage)}</span>
      </li>
      <li>
        <h5>Expéditeur</h5>
        <span>
          {message?.civility} {message?.firstName} {message?.lastName}
        </span>
        <span>{message?.organisation}</span>
      </li>
      <li>
        <h5>Sujet</h5>
        <span>{message?.subjectMessage}</span>
      </li>
      <li>
        <h5>Contacts</h5>
        <span>{message?.phoneMessage}</span>
        <span>{message?.emailMessage}</span>
      </li>
      <li className="last-item">
        <button
          className={`card-btn ${
            message.isReplyed === "1" ? "" : "unconsulted"
          }`}
          onClick={() => {
            setToggleDetails(true);
            updateMessage();
          }}
        >
          Consulter
        </button>
        <button
          className="card-btn"
          onClick={() => {
            setTogglePopup(true);
          }}
        >
          Supprimer
        </button>
      </li>
      <DetailsMessage
        toggleDetails={toggleDetails}
        setToggleDetails={setToggleDetails}
        contentMessage={message?.contentMessage}
        subjectMessage={message?.subjectMessage}
        emailMessage={message?.emailMessage}
        firstName={message?.firstName}
        lastName={message?.lastName}
      />
      <Popup
        togglePopup={togglePopup}
        setTogglePopup={setTogglePopup}
        onSubmite={deleteMessage}
        messagePopup="La suppression est irreversible, êtes-vous certain de vouloir supprimer ce message ?"
        comeFrom="messageDeletion"
      />
    </ul>
  );
};

export default MessageCard;

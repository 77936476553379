import axios from "axios";
import qs from "qs";
import React, { useEffect, useRef, useState } from "react";

const DetailsMessage = (props) => {
  let {
    toggleDetails,
    setToggleDetails,
    contentMessage,
    subjectMessage,
    emailMessage,
    firstName,
    lastName,
  } = props;

  const [message, setMessage] = useState("");
  const [mess, setMess] = useState("");

  const myRef = useRef();

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  useEffect(() => {
    let handler = (e) => {
      if (!myRef.current.contains(e.target)) {
        setToggleDetails(false);
        setMessage("");
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const SendMessage = () => {
    const formatData = {
      emailMessage: emailMessage,
      firstName: firstName,
      lastName: lastName,
      message: message,
      subjectMessage: subjectMessage,
    };

    axios
      .post(
        "https://api.artimarq.fr/back/messages/sendMessage",
        qs.stringify(formatData),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        console.log(response);
        setMess(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div
      className={`Details-message-container ${
        toggleDetails ? "active" : "inactive"
      }`}
    >
      <div className="message-content" ref={myRef}>
        <span>Sujet</span>
        <div className="subject-message">
          <p>{subjectMessage}</p>
        </div>
        <div className="up-detail-message">
          <span>Message</span>
          <p>{contentMessage}</p>
        </div>
        <div className="down-detail-message">
          <span>Reponse</span>
          <textarea
            name="message"
            cols="87"
            rows="15"
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="footer-message-page">
          <div className="btn-message-page">
            <button
              className="black-button"
              onClick={() => {
                setToggleDetails(false);
              }}
            >
              annuler
            </button>
            <button className="black-button" onClick={SendMessage}>
              Envoyer
            </button>
          </div>
        </div>
        <div className="alertSuccess">{mess}</div>
      </div>
    </div>
  );
};

export default DetailsMessage;

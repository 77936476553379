import axios from "axios";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

const NavBarBackPage = () => {
  const [newOrder, setNewOrder] = useState([]);
  const [leadsNews, setLeadsNews] = useState([]);
  const [subscribers, setSubscribers] = useState([]);

  const location = useLocation();

  const countOrder = () => {
    const pendingOrders = newOrder.filter(
      (order) => order?.isConsulted === "0"
    );
    return pendingOrders.length;
  };

  const countLeads = () => {
    const leads = leadsNews.filter((lead) => lead?.isAcceptNews === "1");
    return leads.length + subscribers.length;
  };

  const ordersCount = countOrder();
  const leadsCount = countLeads();

  useEffect(() => {
    const getOrder = () => {
      axios
        .get(`https://api.artimarq.fr/back/orders/visualisations`)
        .then((res) => {
          const infoObj = Object.values(res.data);
          setNewOrder(infoObj);
        });
    };
    getOrder();
  }, []);

  useEffect(() => {
    const getLeads = () => {
      axios
        .get(`https://api.artimarq.fr/back/leads/visualisations`)
        .then((res) => {
          const infoObj = Object.values(res.data);
          setLeadsNews(infoObj);
        });
    };
    getLeads();
  }, []);

  useEffect(() => {
    const getSubscribers = () => {
      axios
        .get(`https://api.artimarq.fr/back/newsletter/subscribers`)
        .then((res) => {
          const infoObj = Object.values(res.data);
          setSubscribers(infoObj);
        });
    };
    getSubscribers();
  }, []);

  return (
    <div>
      {(location.pathname === "/back/devis-en-cours" ||
        location.pathname === "/back/demandes-de-devis" ||
        location.pathname === "/back/creer-un-devis") && (
        <div className="section-back">
          <span>Gestion devis</span>
          <div>
            <div className="Link-nav-container">
              <NavLink
                to="/back/devis-en-cours"
                className="link-section-back"
                activeclassname="active"
              >
                Visualisation
              </NavLink>
            </div>
            <div className="Link-nav-container">
              <NavLink
                to="/back/demandes-de-devis"
                className="link-section-back"
                activeclassname="active"
              >
                {ordersCount > 0 && (
                  <div className="badgeNewOrder">{ordersCount}</div>
                )}
                Demandes
              </NavLink>
            </div>
            <div className="Link-nav-container">
              <NavLink
                to="/back/creer-un-devis"
                className="link-section-back"
                activeclassname="active"
              >
                Creer un devis
              </NavLink>
            </div>
          </div>
        </div>
      )}
      {location.pathname === "/back/messagerie" && (
        <div className="section-back">
          <span>Messagerie</span>
        </div>
      )}
      {(location.pathname === "/back/factures" ||
        location.pathname === "/back/creer-une-facture") && (
        <div className="section-back">
          <span>Factures</span>
          <div>
            <div className="Link-nav-container">
              <NavLink
                to="/back/factures"
                className="link-section-back"
                activeclassname="active"
              >
                Factures
              </NavLink>
            </div>
            <div className="Link-nav-container">
              <NavLink
                to="/back/creer-une-facture"
                className="link-section-back"
                activeclassname="active"
              >
                Creer une facture
              </NavLink>
            </div>
          </div>
        </div>
      )}
      {(location.pathname === "/back/gestion-clients" ||
        location.pathname === "/back/gestion-de-leads" ||
        location.pathname === "/back/creation-de-fiche-client" ||
        location.pathname === "/back/modification-de-fiche") && (
        <div className="section-back">
          <span>Gestion clients</span>
          <div>
            <div className="Link-nav-container">
              <NavLink
                to="/back/gestion-clients"
                className="link-section-back"
                activeclassname="active"
              >
                Clients
              </NavLink>
            </div>
            <div className="Link-nav-container">
              <NavLink
                to="/back/gestion-de-leads"
                className="link-section-back"
                activeclassname="active"
              >
                Prospects
              </NavLink>
            </div>
            <div className="Link-nav-container">
              <NavLink
                to="/back/creation-de-fiche-client"
                className="link-section-back"
                activeclassname="active"
              >
                Creer une fiche client
              </NavLink>
            </div>
          </div>
        </div>
      )}
      {(location.pathname === "/back/gestion-equipe" ||
        location.pathname === "/back/creer-un-utilisateur" ||
        location.pathname === "/back/parametre-utilisateur") && (
        <div className="section-back">
          <span>Gestion equipe</span>
          <div>
            <div className="Link-nav-container">
              <NavLink
                to="/back/gestion-equipe"
                className="link-section-back"
                activeclassname="active"
              >
                Gestion d'equipe
              </NavLink>
            </div>
            <div className="Link-nav-container">
              <NavLink
                to="/back/creer-un-utilisateur"
                className="link-section-back"
                activeclassname="active"
              >
                Creer un utilisateur
              </NavLink>
            </div>
          </div>
        </div>
      )}
      {(location.pathname === "/back/gestion-produits" ||
        location.pathname === "/back/creer-un-produit") && (
        <div className="section-back">
          <span>Gestion produits</span>
          <div>
            <div className="Link-nav-container">
              <NavLink
                to="/back/gestion-produits"
                className="link-section-back"
                activeclassname="active"
              >
                Gestion des produits
              </NavLink>
            </div>
            <div className="Link-nav-container">
              <NavLink
                to="/back/creer-un-produit"
                className="link-section-back"
                activeclassname="active"
              >
                Creer un produit
              </NavLink>
            </div>
          </div>
        </div>
      )}
      {(location.pathname === "/back/parametres" ||
        location.pathname === "/back/medias") && (
        <div className="section-back">
          <span>Paramètres</span>
          <div>
            <div className="Link-nav-container">
              <NavLink
                to="/back/parametres"
                className="link-section-back"
                activeclassname="active"
              >
                Informations
              </NavLink>
            </div>
            <div className="Link-nav-container">
              <NavLink
                to="/back/medias"
                className="link-section-back"
                activeclassname="active"
              >
                Medias
              </NavLink>
            </div>
          </div>
        </div>
      )}
      {(location.pathname === "/back/blog/articles" ||
        location.pathname === "/back/blog/redaction" ||
        location.pathname.startsWith("/back/blog/edition-article/")) && (
        <div className="section-back">
          <span>Le Blog</span>
          <div>
            <div className="Link-nav-container">
              <NavLink
                to="/back/blog/articles"
                className="link-section-back"
                activeclassname="active"
              >
                Articles
              </NavLink>
            </div>
            <div className="Link-nav-container">
              <NavLink
                to="/back/blog/redaction"
                className="link-section-back"
                activeclassname="active"
              >
                Redaction
              </NavLink>
            </div>
          </div>
        </div>
      )}
      {(location.pathname === "/back/newsletter/articles" ||
        location.pathname === "/back/newsletter/redaction" ||
        location.pathname.startsWith("/back/newsletter/edition/")) && (
        <div className="section-back">
          <span>Newsletter ({leadsCount} inscris)</span>
          <div>
            <div className="Link-nav-container">
              <NavLink
                to="/back/newsletter/articles"
                className="link-section-back"
                activeclassname="active"
              >
                Articles
              </NavLink>
            </div>
            <div className="Link-nav-container">
              <NavLink
                to="/back/newsletter/redaction"
                className="link-section-back"
                activeclassname="active"
              >
                Redaction
              </NavLink>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavBarBackPage;

import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const ParamContext = createContext(null);

export const ParamContextProvider = (props) => {
  const navigate = useNavigate();

  // website param
  const [products, setProducts] = useState([]);

  const [imgHomeCover, setImgHomeCover] = useState("");
  const [imgHomeCoverL, setImgHomeCoverL] = useState("");
  const [imgHomeCoverSM, setImgHomeCoverSM] = useState("");
  const [logo, setLogo] = useState("");
  const [homeTitle, setHomeTitle] = useState("");
  const [homeSubTitle, setHomeSubTitle] = useState("");
  const [imgOpenTags, setImgOpenTags] = useState("");

  const [heroBusCard, setHeroBusCard] = useState("");
  const [logoVar, setLogoVar] = useState("");

  const [nameCompany, setNameCompany] = useState("");
  const [statusCompany, setStatusCompany] = useState("");
  const [addressCompany, setAddressCompany] = useState("");
  const [zipCompany, setZipCompany] = useState("");
  const [cityCompany, setCityCompany] = useState("");
  const [countryCompany, setCountryCompany] = useState("");
  const [idCompany, setIdCompany] = useState("");
  const [capitalCompany, setCapitalCompany] = useState("");
  const [rcs, setRcs] = useState("");
  const [publisherCompany, setPublisherCompany] = useState("");

  const [nameContact, setNameContact] = useState("");
  const [emailContact, setEmailContact] = useState("");
  const [phoneContact, setPhoneContact] = useState("");
  const [addressContact, setAddressContact] = useState("");
  const [zipContact, setZipContact] = useState("");
  const [cityContact, setCityContact] = useState("");
  const [countryContact, setCountryContact] = useState("");

  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [linkdin, setLinkdin] = useState("");
  const [webSide, SetWebSide] = useState("");

  const [embroidryImg, setEmbroidryImg] = useState("");
  const [dtgImg, setDtgImg] = useState("");
  const [flocImg, setFlocImg] = useState("");
  const [serImg, setSerImg] = useState("");

  const [nameBlog, setNameBlog] = useState("");
  const [descBlog, setDescBlog] = useState("");
  const [heroblogL, setHeroblogL] = useState("");
  const [heroblogSM, setHeroblogSM] = useState("");
  const [imgBlogCover, setImgBlogCover] = useState("");

  const [popupMessage, setPopupMessage] = useState("");
  const [isdisplaypfopup, SetIsdisplaypfopup] = useState("");

  const baseUrl = "https://api.artimarq.fr/public/images/";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [settingsResponse, productsResponse] = await Promise.all([
          axios.get("https://api.artimarq.fr/front/settings"),
          axios.get("https://api.artimarq.fr/front/products"),
        ]);

        const settingsObj = Object.values(settingsResponse.data);
        settingsObj.forEach((setting) => {
          switch (setting.refSettings) {
            case "slide-l":
              setImgHomeCoverL(`url(${baseUrl}${setting.settingValue})`);
              break;
            case "slide-sm":
              setImgHomeCoverSM(`url(${baseUrl}${setting.settingValue})`);
              break;
            case "Logo artimarq":
              setLogo(baseUrl + setting.settingValue);
              break;
            case "facebook":
              setFacebook(setting.settingValue);
              break;
            case "instagram":
              setInstagram(setting.settingValue);
              break;
            case "tiktok":
              setTiktok(setting.settingValue);
              break;
            case "linkdin":
              setLinkdin(setting.settingValue);
              break;
            case "broderie":
              setEmbroidryImg(`url(${baseUrl}${setting.settingValue})`);
              break;
            case "dtg":
              setDtgImg(`url(${baseUrl}${setting.settingValue})`);
              break;
            case "flocage":
              setFlocImg(`url(${baseUrl}${setting.settingValue})`);
              break;
            case "serigraphie":
              setSerImg(`url(${baseUrl}${setting.settingValue})`);
              break;
            case "status company":
              setStatusCompany(setting.settingValue);
              break;
            case "adress company":
              setAddressCompany(setting.settingValue);
              break;
            case "zip company":
              setZipCompany(setting.settingValue);
              break;
            case "city company":
              setCityCompany(setting.settingValue);
              break;
            case "country Company":
              setCountryCompany(setting.settingValue);
              break;
            case "id company":
              setIdCompany(setting.settingValue);
              break;
            case "share capital":
              setCapitalCompany(setting.settingValue);
              break;
            case "publisher":
              setPublisherCompany(setting.settingValue);
              break;
            case "name company":
              setNameCompany(setting.settingValue);
              break;
            case "name contact":
              setNameContact(setting.settingValue);
              break;
            case "email contact":
              setEmailContact(setting.settingValue);
              break;
            case "phone contact":
              setPhoneContact(setting.settingValue);
              break;
            case "address contact":
              setAddressContact(setting.settingValue);
              break;
            case "zip contact":
              setZipContact(setting.settingValue);
              break;
            case "City contact":
              setCityContact(setting.settingValue);
              break;
            case "country contact":
              setCountryContact(setting.settingValue);
              break;
            case "home title":
              setHomeTitle(setting.settingValue);
              break;
            case "home sub title":
              setHomeSubTitle(setting.settingValue);
              break;
            case "rcs":
              setRcs(setting.settingValue);
              break;
            case "name blog":
              setNameBlog(setting.settingValue);
              break;
            case "desc blog":
              setDescBlog(setting.settingValue);
              break;
            case "Hero blog L":
              setHeroblogL(`url(${baseUrl}${setting.settingValue})`);
              break;
            case "Hero blog SM":
              setHeroblogSM(`url(${baseUrl}${setting.settingValue})`);
              break;
            case "Facebook img Open Tags":
              setImgOpenTags(setting.settingValue);
              break;
            case "popupMessage":
              setPopupMessage(setting.settingValue);
              break;
            case "website":
              SetWebSide(setting.settingValue);
              break;
            case "isdisplaypfopup":
              SetIsdisplaypfopup(setting.settingValue);
              break;
            case "Hero-busness-card":
              setHeroBusCard(`url(${baseUrl}${setting.settingValue}`);
              break;
            case "logo-variation":
              setLogoVar(setting.settingValue);
              break;
            default:
              break;
          }
        });

        const productsObj = Object.values(productsResponse.data);
        setProducts(productsObj);
      } catch (error) {
        console.error("Une erreur s'est produite : ", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth < 481) {
        setImgHomeCover(imgHomeCoverSM);
        setImgBlogCover(heroblogSM);
      } else {
        setImgHomeCover(imgHomeCoverL);
        setImgBlogCover(heroblogL);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [heroblogL, heroblogSM, imgHomeCoverL, imgHomeCoverSM]);

  //   admin param

  const [adminId, setAdminId] = useState(
    JSON.parse(sessionStorage.getItem("adminId")) || ""
  );

  const setCurrentAdmin = (id) => {
    setAdminId(id);
  };

  const [adminStatue, setAdminStatue] = useState(
    JSON.parse(sessionStorage.getItem("adminStatue")) || false
  );
  const [adminData, setAdminData] = useState({});

  const handleLoginAdmin = () => {
    setAdminStatue(true);
  };

  const handleLogoutAdmin = () => {
    setAdminStatue(false);
    setAdminData({});
    setAdminId("");
    navigate("/back/login");
    window.location.reload();
  };

  useEffect(() => {
    const getAdmin = async () => {
      await axios
        .get(`https://api.artimarq.fr/back/users/visualisation/${adminId}`)
        .then((res) => {
          const infoObj = Object.values(res.data);
          setAdminData(infoObj[0]);
        });
    };
    getAdmin();
    sessionStorage.setItem("adminStatue", JSON.stringify(adminStatue));
  }, [adminId, adminStatue]);

  useEffect(() => {
    sessionStorage.setItem("adminId", JSON.stringify(adminId));
  }, [adminId]);

  // blog

  const [articles, setArticles] = useState([]);
  const [articlesBack, setArticlesBack] = useState([]);

  useEffect(() => {
    const getArticles = async () => {
      await axios.get(`https://api.artimarq.fr/front/articles`).then((res) => {
        const infoObj = Object.values(res.data);
        const sortedData = infoObj.sort(
          (a, b) => new Date(b.dateArticle) - new Date(a.dateArticle)
        );
        setArticles(sortedData);
      });
    };
    getArticles();
  }, []);

  useEffect(() => {
    const getArticles = async () => {
      await axios
        .get(`https://api.artimarq.fr/back/blog/visualisations`)
        .then((res) => {
          const infoObj = Object.values(res.data);
          const sortedData = infoObj.sort(
            (a, b) => new Date(b.dateArticle) - new Date(a.dateArticle)
          );
          setArticlesBack(sortedData);
        });
    };
    getArticles();
  }, []);

  // Newsletter
  const [newsletterBack, setNewsletterBack] = useState([]);

  useEffect(() => {
    const getArticles = async () => {
      await axios
        .get(`https://api.artimarq.fr/back/newsletter/visualisations`)
        .then((res) => {
          const infoObj = Object.values(res.data);
          const sortedData = infoObj.sort(
            (a, b) => new Date(b.dateArticle) - new Date(a.dateArticle)
          );
          setNewsletterBack(sortedData);
        });
    };
    getArticles();
  }, []);
  const contextValue = {
    adminStatue,
    adminData,
    setCurrentAdmin,
    handleLoginAdmin,
    handleLogoutAdmin,
    products,
    imgHomeCover,
    imgHomeCoverL,
    imgHomeCoverSM,
    logo,
    nameCompany,
    statusCompany,
    addressCompany,
    zipCompany,
    cityCompany,
    countryCompany,
    idCompany,
    capitalCompany,
    rcs,
    publisherCompany,
    nameContact,
    emailContact,
    webSide,
    phoneContact,
    addressContact,
    zipContact,
    cityContact,
    countryContact,
    facebook,
    instagram,
    tiktok,
    linkdin,
    embroidryImg,
    dtgImg,
    flocImg,
    serImg,
    homeTitle,
    homeSubTitle,
    articles,
    nameBlog,
    descBlog,
    imgBlogCover,
    articlesBack,
    adminId,
    imgOpenTags,
    newsletterBack,
    popupMessage,
    isdisplaypfopup,
    heroBusCard,
    logoVar,
  };

  return (
    <ParamContext.Provider value={contextValue}>
      {props.children}
    </ParamContext.Provider>
  );
};

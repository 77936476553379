import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import colorsData from "../colorsData.json";
import AskDevis from "./AskDevis";
import TextilParam from "../components/TextilParam";
import ZoneParam from "../components/ZoneParam";
import TechnicsParam from "../components/TechnicsParam";
import SizesParam from "../components/SizesParam";
import { ParamContext } from "../components/context/ParamContexte";

const CustomePage = ({ setIsLoading }) => {
  let { id } = useParams();
  const baseUrl = "https://api.artimarq.fr/public/images/";

  const { nameCompany } = useContext(ParamContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [product, setProduct] = useState();
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedColor, setSelectedColor] = useState(
    JSON.parse(sessionStorage.getItem("selectedColor")) || null
  );
  const [selectedGSM, setSelectedGSM] = useState(
    JSON.parse(sessionStorage.getItem("selectedGSM")) || null
  );
  const [selectedTechnic, setSelectedTechnic] = useState(
    JSON.parse(sessionStorage.getItem("selectedTechnic")) || null
  );
  const [selectedDelays, setSelectedDelays] = useState(
    JSON.parse(sessionStorage.getItem("selectedDelays")) || null
  );
  const [selectedStyle, setSelectedStyle] = useState(
    JSON.parse(sessionStorage.getItem("selectedStyle")) || []
  );
  const [selectedZones, setSelectedZones] = useState(
    JSON.parse(sessionStorage.getItem("selectedZones")) || []
  );
  const [selectedSizes, setSelectedSizes] = useState(
    JSON.parse(sessionStorage.getItem("selectedSizes")) || {}
  );

  useEffect(() => {
    sessionStorage.setItem("selectedColor", JSON.stringify(selectedColor));
    sessionStorage.setItem("selectedGSM", JSON.stringify(selectedGSM));
    sessionStorage.setItem("selectedTechnic", JSON.stringify(selectedTechnic));
    sessionStorage.setItem("selectedDelays", JSON.stringify(selectedDelays));
    sessionStorage.setItem("selectedStyle", JSON.stringify(selectedStyle));
    sessionStorage.setItem("selectedZones", JSON.stringify(selectedZones));
    sessionStorage.setItem("selectedSizes", JSON.stringify(selectedSizes));
  }, [
    selectedColor,
    selectedDelays,
    selectedGSM,
    selectedSizes,
    selectedStyle,
    selectedTechnic,
    selectedZones,
  ]);

  const fromCustom = true;
  const category = product && product[0] ? product[0].nameCategory : null;
  const productType = product && product[0] ? product[0].productType : null;
  const nameProduct = product && product[0] ? product[0].nameProduct : null;
  const technics = product && product[0] ? product[0].technics : null;
  const isFaceActive = product && product[0] ? product[0].isFaceActive : null;
  const isBackActive = product && product[0] ? product[0].isBackActive : null;
  const isSideActive = product && product[0] ? product[0].isSideActive : null;

  const handleNext = () => {
    if (currentStep === 1) {
      setCurrentStep(2);
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  useEffect(() => {
    if (product && product[0].nameProduct) {
      document.title = `${nameCompany} | ${product[0].nameProduct} à personnaliser`;
    }
  }, [nameCompany, product]);

  const optionsDelays = ["Standard", "Express"];

  const handleOptionClick = (color) => {
    setSelectedColor(color.name);
  };

  const handleDelaysClick = (option) => {
    setSelectedDelays(option);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `https://api.artimarq.fr/front/product/${id}`
      );
      setProduct(Object.values(response.data));
    };
    fetchData();
    setIsLoading(false);
  }, [id, setIsLoading]);

  const emptyConfig = () => {
    setSelectedColor(null);
    setSelectedGSM(null);
    setSelectedTechnic(null);
    setSelectedDelays(null);
    setSelectedStyle([]);
    setSelectedZones([]);
    setSelectedSizes([]);
  };

  return (
    <div>
      {product && product[0].nameProduct && (
        <Helmet>
          <title>{`${nameCompany} | ${product[0]?.nameProduct} à personnaliser`}</title>
          <meta
            property="og:title"
            content={`${product[0]?.nameProduct} à personnaliser`}
          />
          <meta
            property="og:description"
            content={`commencez à personnaliser votre ${product[0]?.nameProduct}`}
          />
          <meta property="og:type" content="product" />
          <meta property="og:image" content={baseUrl + product[0]?.nameMedia} />
          <meta property="og:site_name" content="ARTIMARQ site officiel" />
          <meta
            name="description"
            content={`Flocage, sérigraphie, impression textile et broderie : commencez à personnaliser votre ${product[0]?.nameProduct} dès maintenant`}
          />
        </Helmet>
      )}
      {currentStep === 1 && (
        <div className="classic-page custom-page">
          <div className="left-side-custom">
            <div className="content-container-left">
              <div className="img-product-container">
                {product ? (
                  <img src={baseUrl + product[0]?.nameMedia} alt="product" />
                ) : (
                  <p>Chargement...</p>
                )}
              </div>
            </div>
          </div>
          <div className="right-side-custom">
            <div className="custom-param-container">
              <div className="head-custom-param">
                <span>Votre textile</span>
              </div>
              <div className="body-custom-param">
                <span>Couleur : {selectedColor}</span>
                <div className="color-container">
                  {colorsData.colors.map((colorObject) => (
                    <label
                      key={colorObject.id}
                      htmlFor={colorObject.id}
                      className={`colors-items ${
                        selectedColor === colorObject.name
                          ? "selected-color"
                          : ""
                      }`}
                      style={{ background: colorObject.hex }}
                    >
                      <input
                        id={colorObject.id}
                        type="radio"
                        name="radioColor"
                        value={selectedColor || ""}
                        onChange={() => handleOptionClick(colorObject)}
                      />
                    </label>
                  ))}
                </div>
              </div>
              <TextilParam
                category={category}
                nameProduct={nameProduct}
                selectedStyle={selectedStyle}
                selectedGSM={selectedGSM}
                setSelectedGSM={setSelectedGSM}
                setSelectedStyle={setSelectedStyle}
              />
            </div>
            <div className="textile-param-container">
              <div className="head-custom-param">
                <span>Zone de marquage</span>
              </div>
              <ZoneParam
                productType={productType}
                nameProduct={nameProduct}
                selectedZones={selectedZones}
                setSelectedZones={setSelectedZones}
                setIsLoading={setIsLoading}
                isFaceActive={isFaceActive}
                isBackActive={isBackActive}
                isSideActive={isSideActive}
              />
            </div>
            <div className="textile-param-container">
              <div className="head-custom-param">
                <span>Technique</span>
              </div>
              <TechnicsParam
                nameProduct={nameProduct}
                selectedTechnic={selectedTechnic}
                setSelectedTechnic={setSelectedTechnic}
                technics={technics}
              />
            </div>
            <div className="textile-param-container">
              <div className="head-custom-param">
                <span>Tailles et quantités</span>
              </div>
              <SizesParam
                category={category}
                setSelectedSizes={setSelectedSizes}
                nameProduct={nameProduct}
                selectedSizes={selectedSizes}
              />
            </div>
            <div className="textile-param-container">
              <div className="head-custom-param">
                <span>Vos delais</span>
              </div>
              <div className="body-custom-param">
                <div className="technic-container">
                  {optionsDelays.map((option) => (
                    <div key={option}>
                      <label
                        htmlFor={option}
                        className={
                          selectedDelays === option ? "selected-background" : ""
                        }
                      >
                        {option}
                      </label>
                      <input
                        id={option}
                        type="radio"
                        value={selectedDelays || ""}
                        onClick={() => handleDelaysClick(option)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="submit-devis-container">
              <button className="black-button" onClick={() => handleNext()}>
                Obtenir un devis gratuit
              </button>
            </div>
          </div>
        </div>
      )}
      {currentStep > 1 && (
        <AskDevis
          fromCustom={fromCustom}
          handleBack={handleBack}
          nameProduct={nameProduct}
          selectedColor={selectedColor}
          selectedTechnic={selectedTechnic}
          selectedDelays={selectedDelays}
          selectedZones={selectedZones}
          selectedSizes={selectedSizes}
          selectedGSM={selectedGSM}
          selectedStyle={selectedStyle}
          setSelectedColor={setSelectedColor}
          setSelectedGSM={setSelectedGSM}
          setSelectedTechnic={setSelectedTechnic}
          setSelectedDelays={setSelectedDelays}
          setSelectedStyle={setSelectedStyle}
          setSelectedZones={setSelectedZones}
          setSelectedSizes={setSelectedSizes}
          emptyConfig={emptyConfig}
        />
      )}
    </div>
  );
};

export default CustomePage;

import React, { useEffect, useState } from "react";
import NavbarBack from "../../components/back_office/NavbarBack";
import axios from "axios";
import NavBarBackPage from "../../components/back_office/NavBarBackPage";
import MessageCard from "../../components/back_office/MessageCard";

const Message = () => {
  const [getMessages, setGetMessages] = useState([]);

  useEffect(() => {
    const getMessages = async () => {
      await axios
        .get(`https://api.artimarq.fr/back/messages/getMessages`)
        .then((res) => {
          const infoObj = Object.values(res.data);
          const sortedData = infoObj.sort((a, b) => new Date(b.dateMessage) - new Date(a.dateMessage));
          setGetMessages(sortedData);
        });
    };
    getMessages();
  }, []);

  let dataToDisplay;
  if (getMessages.length > 0) {
    dataToDisplay = getMessages.map((message) => (
      <MessageCard key={message.idMessages} idMessage={message.idMessages} />
    ));
  } else {
    dataToDisplay = (
      <div className="contentEmpty">
        <h3>Vous n'avez aucune demande de devis pour l'instant.</h3>
      </div>
    );
  }
  
  return (
    <div className="body-website_back">
      <NavbarBack />
      <div className="back-page-content">
        <NavBarBackPage />
        <div>
          <div className="admin-content-page">
            <div className="devis-back-container">{dataToDisplay}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Message;

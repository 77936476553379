import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import countriesData from "../../countries.json";

const UpdateLeads = (props) => {
  let {
    toggleEdite,
    setToggleEdite,
    idLeads,
    organisationType,
    OrganisationName,
    organisationAddress,
    organisationCity,
    organisationCountry,
    organisationZip,
    civilityLead,
    firstNameLeads,
    lastNameLeads,
    emailLeads,
    phoneLeads,
    statutLead,
  } = props;

  const myRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!myRef.current.contains(e.target)) {
        setToggleEdite(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const [isLoading, setIsLoading] = useState(false);
  const [formDataState, setFormDataState] = useState({
    radioOrga: organisationType,
    orgaName: OrganisationName,
    Adresse: organisationAddress,
    country: organisationCountry,
    zip: organisationZip,
    city: organisationCity,
    civility: civilityLead,
    lastname: lastNameLeads,
    firstName: firstNameLeads,
    email: emailLeads,
    phone: phoneLeads,
    statut: statutLead,
  });

  const resetFormDataState = () => {
    setFormDataState({
      radioOrga: organisationType || "",
      orgaName: OrganisationName || "",
      Adresse: organisationAddress || "",
      country: organisationCountry || "",
      zip: organisationZip || "",
      city: organisationCity || "",
      civility: civilityLead || "",
      lastname: lastNameLeads || "",
      firstName: firstNameLeads || "",
      email: emailLeads || "",
      phone: phoneLeads || "",
      statut: statutLead || "",
    });
  };

  const options = [
    "Association",
    "Collectivité",
    "Entreprise",
    "Marque",
    "Particulier",
  ];

  const handleChange = (value, name) => {
    setFormDataState((prevState) => ({ ...prevState, [name]: value }));
  };

  const CreateDevis = async () => {
    setIsLoading(true);

    const formatData = {
      idLeads,
      civility: formDataState.civility,
      fisrtName: formDataState.firstName,
      lastName: formDataState.lastname,
      organisationType: formDataState.radioOrga,
      organisation: formDataState.orgaName,
      address: formDataState.Adresse,
      zip: formDataState.zip,
      city: formDataState.city,
      country: formDataState.country,
      email: formDataState.email,
      phone: formDataState.phone,
      statut: formDataState.statut,
    };

    try {
      await axios.post("https://api.artimarq.fr/back/leads/edite", formatData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      // console.log(response);
      resetFormDataState();
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`bg-edite-customer ${toggleEdite ? "active" : "inactive"}`}>
      <div ref={myRef} className="edite-customer-page">
        <div className="input-devis-up-container admin-content-page">
          <div className="organisation-infos">
            <div className="body-ask-devis">
              <div className="input-devis-container">
                <label>Type d'organisation</label>
                <select
                  name="radioOrga"
                  value={formDataState.radioOrga || organisationType}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                >
                  {options.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="input-devis-bloc">
            <div className="section-input-devis">
              <div className="input-devis-container">
                <label htmlFor="orgaName">Organisation</label>
                <input
                  id="orgaName"
                  name="orgaName"
                  defaultValue={formDataState.orgaName || OrganisationName}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
              <div className="input-devis-container">
                <label htmlFor="Adresse">Adresse *</label>
                <input
                  name="Adresse"
                  id="Adresse"
                  defaultValue={formDataState.Adresse || organisationAddress}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
            </div>
            <div className="section-input-devis">
              <div className="input-devis-container">
                <label>Pays *</label>
                <select
                  name="country"
                  id="country"
                  value={formDataState.country || organisationCountry}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                >
                  {countriesData.map((country) => (
                    <option key={country.code} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-devis-container">
                <label htmlFor="zip">Code postal *</label>
                <input
                  name="zip"
                  id="zip"
                  defaultValue={formDataState.zip || organisationZip}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
              <div className="input-devis-container">
                <label htmlFor="city">Ville *</label>
                <input
                  name="city"
                  id="city"
                  defaultValue={formDataState.city || organisationCity}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="input-devis-bloc">
            <div className="input-devis-container">
              <label>Civilité</label>
              <select
                name="civility"
                value={formDataState.civility || civilityLead}
                onChange={(e) => {
                  handleChange(e.target.value, e.target.name);
                }}
              >
                <option value="M">Monsieur</option>
                <option value="Mme">Madame</option>
              </select>
            </div>

            <div className="section-input-devis">
              <div className="input-devis-container">
                <label htmlFor="lastname">Nom *</label>
                <input
                  name="lastname"
                  id="lastname"
                  defaultValue={formDataState.lastname || lastNameLeads}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
              <div className="input-devis-container">
                <label htmlFor="firstName">Prenom *</label>
                <input
                  id="firstName"
                  name="firstName"
                  defaultValue={formDataState.firstName || firstNameLeads}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
            </div>
            <div className="section-input-devis">
              <div className="input-devis-container">
                <label htmlFor="email">Email *</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  defaultValue={formDataState.email || emailLeads}
                  onChange={(e) => {
                    handleChange(e.target.value.toUpperCase(), e.target.name);
                  }}
                />
              </div>
              <div className="input-devis-container">
                <label htmlFor="phone">Telephone *</label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  defaultValue={formDataState.phone || phoneLeads}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="input-devis-container">
            <label>Statut</label>
            <select
              name="statut"
              value={formDataState.statut || statutLead}
              onChange={(e) => {
                handleChange(e.target.value, e.target.name);
              }}
            >
              <option value="Leads">Prospect</option>
              <option value="Customer">Client</option>
            </select>
          </div>
        </div>
        <div className="footer-devis-page">
          <div className="btn-devis-page">
            <button
              className="black-button"
              onClick={() => {
                resetFormDataState();
                setToggleEdite(false);
              }}
            >
              annuler
            </button>
            <button
              className="black-button"
              onClick={CreateDevis}
              disabled={isLoading}
            >
              {isLoading ? "Chargement..." : "Créer"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateLeads;

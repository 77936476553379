import React, { useState } from "react";
import axios from "axios";
import countriesData from "../../countries.json";
import NavbarBack from "../../components/back_office/NavbarBack";
import NavBarBackPage from "../../components/back_office/NavBarBackPage";

const CreatUser = () => {
  const [messageSupp, setMessageSupp] = useState("");
  const [warning, setWarning] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formDataState, setFormDataState] = useState({
    lastname: "",
    firstName: "",
    email: "",
    phone: "",
    Adresse: "",
    addressComp: "",
    zip: "",
    city: "",
    country: "France",
    statut: "",
    login: "",
    password: "",
  });

  const resetFormDataState = () => {
    setFormDataState({
      lastname: "",
      firstName: "",
      email: "",
      phone: "",
      Adresse: "",
      addressComp: "",
      zip: "",
      city: "",
      country: "France",
      statut: "false",
      login: "",
      password: "",
    });
  };

  const handleChange = (value, name) => {
    setFormDataState((prevState) => ({ ...prevState, [name]: value }));
  };

  const CreateDevis = async () => {
    setIsLoading(true);

    const {
      lastname,
      firstName,
      email,
      phone,
      Adresse,
      zip,
      city,
      country,
      statut,
      login,
      password,
    } = formDataState;

    if (
      !lastname &&
      !firstName &&
      !email &&
      !phone &&
      !Adresse &&
      !zip &&
      !city &&
      !country &&
      !statut &&
      !login &&
      !password
    ) {
      setIsLoading(false);
      setMessageSupp("");
      setWarning("Veuillez compléter tous les champs obligatoirs");
      return;
    }

    const formatData = {
      lastName: formDataState.lastname,
      fisrtName: formDataState.firstName,
      email: formDataState.email,
      phone: formDataState.phone,
      address: formDataState.Adresse,
      addressComp: formDataState.addressComp,
      zip: formDataState.zip,
      city: formDataState.city,
      country: formDataState.country,
      statut: formDataState.statut,
      login: formDataState.login,
      password: formDataState.password,
    };

    try {
      const response = await axios.post(
        "https://api.artimarq.fr/back/users/create",
        formatData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      console.log(response);
      setMessageSupp(response.data.message);
      if (response.data.success === true) {
        resetFormDataState();
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="body-website_back">
      <NavbarBack />
      <div className="back-page-content">
        <NavBarBackPage />
        <div>
          <div>
            <div className="input-devis-up-container admin-content-page">
              <div className="input-devis-bloc">
                <div className="section-input-devis">
                  <div className="input-devis-container">
                    <label htmlFor="firstName">Nom *</label>
                    <input
                      id="firstName"
                      name="firstName"
                      value={formDataState.firstName}
                      onChange={(e) => {
                        handleChange(e.target.value, e.target.name);
                      }}
                    />
                  </div>
                  <div className="input-devis-container">
                    <label htmlFor="lastname">Prenom *</label>
                    <input
                      name="lastname"
                      id="lastname"
                      value={formDataState.lastname}
                      onChange={(e) => {
                        handleChange(e.target.value, e.target.name);
                      }}
                    />
                  </div>
                </div>
                <div className="section-input-devis">
                  <div className="input-devis-container">
                    <label htmlFor="email">Email *</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formDataState.email}
                      onChange={(e) => {
                        handleChange(
                          e.target.value.toUpperCase(),
                          e.target.name
                        );
                      }}
                    />
                  </div>
                  <div className="input-devis-container">
                    <label htmlFor="phone">Telephone *</label>
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      value={formDataState.phone}
                      onChange={(e) => {
                        handleChange(e.target.value, e.target.name);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="input-devis-bloc">
                <div className="section-input-devis">
                  <div className="input-devis-container">
                    <label htmlFor="Adresse">Adresse *</label>
                    <input
                      name="Adresse"
                      id="Adresse"
                      value={formDataState.Adresse}
                      onChange={(e) => {
                        handleChange(e.target.value, e.target.name);
                      }}
                    />
                  </div>
                  <div className="input-devis-container">
                    <label htmlFor="addressComp">Complement d'adresse</label>
                    <input
                      id="addressComp"
                      name="addressComp"
                      value={formDataState.addressComp}
                      onChange={(e) => {
                        handleChange(e.target.value, e.target.name);
                      }}
                    />
                  </div>
                </div>
                <div className="section-input-devis">
                  <div className="input-devis-container">
                    <label>Pays *</label>
                    <select
                      name="country"
                      id="country"
                      value={formDataState.country}
                      onChange={(e) => {
                        handleChange(e.target.value, e.target.name);
                      }}
                    >
                      {countriesData.map((country) => (
                        <option key={country.code} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="input-devis-container">
                    <label htmlFor="zip">Code postal *</label>
                    <input
                      name="zip"
                      id="zip"
                      value={formDataState.zip}
                      onChange={(e) => {
                        handleChange(e.target.value, e.target.name);
                      }}
                    />
                  </div>
                  <div className="input-devis-container">
                    <label htmlFor="city">Ville *</label>
                    <input
                      name="city"
                      id="city"
                      value={formDataState.city}
                      onChange={(e) => {
                        handleChange(e.target.value, e.target.name);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="section-input-devis">
                <div className="input-devis-container">
                  <label>Status *</label>
                  <select
                    name="statut"
                    id="statut"
                    value={formDataState.statut}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  >
                    <option value={false}>Collaborateur</option>
                    <option value={true}>Administrateur</option>
                  </select>
                </div>
              </div>
              <div className="input-devis-bloc">
                <div className="section-input-devis">
                  <div className="input-devis-container">
                    <label htmlFor="login">Identifiant</label>
                    <input
                      name="login"
                      id="login"
                      value={formDataState.login}
                      onChange={(e) => {
                        handleChange(e.target.value, e.target.name);
                      }}
                    />
                  </div>
                  <div className="input-devis-container">
                    <label htmlFor="password">Mot de passe</label>
                    <input
                      id="password"
                      name="password"
                      value={formDataState.password}
                      onChange={(e) => {
                        handleChange(e.target.value, e.target.name);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-devis-page">
              <div className="btn-devis-page">
                <button className="black-button" onClick={resetFormDataState}>
                  annuler
                </button>
                <button
                  className="black-button"
                  onClick={CreateDevis}
                  disabled={isLoading}
                >
                  {isLoading ? "Chargement..." : "Créer"}
                </button>
              </div>
            </div>
            <div className="alertSuccess">{warning}</div>
            <div className="alertSuccess">{messageSupp}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatUser;

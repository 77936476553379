import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ParamContext } from "../components/context/ParamContexte";

const Technics = () => {
  const { embroidryImg, dtgImg, flocImg, serImg, nameCompany, imgOpenTags } =
    useContext(ParamContext);
  const baseUrl = "https://api.artimarq.fr/public/images/";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [section, setSection] = useState("FLOCAGE");
  const [imgHero, setImgHero] = useState(flocImg);

  const handleSection = (option) => {
    setSection(option.tech);
    setImgHero(option.img);
  };

  return (
    <div>
      <Helmet>
        <title>{`${nameCompany} | Nos techniques de personnalisations`}</title>
        <meta
          property="og:title"
          content="Nos techniques de personnalisations"
        />
        <meta
          property="og:description"
          content="Découvrez nos techniques de personnalisation : flocage, sérigraphie, impression textile & broderie."
        />
        <meta property="og:type" content="service" />
        <meta property="og:image" content={baseUrl + imgOpenTags} />
        <meta property="og:url" content="https://artimarq.fr/marquages" />
        <meta property="og:site_name" content="ARTIMARQ site officiel" />
        <meta
          name="description"
          content="Découvrez nos techniques de personnalisation : flocage, sérigraphie, impression textile & broderie. Personnalisez vos vêtements selon vos besoins."
        />
        <link rel="canonical" href={`https://artimarq.fr/marquages`} />
      </Helmet>
      <div
        className="marq-explain-container"
        style={{ backgroundImage: imgHero }}
        alt="illustration"
      ></div>
      <div className="section-marq-title">
        <ul>
          <li
            className={section === "FLOCAGE" ? "selected-title" : ""}
            onClick={() => {
              handleSection({ tech: "FLOCAGE", img: flocImg });
            }}
          >
            FLOCAGE
          </li>
        </ul>
        <ul>
          <li
            className={section === "SERIGRAPHIE" ? "selected-title" : ""}
            onClick={() => handleSection({ tech: "SERIGRAPHIE", img: serImg })}
          >
            SERIGRAPHIE
          </li>
        </ul>
        <ul>
          <li
            className={
              section === "IMPRESSION NUMERIQUES" ? "selected-title" : ""
            }
            onClick={() =>
              handleSection({ tech: "IMPRESSION NUMERIQUE", img: dtgImg })
            }
          >
            IMPRESSION NUMERIQUE
          </li>
        </ul>
        <ul>
          <li
            className={section === "BRODERIE" ? "selected-tech" : ""}
            onClick={() =>
              handleSection({ tech: "BRODERIE", img: embroidryImg })
            }
          >
            BRODERIE
          </li>
        </ul>
      </div>
      <div className=" classic-page">
        {section === "FLOCAGE" ? (
          <div className="body-marq-page">
            <h2>Flocage</h2>
            <p>
              Le flocage est une technique d'application de motifs ou de textes
              utilisant des fibres synthétiques. Cette méthode consiste à
              découper des formes ou des lettres dans un matériau appelé flex.
              Ensuite, ces morceaux de flex sont fixés sur le tissu, créant
              ainsi un revêtement velouté et texturé.Le flocage offre une
              finition douce et élégante, et il est couramment utilisé pour
              personnaliser des vêtements, des maillots de sport, des casquettes
              et d'autres articles textiles. Il permet de créer des designs
              hautement visibles, durables et de grande qualité, offrant ainsi
              une personnalisation esthétique et unique à vos produits.
            </p>
          </div>
        ) : section === "SERIGRAPHIE" ? (
          <div className="body-marq-page">
            <h2>Serigraphie</h2>
            <p>
              La sérigraphie est une technique d'impression qui utilise un écran
              finement tissé pour transférer de l'encre sur différents supports.
              C'est un procédé polyvalent qui permet de créer des impressions
              durables et de haute qualité avec une grande précision. La
              sérigraphie est largement utilisée dans l'industrie de la
              publicité, de la mode, de la signalisation et de l'art pour
              produire des designs personnalisés, des motifs détaillés et des
              couleurs vives. Celle ci est a privilégé pour les grandes series.{" "}
            </p>
          </div>
        ) : section === "IMPRESSION NUMERIQUE" ? (
          <div className="body-marq-page">
            <div>
              <h2>IMPRESSION NUMERIQUE</h2>
              <p>
                L'impression DTF (Direct To Film) est une méthode d'impression
                numérique qui vous permet de donner vie à vos idées créatives en
                imprimant des motifs personnalisés sur une grande variété de
                textiles. Grâce à cette technique, vous pouvez obtenir des
                impressions de haute qualité, avec des couleurs éclatantes et
                des détails nets, pour créer des vêtements, des accessoires et
                d'autres articles textiles uniques. Que vous ayez besoin de
                personnaliser un seul article ou de créer une petite série de
                produits, l'impression DTF offre une solution polyvalente pour
                vos besoins d'impression, assurant une durabilité et une
                résistance au lavage exceptionnelles. Exprimez-vous avec des
                designs qui se démarquent grâce à cette technique innovante
                d'impression numérique sur textile.
              </p>
            </div>
          </div>
        ) : section === "BRODERIE" ? (
          <div className="body-marq-page">
            <h2>Broderie</h2>
            <p>
              La broderie est un art ancien et méticuleux qui consiste à
              embellir un tissu en y ajoutant des motifs décoratifs à l'aide de
              fils colorés. Cette technique consiste à utiliser une aiguille et
              du fil pour créer des points de couture sur le tissu, formant
              ainsi des motifs, des lettres ou des dessins complexes. La
              broderie offre une grande variété de styles, de techniques et de
              possibilités créatives, permettant de personnaliser des vêtements,
              des accessoires, des articles de maison et bien d'autres. C'est un
              moyen élégant et intemporel d'ajouter une touche artistique et
              unique à vos créations.
            </p>
          </div>
        ) : (
          <div className="body-marq-page">
            <h2>Flocage</h2>
            <p>
              Le flocage est une technique d'application de motifs ou de textes
              utilisant des fibres synthétiques. Cette méthode consiste à
              découper des formes ou des lettres dans un matériau appelé flex.
              Ensuite, ces morceaux de flex sont fixés sur le tissu, créant
              ainsi un revêtement velouté et texturé.Le flocage offre une
              finition douce et élégante, et il est couramment utilisé pour
              personnaliser des vêtements, des maillots de sport, des casquettes
              et d'autres articles textiles. Il permet de créer des designs
              hautement visibles, durables et de grande qualité, offrant ainsi
              une personnalisation esthétique et unique à vos produits.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Technics;

import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import countriesData from "../../countries.json";

const EditeUser = (props) => {
  let {
    toggleEdtiteUser,
    setToggleEdtiteUser,
    idUser,
    firstNameUser,
    lastNameUser,
    emailUser,
    phoneUser,
    address,
    addressCom,
    city,
    zip,
    country,
    login,
    statutUser,
  } = props;

  const myRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!myRef.current.contains(e.target)) {
        setToggleEdtiteUser(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const [rank, setRank] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formDataState, setFormDataState] = useState({
    firstName: firstNameUser,
    lastName: lastNameUser,
    email: emailUser,
    phone: phoneUser,
    address: address,
    addressCom: addressCom,
    city: city,
    zip: zip,
    country: country,
    login: login,
    password: "",
    statut: statutUser,
  });

  const resetFormDataState = () => {
    setFormDataState({
      firstName: firstNameUser || "",
      lastName: lastNameUser || "",
      email: emailUser || "",
      phone: phoneUser || "",
      address: address || "",
      addressCom: addressCom || "",
      city: city || "",
      zip: zip || "",
      country: country || "",
      login: login || "",
      password: "",
      statut: statutUser || "",
    });
  };

  useEffect(() => {
    if (statutUser === "1") {
      setRank("1");
    } else {
      setRank(false);
    }
  }, [statutUser]);

  const handleChange = (value, name) => {
    setFormDataState((prevState) => ({ ...prevState, [name]: value }));
  };

  const CreateDevis = async () => {
    setIsLoading(true);

    const formatData = {
      idUser,
      fisrtName: formDataState.firstName,
      lastName: formDataState.lastName,
      email: formDataState.email,
      phone: formDataState.phone,
      address: formDataState.address,
      addressCom: formDataState.addressCom,
      city: formDataState.city,
      zip: formDataState.zip,
      country: formDataState.country,
      login: formDataState.login,
      password: formDataState.password,
      statutUser: formDataState.statut,
    };

    try {
      const response = await axios.post(
        "https://api.artimarq.fr/back/users/edite",
        formatData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      // console.log(response);
      resetFormDataState();
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={`bg-edite-user ${toggleEdtiteUser ? "active" : "inactive"}`}
    >
      <div ref={myRef} className="edite-user-page">
        <div className="input-devis-up-container admin-content-page">
          <div className="input-devis-bloc">
            <div className="section-input-devis">
              <div className="input-devis-container">
                <label htmlFor="lastName">Nom</label>
                <input
                  id="lastName"
                  name="lastName"
                  defaultValue={formDataState.lastName || lastNameUser}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
              <div className="input-devis-container">
                <label htmlFor="firstName">Prenom</label>
                <input
                  name="firstName"
                  id="firstName"
                  defaultValue={formDataState.firstName || firstNameUser}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
            </div>
            <div className="section-input-devis">
              <div className="input-devis-container">
                <label htmlFor="phone">Telephone</label>
                <input
                  name="phone"
                  id="phone"
                  defaultValue={formDataState.phone || phoneUser}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
              <div className="input-devis-container">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  defaultValue={formDataState.email || emailUser}
                  onChange={(e) => {
                    handleChange(e.target.value.toUpperCase(), e.target.name);
                  }}
                />
              </div>
            </div>
            <div className="section-input-devis">
              <div className="input-devis-container">
                <label htmlFor="login">Identifiant</label>
                <input
                  type="text"
                  id="login"
                  name="login"
                  defaultValue={formDataState.login || login}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
              <div className="input-devis-container">
                <label htmlFor="password">Mot de passe</label>
                <input
                  type="text"
                  id="password"
                  name="password"
                  defaultValue={formDataState.password}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="input-devis-bloc">
            <div className="section-input-devis">
              <div className="input-devis-container">
                <label htmlFor="address">Adresse</label>
                <input
                  name="address"
                  id="address"
                  defaultValue={formDataState.address || address}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
              <div className="input-devis-container">
                <label htmlFor="addressCom">Complement d'adresse</label>
                <input
                  name="addressCom"
                  id="addressCom"
                  defaultValue={formDataState.addressCom || addressCom}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
            </div>
            <div className="section-input-devis">
              <div className="input-devis-container">
                <label htmlFor="city">Ville</label>
                <input
                  name="city"
                  id="city"
                  defaultValue={formDataState.city || city}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
              <div className="input-devis-container">
                <label htmlFor="zip">Code postal</label>
                <input
                  name="zip"
                  id="zip"
                  defaultValue={formDataState.zip || zip}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                />
              </div>
              <div className="input-devis-container">
                <label>Pays</label>
                <select
                  name="country"
                  id="country"
                  value={formDataState.country || country}
                  onChange={(e) => {
                    handleChange(e.target.value, e.target.name);
                  }}
                >
                  {countriesData.map((country) => (
                    <option key={country.code} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="input-devis-container">
            <label>Statut</label>
            <select
              name="statut"
              value={formDataState.statut || rank}
              onChange={(e) => {
                handleChange(e.target.value, e.target.name);
              }}
            >
              <option value="1">Administrateur</option>
              <option value={false}>Collaborateur</option>
            </select>
          </div>
        </div>
        <div className="footer-devis-page">
          <div className="btn-devis-page">
            <button
              className="black-button"
              onClick={() => {
                resetFormDataState();
                setToggleEdtiteUser(false);
              }}
            >
              annuler
            </button>
            <button
              className="black-button"
              onClick={CreateDevis}
              disabled={isLoading}
            >
              {isLoading ? "Chargement..." : "Créer"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditeUser;

import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import qs from "qs";
import Popup from "../Popup";
import { ParamContext } from "../context/ParamContexte";

const BillCard = (props) => {
  let { idBill } = props;

  const { adminData } = useContext(ParamContext);

  const [bill, setBill] = useState([]);
  const [togglePopup, setTogglePopup] = useState(false);
  const [messagePopup, setMessagePopup] = useState("");
  const [comeFrom, setComeFrom] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRel, setIsLoadingRel] = useState(false);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);

  useEffect(() => {
    const getBill = async () => {
      await axios
        .get(`https://api.artimarq.fr/back/bills/visualisation/${idBill}`)
        .then((res) => {
          const billObj = Object.values(res.data);
          setBill(billObj[0]);
        });
    };
    getBill();
  }, [idBill]);

  const datePerser = (date) => {
    if (date) {
      const newDate = new Date(date);
      const day = newDate.getDate().toString().padStart(2, "0");
      const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
      const year = newDate.getFullYear();
      return `${day}/${month}/${year}`;
    } else {
      return "";
    }
  };

  const deleteBill = () => {
    const formatData = {
      idBill,
      nameFile: bill?.nameFile,
    };

    axios
      .post(
        "https://api.artimarq.fr/back/bills/delete",
        qs.stringify(formatData),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendDevis = () => {
    setIsLoading(true);

    const formatData = {
      idBill: bill?.idBill,
      firstName: bill?.firstNameBill,
      lastName: bill?.lastNameBill,
      email: bill?.emailBill,
      Advance: bill?.Advance,
    };

    axios
      .post(
        "https://api.artimarq.fr/back/bills/send",
        qs.stringify(formatData),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        setIsLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const RelBill = () => {
    setIsLoadingRel(true);

    const formatData = {
      idBill: bill?.idBill,
      firstName: bill?.firstNameBill,
      lastName: bill?.lastNameBill,
      email: bill?.emailBill,
      dateBill: bill.dateBill,
      isUnpaid: true,
    };

    axios
      .post(
        "https://api.artimarq.fr/back/bills/send",
        qs.stringify(formatData),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        setIsLoadingRel(false);
        if (response.data === true) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoadingRel(false);
      });
  };

  const cancelBill = () => {
    setIsLoadingCancel(true);
    const formatData = {
      idBill,
    };

    axios
      .post(
        "https://api.artimarq.fr/back/bills/cancel",
        qs.stringify(formatData),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        console.log(response);
        window.location.reload();
        // setIsLoadingCancel(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoadingCancel(false);
      });
  };

  const openPdf = () => {
    window.open(
      "https://api.artimarq.fr/public/bills/" + bill?.nameFile,
      "_blank"
    );
  };

  const btnSend = bill?.isShared === "1" ? "Envoyer à nouveau" : "Envoyer";
  const btnCancel =
    bill?.isCanceled === "1" ? "Restaurer la facture" : "Annuler la facture";

  let billingFunc = null;

  if (comeFrom === "BillDeletion") {
    billingFunc = deleteBill;
  } else if (comeFrom === "sendBill") {
    billingFunc = sendDevis;
  } else if (comeFrom === "cancelBill") {
    billingFunc = cancelBill;
  } else if (comeFrom === "BillRel") {
    billingFunc = RelBill;
  }

  return (
    <ul className="devis-card-back">
      <li>
        <h5>Client</h5>
        <span>
          {bill?.firstNameBill} {bill?.lastNameBill}
        </span>
      </li>
      <li>
        <h5>Organisation</h5>
        <span>{bill?.organisationBill}</span>
      </li>
      <li>
        <h5>N Facture</h5>
        <span>{bill.idBill}</span>
      </li>
      <li>
        <h5>Date</h5>
        <span
          style={bill.isCanceled === "0" ? { color: "" } : { color: "red" }}
        >
          {bill.isCanceled === "1" ? "Annulée" : datePerser(bill.dateBill)}
        </span>
      </li>
      <li className="last-item">
        <button className="card-btn" onClick={openPdf}>
          Consulter
        </button>
        <button
          className={`card-btn ${bill?.isCanceled === "1" ? "inactive" : ""}`}
          onClick={() => {
            setTogglePopup(true);
            setMessagePopup(
              "En confirmant l'envoie, la facture sera immédiatement envoyé au client !"
            );
            setComeFrom("sendBill");
          }}
          disabled={isLoading || bill?.isCanceled === "1"}
        >
          {isLoading ? "..." : btnSend}
        </button>
        <button
          className={`card-btn ${bill?.isCanceled === "1" ? "inactive" : ""}`}
          disabled={bill?.isCanceled === "1"}
          onClick={() => {
            setTogglePopup(true);
            setMessagePopup(
              "En confirmant l'envoie, la relance sera immédiatement envoyé au client !"
            );
            setComeFrom("BillRel");
          }}
        >
          {isLoadingRel ? "..." : "Relancer"}
        </button>
        {adminData.adminAcces === "1" && (
          <button
            className="card-btn"
            onClick={() => {
              setTogglePopup(true);
              setMessagePopup("Confirmer vous cette action ?");
              setComeFrom("cancelBill");
            }}
            disabled={isLoadingCancel}
          >
            {isLoadingCancel ? "..." : btnCancel}
          </button>
        )}
        {/* <button
          className="card-btn"
          onClick={() => {
            setTogglePopup(true);
            setMessagePopup("La suppression est irreversible, êtes-vous certain de vouloir supprimer cette facture ?");
            setComeFrom("BillDeletion");
          }}
        >
          Supprimer
        </button> */}
      </li>
      <Popup
        togglePopup={togglePopup}
        setTogglePopup={setTogglePopup}
        onSubmite={billingFunc}
        messagePopup={messagePopup}
        comeFrom={comeFrom}
      />
    </ul>
  );
};

export default BillCard;

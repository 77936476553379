import React, { useState } from "react";
import axios from "axios";
import Popup from "../../components/Popup";
import NavbarBack from "../../components/back_office/NavbarBack";
import NavBarBackPage from "../../components/back_office/NavBarBackPage";

const CreateProduct = () => {
  const [togglePopup, setTogglePopup] = useState(false);
  const [togglemessage, setToggleMessage] = useState("");
  const [warning, setWarning] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [files, setFiles] = useState([]);
  const [formDataState, setFormDataState] = useState({
    displayHomePage: false,
    genderProduct: "Hommes",
    isBackActive: false,
    isFaceActive: "1",
    isSideActive: false,
    nameCategory: "T-shirts",
    nameProduct: "",
    productType: "T-shirts",
  });

  const resetFormDataState = () => {
    setFormDataState({
      displayHomePage: false,
      genderProduct: "Hommes",
      isBackActive: false,
      isFaceActive: "1",
      isSideActive: false,
      nameCategory: "T-shirts",
      nameProduct: "",
      productType: "T-shirts",
    });
  };

  const options = [
    { id: 1, name: "Serigraphie" },
    { id: 2, name: "Flocage" },
    { id: 3, name: "Impression" },
    { id: 4, name: "Broderie" },
  ];

  const handleChange = (value, name) => {
    setFormDataState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeFiles = (event) => {
    const newFiles = event.target.files;
    setFiles([...newFiles]);
  };

  const handleOptionClick = (zoneName) => {
    if (selectedOptions.includes(zoneName)) {
      setSelectedOptions(selectedOptions.filter((zone) => zone !== zoneName));
    } else {
      setSelectedOptions([...selectedOptions, zoneName]);
    }
  };

  const CreateProduct = async () => {
    setIsLoading(true);

    const { genderProduct, nameProduct, productType } = formDataState;

    if (!genderProduct && !nameProduct && !productType && !files) {
      setIsLoading(false);
      setToggleMessage("");
      setWarning("Veuillez remplire tous les champs obligatoirs");
      return;
    }

    const filesData = new FormData();
    for (let i = 0; i < files.length; i++) {
      filesData.append("file[]", files[i]);
    }

    try {
      const responseUpload = await axios.post(
        "https://api.artimarq.fr/back/images/upload",
        filesData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const formatData = {
        idMedia: responseUpload.data,
        displayHomePage: formDataState.displayHomePage,
        genderProduct: formDataState.genderProduct,
        isBackActive: formDataState.isBackActive,
        isFaceActive: formDataState.isFaceActive,
        isSideActive: formDataState.isSideActive,
        nameCategory: formDataState.nameCategory,
        nameProduct: formDataState.nameProduct,
        productType: formDataState.productType,
        selectedOptions,
      };

      const responseCreateProduct = await axios.post(
        "https://api.artimarq.fr/back/products/create",
        formatData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      // console.log(responseCreateProduct);
      setWarning("");
      resetFormDataState();
      setSelectedOptions([]);
      setFiles([]);
      setToggleMessage(responseCreateProduct.data.message);
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="body-website_back">
      <NavbarBack />
      <div className="back-page-content">
        <NavBarBackPage />
        <div>
          <div className="input-devis-up-container admin-content-page">
            <div className="input-devis-bloc">
              <div className="section-input-devis">
                <div className="input-devis-container">
                  <label>Genre</label>
                  <select
                    name="genderProduct"
                    id="genderProduct"
                    value={formDataState.genderProduct}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  >
                    <option value="Hommes">Hommes</option>
                    <option value="Femmes">Femmes</option>
                    <option value="Unisexe">Unisexe</option>
                  </select>
                </div>
                <div className="input-devis-container">
                  <label>Categorie</label>
                  <select
                    name="nameCategory"
                    id="nameCategory"
                    value={formDataState.nameCategory}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  >
                    <option value="T-shirts">T-shirts</option>
                    <option value="Polos">Polos</option>
                    <option value="Chemises">Chemises</option>
                    <option value="Hoodies">Hoodies</option>
                    <option value="Sweat-shirts">Sweat-shirts</option>
                    <option value="Pantalons">Pantalons</option>
                    <option value="Shorts">Shorts</option>
                    <option value="Accessoires">Accessoires</option>
                    <option value="Autres produits">Autres produits</option>
                    <option value="Patchs">Ecussons</option>
                  </select>
                </div>
                <div className="input-devis-container">
                  <label>Afficher en page d'acceuil</label>
                  <select
                    name="displayHomePage"
                    id="displayHomePage"
                    value={formDataState.displayHomePage}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  >
                    <option value="1">Oui</option>
                    <option value={false}>Non</option>
                  </select>
                </div>
              </div>
              <div className="section-input-devis">
                <div className="input-devis-container">
                  <label>Imprimable de face ?</label>
                  <select
                    name="isFaceActive"
                    id="isFaceActive"
                    value={formDataState.isFaceActive}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  >
                    <option value="1">Oui</option>
                    <option value={false}>Non</option>
                  </select>
                </div>
                <div className="input-devis-container">
                  <label>Imprimable de dos ?</label>
                  <select
                    name="isBackActive"
                    id="isBackActive"
                    value={formDataState.isBackActive}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  >
                    <option value="1">Oui</option>
                    <option value={false}>Non</option>
                  </select>
                </div>
                <div className="input-devis-container">
                  <label>Imprimable de coté ?</label>
                  <select
                    name="isSideActive"
                    id="isSideActive"
                    value={formDataState.isSideActive}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  >
                    <option value="1">Oui</option>
                    <option value={false}>Non</option>
                  </select>
                </div>
              </div>
              <div className="section-input-devis">
                <div className="input-devis-container">
                  <label htmlFor="nameProduct">Nom du produit</label>
                  <input
                    name="nameProduct"
                    id="nameProduct"
                    defaultValue={formDataState.nameProduct}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
                <div className="input-devis-container">
                  <label>Type de produit</label>
                  <select
                    name="productType"
                    id="productType"
                    value={formDataState.productType}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  >
                    <option value="T-shirts">T-shirts</option>
                    <option value="Polos">Polos</option>
                    <option value="Chemises">Chemises</option>
                    <option value="Hoodies">Hoodies</option>
                    <option value="Sweat-shirts">Sweat-shirts</option>
                    <option value="Pantalons">Pantalons</option>
                    <option value="Shorts">Shorts</option>
                    <option value="Casquettes">Casquettes</option>
                    <option value="Débardeurs">Débardeurs</option>
                    <option value="Bobs">Bobs</option>
                    <option value="Bonnets">Bonnets</option>
                    <option value="Sacs à dos">Sacs à dos</option>
                    <option value="Tote bags">Tote bags</option>
                    <option value="Tabliers">Tabliers</option>
                    <option value="Masques">Masques</option>
                    <option value="Peignoirs">Peignoirs</option>
                    <option value="Sacs de gym">Sacs de gym</option>
                    <option value="Serviettes">Serviettes</option>
                    <option value="Patchs">Ecussons</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="input-devis-container">
              <label htmlFor="nameProduct">Techniques</label>
              <div className="body-ask-devis">
                {options.map((option) => (
                  <label
                    key={option.id}
                    htmlFor={option.id}
                    className={`orga-items ${
                      selectedOptions.includes(String(option.id))
                        ? "orga-selected"
                        : ""
                    }`}
                  >
                    <input
                      id={option.id}
                      type="checkbox"
                      value={option.id}
                      onChange={(e) => handleOptionClick(e.target.value)}
                    />
                    {option.name}
                  </label>
                ))}
              </div>
            </div>
            <div className="containerSectionFiles">
              <div>
                <div className="input-devis-container">
                  <label>Ajoutez un visuel</label>
                  <form className="upload">
                    <label htmlFor="uploadInput" className="uploadButton">
                      <div className="uploadBody">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 99.09 122.88"
                        >
                          <path d="M64.64,13,86.77,36.21H64.64V13ZM42.58,71.67a3.25,3.25,0,0,1-4.92-4.25l9.42-10.91a3.26,3.26,0,0,1,4.59-.33,5.14,5.14,0,0,1,.4.41l9.3,10.28a3.24,3.24,0,0,1-4.81,4.35L52.8,67.07V82.52a3.26,3.26,0,1,1-6.52,0V67.38l-3.7,4.29ZM24.22,85.42a3.26,3.26,0,1,1,6.52,0v7.46H68.36V85.42a3.26,3.26,0,1,1,6.51,0V96.14a3.26,3.26,0,0,1-3.26,3.26H27.48a3.26,3.26,0,0,1-3.26-3.26V85.42ZM99.08,39.19c.15-.57-1.18-2.07-2.68-3.56L63.8,1.36A3.63,3.63,0,0,0,61,0H6.62A6.62,6.62,0,0,0,0,6.62V116.26a6.62,6.62,0,0,0,6.62,6.62H92.46a6.62,6.62,0,0,0,6.62-6.62V39.19Zm-7.4,4.42v71.87H7.4V7.37H57.25V39.9A3.71,3.71,0,0,0,61,43.61Z" />
                        </svg>
                        <p>Glissez le fichier</p>
                      </div>
                    </label>
                    <div className="uploadFooter">
                      <label htmlFor="uploadInput" className="uploadButton">
                        <p>Parcourir</p>
                        <input
                          type="file"
                          accept="image/*"
                          id="uploadInput"
                          onChange={handleChangeFiles}
                          multiple={false}
                        />
                      </label>
                    </div>
                  </form>
                  <div className="uploadedImagesEdit">
                    {files.map((file, index) => (
                      <div key={index}>
                        <p>{file.name}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-devis-page">
            <div className="btn-devis-page">
              <button className="black-button" onClick={resetFormDataState}>
                annuler
              </button>
              <button
                className="black-button"
                onClick={() => setTogglePopup(true)}
                disabled={isLoading}
              >
                {isLoading ? "Chargement..." : "Créer"}
              </button>
            </div>
          </div>
          <div className="alertSuccess">{warning}</div>
          <div className="alertSuccess">{togglemessage}</div>
          <Popup
            togglePopup={togglePopup}
            setTogglePopup={setTogglePopup}
            onSubmite={CreateProduct}
            messagePopup="En confirmant la creation, le produit sera immediatement mis en ligne !"
            comeFrom="create product"
          />
        </div>
      </div>
    </div>
  );
};

export default CreateProduct;

import React, { useEffect, useState } from "react";
import NavbarBack from "../../components/back_office/NavbarBack";
import NavBarBackPage from "../../components/back_office/NavBarBackPage";
import axios from "axios";
import ProductCardBack from "../../components/back_office/ProductCardBack";

const ProductsManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [productData, setProductData] = useState([]);

  useEffect(() => {
    const getProduct = async () => {
      await axios
        .get(`https://api.artimarq.fr/back/products/visualisations`)
        .then((res) => {
          const allLeads = Object.values(res.data);
          const sortedData = allLeads.sort(
            (a, b) => new Date(b.dateProduct) - new Date(a.dateProduct)
          );
          setProductData(sortedData);
        });
    };
    getProduct();
  }, []);

  let dataToDisplay;
  if (productData.length > 0) {
    dataToDisplay = productData.map((product) => (
      <ProductCardBack key={product.idProduct} idProduct={product.idProduct} />
    ));
  } else {
    dataToDisplay = (
      <div className="contentEmpty">
        <h3>Vous n'avez aucun produit pour l'instant.</h3>
      </div>
    );
  }

  return (
    <div className="body-website_back">
      <NavbarBack />
      <div className="back-page-content">
        <NavBarBackPage />
        <div>
          <div className="admin-content-page">
            <div className="devis-back-container">{dataToDisplay}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsManagement;

import React, { useContext, useEffect } from "react";
import NavbarBack from "../../components/back_office/NavbarBack";
import NavBarBackPage from "../../components/back_office/NavBarBackPage";
import { ParamContext } from "../../components/context/ParamContexte";
import ArticleBlogCardBack from "./ArticleBlogCardBack";

const BlogsViews = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { articlesBack } = useContext(ParamContext);

  let dataToDisplay;
  if (articlesBack.length > 0) {
    dataToDisplay = articlesBack.map((article) => (
      <ArticleBlogCardBack
        key={article.idArticle}
        idArticle={article.idArticle}
      />
    ));
  } else {
    dataToDisplay = (
      <div className="contentEmpty">
        <p>Il n'y a aucun article pour le moment</p>
      </div>
    );
  }

  return (
    <div className="body-website_back">
      <NavbarBack />
      <div className="back-page-content">
        <NavBarBackPage />
        <div>
          <div className="admin-content-page">
            <div className="articles-content">{dataToDisplay}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogsViews;

import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { ParamContext } from "../components/context/ParamContexte";

const Cgv = () => {
  const { nameCompany, addressCompany, zipCompany, cityCompany, idCompany } =
    useContext(ParamContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formatNumber = (number) => {
    const numberString = number.toString();
    return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  return (
    <div className="polities-pages">
      <Helmet>
        <title>Conditions Générales de Vente | {nameCompany}</title>
        <meta
          name="description"
          content="Consultez nos conditions générales de vente pour découvrir nos termes, modalités d'utilisation, protection des données, et plus encore."
        />
        <link rel="canonical" href={`https://artimarq.fr/CGV`} />
      </Helmet>
      <h1>Conditions Générales de Ventes</h1>
      <div className="centent-cgv">
        <div className="section-policies">
          <h3>1. Préambule</h3>
          <p>
            Les présentes Conditions Générales de Vente (CGV) régissent les
            relations contractuelles entre {nameCompany}, une société de
            prestation en marquage textile, dont le siège social est situé à{" "}
            {addressCompany} - {zipCompany} {cityCompany}, enregistrée au
            Registre du Commerce et des Sociétés de {cityCompany}, sous le
            numéro {formatNumber(idCompany)}, et ses clients.
          </p>
        </div>
        <div className="section-policies">
          <h3>2. Objet</h3>
          <p>
            {nameCompany} propose des services de marquage textile en France
            métropolitaine, comprenant notamment la personnalisation d'articles
            textiles tels que des vêtements et accessoires diverses,
            l'impression de logos, de noms, de numéros, et d'autres éléments
            graphiques sur les articles textiles fournis par {nameCompany} ou
            par les soins du client.
          </p>
        </div>
        <div className="section-policies">
          <h3>3. Commandes</h3>
          <h4>3.1. Passation de commande</h4>
          <p>
            Le client peut passer commande auprès d'{nameCompany} par différents
            moyens, y compris via le site internet de la société, par téléphone,
            par courrier électronique, ou en personne dans nos locaux.
          </p>
          <h4>3.2. Acceptation de la commande</h4>
          <p>
            Toute commande passée par le client ne sera considérée comme
            acceptée qu'après confirmation écrite par {nameCompany}.{" "}
            {nameCompany} se réserve le droit de refuser toute commande sans
            avoir à fournir de motif.
          </p>
          <h4>3.3. Bon à tirer</h4>
          <p>
            Dans le cas où un BAT serait émis de la part de {nameCompany}, le
            client aura le choix entre :
          </p>
          <p>
            Indiquer que la maquette qui lui est proposée lui convient. Le
            Client valide alors le « bon à tirer ». Après cette validation, il
            est procédé à la phase de production et d'expédition des produits.
          </p>
          <p>
            Ou indiquer que la maquette qui lui est proposée ne lui convient pas
            et d'indiquer les modifications qu'il souhaite lui apporter. Une
            nouvelle maquette sous forme de « bon à tirer » lui est alors
            adressée par courrier électronique dans les 48 heures de la demande
            de correction de la maquette.
          </p>
          <p>
            Dans l'éventualité où le client ne valide pas le BAT sous 45 jours,
            malgré plusieurs propositions de {nameCompany}, alors que le devis
            et la commande sont validés et qu'aucune solution ne semble
            satisfaire le client, {nameCompany} facturera de manière forfaitaire
            50 % du montant global de la commande HT. Le positionnement du logo
            sur le support est effectué manuellement. Le client accepte qu'une
            marge d'erreur soit tolérée si elle ne remet pas en question le
            rendu final, conforme au BAT.
          </p>
        </div>
        <div className="section-policies">
          <h3>4. Acceptation du devis par le Client</h3>
          <p>
            Avant le début de la prestation de services, le client devra
            accepter le devis fourni par {nameCompany}. L'acceptation peut être
            effectuée par écrit, par voie électronique ou par tout autre moyen
            convenu entre les parties.
          </p>
        </div>
        <div className="section-policies">
          <h3>5. Tarifs et Paiements</h3>
          <h4>5.1. Tarifs</h4>
          <p>
            Les tarifs des services sont disponibles sur demande auprès d'
            {nameCompany}. Ils sont susceptibles de varier en fonction de la
            quantité commandée, de la complexité du marquage, et d'autres
            facteurs.
          </p>
          <h4>5.2. Paiement</h4>
          <p>
            Le paiement des services doit être effectué selon les modalités
            convenues entre {nameCompany} et le client. {nameCompany} se réserve
            le droit d'exiger un acompte avant le début de la prestation de
            services.
          </p>
        </div>
        <div className="section-policies">
          <h3>6. Délais de Livraison</h3>
          <p>
            Les délais de livraison sont communiqués au client au moment de la
            confirmation de la commande. {nameCompany} s'efforcera de respecter
            ces délais autant que possible, mais ne peut être tenu responsable
            des retards causés par des circonstances indépendantes de sa
            volonté.
          </p>
        </div>
        <div className="section-policies">
          <h3>7. Livraison</h3>
          <h4>7.1. Modalités de livraison</h4>
          <p>
            Les modalités de livraison des produits marqués seront convenues
            entre {nameCompany} et le client au moment de la commande. Les frais
            de ports seront à la charge du client, sauf indication contraire
            dans le devis. Le Client est tenu de réceptionner le colis à
            l'adresse qu'il aura communiqué lors de la validation de la
            commande.
          </p>
          <p>
            Dans le cas où le Client ne retirerait ou ne réceptionnerait pas le
            colis dans les délais et conditions requis, le colis non réclamé
            sera retourné à {nameCompany} qui le mettra à la disposition du
            Client pour une nouvelle livraison. Le Client devra se manifester
            auprès de
            {nameCompany} afin d'obtenir une nouvelle livraison de son colis.
          </p>
          <p>
            Dans le cas où le colis a été retourné à {nameCompany} pour un motif
            imputable au Client (colis non réclamé, adresse de livraison
            erronée, etc.), le Client devra s'acquitter de nouveaux frais de
            livraison pour voir son colis lui être réexpédié. Le Client sera
            informé par e-mail de la procédure à suivre pour la réexpédition de
            son colis.
          </p>
          <h4>7.2. Retards de livraison</h4>
          <p>
            {nameCompany} ne peut être tenu responsable des retards de livraison
            causés par des circonstances indépendantes de sa volonté, telles que
            des grèves, des intempéries, ou des problèmes logistiques.
          </p>
        </div>
        <div className="section-policies">
          <h3>8. Conformité</h3>
          <h4>8.1. Conformité aux spécifications convenues</h4>
          <p>
            {nameCompany} s'engage à fournir des services conformes aux
            spécifications convenues avec le client au moment de la commande.
          </p>
          <h4>8.2. Vérification de la conformité par le client</h4>
          <p>
            Le client est tenu de vérifier la conformité des produits livrés
            avec les spécifications indiquées dans le devis ou la confirmation
            de commande. Si le colis est visiblement endommagé, le Client doit
            signaler ses réserves au transporteur ou refuser purement et
            simplement de les recevoir et en informer parallèlement{" "}
            {nameCompany} dans les 48 heures suivant la réception des produits.
          </p>
          <p>
            Dans le cas où le colis ne serait pas conforme à la commande et/ou
            que les produits comporteraient des vices apparents, le Client doit
            en informer {nameCompany} par toutes voies et moyens écrits avec
            photographies en justificatif. Après accord écrit de {nameCompany},
            les produits devront être renvoyés par le Client à {nameCompany} par
            pli recommandé ou suivi à l'adresse de {nameCompany} indiquée à
            l'article 1.
          </p>
          <p>
            Aucun retour ne sera accepté après un délai de 15 jours calendaires
            suivant la date de livraison. Au- delà, le Client reconnait que son
            colis sera considéré comme conforme à sa commande et les produits
            seront considérés comme exempts de tous vices apparents.
          </p>
          <h4>8.3. Remarques concernant la qualité des commandes</h4>
          <p>
            Des traces liées au marquage peuvent être visible sur les supports.
            Ces traces partent au lavage. Nous conseillons de laver les textiles
            avant de les porter.
          </p>
          <p>
            Pour les commandes ayant des quantités supérieures à 1000 pièces, le
            client accepte qu'il y ait une tolérance correspondant à +/-1% sur
            les quantités, sans que le prix unitaire en soit modifié.
          </p>
          <p>
            Pour la broderie, nous effectuons le nettoyage de l'extérieur à 100%
            et nous retirons le papier à l'intérieur. Des différences de taille,
            couleur et poids entre 5% et 7% sont admises et ne peuvent être
            source de réclamation.
          </p>
          <p>
            A réception des produits retournés, {nameCompany} vérifiera leur
            nature et leur état. S'ils s'avèrent effectivement non conformes à
            la commande du Client ou affectés d'un vice apparent, {nameCompany}{" "}
            remboursera au Client les frais de retour engagés.
          </p>
          <p>
            Dans ce cas, le retour de produits donnera lieu, au choix du Client,
            à l'échangé des produits défectueux ou non conformes par des
            produits en parfait état ou conformes, dans la limite des stocks
            disponibles, ou au remboursement du prix des produits retournés
            ainsi que des frais de livraison correspondants. Si le Client
            choisit le remboursement, il sera alors remboursé dans les 14 jours
            suivant la réception des produits retournés dans leur état
            d'origine. Le remboursement s'effectue de la manière suivante : par
            virement bancaire.
          </p>
          <p>
            Dans le cas où les produits retournés s'avéreraient parfaitement
            conformes à la commande et/ou sans vice apparent, {nameCompany} ne
            sera pas tenu de rembourser le Client ni d'en supporter les nouveaux
            frais de livraison.
          </p>
          <p>
            Dans le cas où les supports auraient été fournis par le Client, le
            choix d'un remboursement ne pourra automatiquement inclure une
            remise en état d'origine de ces supports. Toutefois, si le Client
            souhaite également en plus d'un remboursement une remise en état
            d'origine des support, des frais de gestion et de traitement,
            équivalant à un forfait de 20% du montant de la commande seront
            appliqués.
          </p>
        </div>
        <div className="section-policies">
          <h3>9. Réclamations</h3>
          <p>
            Toute réclamation concernant la qualité du marquage ou tout autre
            aspect du service doit être formulée par écrit dans un délai de 30
            jours suivant la réception des articles marqués. {nameCompany}{" "}
            examinera la réclamation et prendra les mesures appropriées pour
            résoudre le problème.
          </p>
        </div>
        <div className="section-policies">
          <h3>9.1 Responsabilité</h3>
          <p>
            {nameCompany} décline toute responsabilité en cas de dommages
            indirects ou consécutifs découlant de l'utilisation de ses services.
          </p>
        </div>
        <div className="section-policies">
          <h3>10. Rétractation</h3>
          <h4>10.1. Droit de rétractation</h4>
          <p>
            En vertu de l'article L.121-21-8, 3° du Code de la consommation, le
            Client reconnaît et accepte que tout produit personnalisé, notamment
            les vêtements, les accessoires, les sacoches et autres articles
            personnalisés commandés sur le site de {nameCompany}, ne peut faire
            l'objet d'un droit de rétractation.
          </p>
        </div>
        <div className="section-policies">
          <h3>11. Disponibilité</h3>
          <p>
            Les services de {nameCompany} sont proposés sous réserve de
            disponibilité. {nameCompany} s'efforcera de répondre aux besoins du
            client en matière de délais, mais la disponibilité des services peut
            varier en fonction de la demande et d'autres facteurs.
          </p>
        </div>
        <div className="section-policies">
          <h3>12. Confidentialité</h3>
          <p>
            {nameCompany} s'engage à traiter toutes les informations fournies
            par le client avec la plus grande confidentialité et à ne pas les
            divulguer à des tiers sans le consentement écrit du client.
          </p>
        </div>
        <div className="section-policies">
          <h3>13. Droit applicable et juridiction compétente</h3>
          <p>
            Les présentes CGV sont régies par le droit français. Tout litige
            relatif à l'interprétation ou à l'exécution des présentes CGV sera
            soumis à la compétence exclusive des tribunaux français.
          </p>
        </div>
        <div className="section-policies">
          <h3>14. Modification des CGV</h3>
          <p>
            {nameCompany} se réserve le droit de modifier ses CGV à tout moment.
            Les clients seront informés de toute modification substantielle des
            CGV.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Cgv;

import { useEffect, useRef } from "react";

const Popup = (props) => {
  let { togglePopup, setTogglePopup, messagePopup, comeFrom, onSubmite } =
    props;

  const myRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!myRef.current.contains(e.target)) {
        setTogglePopup(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <div className={`bg-pupop ${togglePopup ? "active" : "inactive"}`}>
      <div ref={myRef} className="popup-container">
        <div className="popup-content">
          <p>{messagePopup}</p>
        </div>
        <div className="btn-popup-container">
          <div className="sub-btn-popop-container">
            {comeFrom && (
              <>
                <button
                  className="black-button"
                  onClick={() => setTogglePopup(false)}
                >
                  ANNULER
                </button>
                <button
                  className="black-button"
                  onClick={() => {
                    if (comeFrom === "orderDeletion") {
                      onSubmite();
                    } else if (comeFrom === "devisDeletion") {
                      onSubmite();
                    } else if (comeFrom === "sendBill") {
                      onSubmite();
                    } else if (comeFrom === "availableOrder") {
                      onSubmite();
                    } else if (comeFrom === "BillRel") {
                      onSubmite();
                    } else if (comeFrom === "Createbill") {
                      onSubmite();
                    } else if (comeFrom === "cancelBill") {
                      onSubmite();
                    } else if (comeFrom === "messageDeletion") {
                      onSubmite();
                    } else if (comeFrom === "BillDeletion") {
                      onSubmite();
                    } else if (comeFrom === "leadDeletion") {
                      onSubmite();
                    } else if (comeFrom === "userDeletion") {
                      onSubmite();
                    } else if (comeFrom === "productDeletion") {
                      onSubmite();
                    } else if (comeFrom === "toggleOnline") {
                      onSubmite();
                    } else if (comeFrom === "create product") {
                      onSubmite();
                    } else if (comeFrom === "artcleDeletion") {
                      onSubmite();
                    } else if (comeFrom === "toggleOnlineArticle") {
                      onSubmite();
                    } else if (comeFrom === "articleBlocDeletion") {
                      onSubmite();
                    } else if (comeFrom === "publishArticleFunc") {
                      onSubmite();
                    }
                    setTogglePopup(false);
                  }}
                >
                  CONFIRMER
                </button>
              </>
            )}
          </div>
          {!comeFrom && (
            <button
              className="black-button"
              onClick={() => setTogglePopup(false)}
            >
              FERMER
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Popup;

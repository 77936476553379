import React, { useContext, useState } from "react";
import axios from "axios";
import NavbarBack from "../../components/back_office/NavbarBack";
import NavBarBackPage from "../../components/back_office/NavBarBackPage";
import { ParamContext } from "../../components/context/ParamContexte";

const CreateArticle = () => {
  const { adminId } = useContext(ParamContext);

  const [togglemessage, setToggleMessage] = useState("");
  const [warning, setWarning] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [formDataState, setFormDataState] = useState({
    BlogCategory: "Nouveautés et Tendances",
    titleBlog: " ",
    descBlog: " ",
    author: " ",
    articleContent: " ",
  });

  const resetFormDataState = () => {
    setFormDataState({
      BlogCategory: "Nouveautés et Tendances",
      titleBlog: " ",
      descBlog: " ",
      author: " ",
      articleContent: " ",
    });
  };

  const handleChange = (value, name) => {
    setFormDataState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeFiles = (event) => {
    const newFiles = event.target.files;
    setFiles([...newFiles]);
  };

  const CreateProduct = async () => {
    setIsLoading(true);

    const { BlogCategory, titleBlog, descBlog, author, articleContent } =
      formDataState;

    if (
      !BlogCategory &&
      !titleBlog &&
      !descBlog &&
      !author &&
      !articleContent &&
      !files
    ) {
      setIsLoading(false);
      setToggleMessage("");
      setWarning("Veuillez remplire tous les champs obligatoirs");
      return;
    }

    try {
      const response = await axios.post(
        "https://api.artimarq.fr/back/blog/create",
        {
          adminId: adminId,
          BlogCategory: formDataState.BlogCategory,
          titleBlog: formDataState.titleBlog,
          descBlog: formDataState.descBlog,
          author: formDataState.author,
          articleContent: formDataState.articleContent,
          files: files,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
        // console.log(response);
      setWarning("");
      resetFormDataState();
      setFiles([]);
      setToggleMessage(response.data.message);
    } catch (error) {
      console.log(error.message);
      setToggleMessage(
        "Une erreur s'est produite lors de la création du produit."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="body-website_back">
      <NavbarBack />
      <div className="back-page-content">
        <NavBarBackPage />
        <div>
          <div className="input-devis-up-container admin-content-page">
            <div className="input-devis-bloc">
              <div className="section-input-devis">
                <div className="input-devis-container">
                  <label>Categorie</label>
                  <select
                    name="BlogCategory"
                    id="BlogCategory"
                    value={formDataState.BlogCategory}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  >
                    <option value="Nouveautés et Tendances">
                      Nouveautés et Tendances
                    </option>
                    <option value="Événements et Actualités">
                      Événements et Actualités
                    </option>
                    <option value="Guides">Guides</option>
                    <option value="Inspirations">Inspirations</option>
                    <option value="Réalisations">Réalisations</option>
                    <option value="Concours et Jeux">Concours et Jeux</option>
                  </select>
                </div>
              </div>
              <div className="section-input-devis">
                <div className="input-devis-container">
                  <label htmlFor="titleBlog">Titre de l'article</label>
                  <input
                    name="titleBlog"
                    id="titleBlog"
                    defaultValue={formDataState.titleBlog}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
              <div className="section-input-devis">
                <div className="input-devis-container">
                  <label htmlFor="descBlog">Description</label>
                  <input
                    name="descBlog"
                    id="descBlog"
                    defaultValue={formDataState.descBlog}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
                <div className="input-devis-container">
                  <label htmlFor="author">Auteur</label>
                  <input
                    name="author"
                    id="author"
                    defaultValue={formDataState.author}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="input-devis-container">
              <label htmlFor="articleContent">
                Contenu de la page de blog (balisée)
              </label>
              <textarea
                name="articleContent"
                id="articleContent"
                resize="none"
                cols="100"
                rows="50"
                defaultValue={formDataState.articleContent}
                onChange={(e) => {
                  handleChange(e.target.value, e.target.name);
                }}
              ></textarea>
            </div>
            <div className="containerSectionFiles">
              <div>
                <div className="input-devis-container">
                  <label>Illustration de l'article</label>
                  <form className="upload">
                    <label htmlFor="uploadInput" className="uploadButton">
                      <div className="uploadBody">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 99.09 122.88"
                        >
                          <path d="M64.64,13,86.77,36.21H64.64V13ZM42.58,71.67a3.25,3.25,0,0,1-4.92-4.25l9.42-10.91a3.26,3.26,0,0,1,4.59-.33,5.14,5.14,0,0,1,.4.41l9.3,10.28a3.24,3.24,0,0,1-4.81,4.35L52.8,67.07V82.52a3.26,3.26,0,1,1-6.52,0V67.38l-3.7,4.29ZM24.22,85.42a3.26,3.26,0,1,1,6.52,0v7.46H68.36V85.42a3.26,3.26,0,1,1,6.51,0V96.14a3.26,3.26,0,0,1-3.26,3.26H27.48a3.26,3.26,0,0,1-3.26-3.26V85.42ZM99.08,39.19c.15-.57-1.18-2.07-2.68-3.56L63.8,1.36A3.63,3.63,0,0,0,61,0H6.62A6.62,6.62,0,0,0,0,6.62V116.26a6.62,6.62,0,0,0,6.62,6.62H92.46a6.62,6.62,0,0,0,6.62-6.62V39.19Zm-7.4,4.42v71.87H7.4V7.37H57.25V39.9A3.71,3.71,0,0,0,61,43.61Z" />
                        </svg>
                        <p>Glissez le fichier</p>
                      </div>
                    </label>
                    <div className="uploadFooter">
                      <label htmlFor="uploadInput" className="uploadButton">
                        <p>Parcourir</p>
                        <input
                          type="file"
                          accept="image/*"
                          id="uploadInput"
                          onChange={handleChangeFiles}
                          multiple={false}
                        />
                      </label>
                    </div>
                  </form>
                  <div className="uploadedImagesEdit">
                    {files.map((file, index) => (
                      <div key={index}>
                        <p>{file.name}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-devis-page">
            <div className="btn-devis-page">
              <button className="black-button" onClick={resetFormDataState}>
                annuler
              </button>
              <button
                className="black-button"
                onClick={CreateProduct}
                disabled={isLoading}
              >
                {isLoading ? "Chargement..." : "Créer"}
              </button>
            </div>
          </div>
          <div className="alertSuccess">{warning}</div>
          <div className="alertSuccess">{togglemessage}</div>
        </div>
      </div>
    </div>
  );
};

export default CreateArticle;
